/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ShareStoryAddpreviewerData,
  ShareStoryAddpreviewerPayload,
  ShareStoryCheckvisitorstorypermissionData,
  ShareStoryCheckvisitorstorypermissionParams,
  ShareStoryGeneratestorysharelinkData,
  ShareStoryGeneratestorysharelinkPayload,
  ShareStoryPreviewstatusData,
  ShareStoryPreviewstatusParams,
  ShareStoryStorysharelinkinfobylinkidData,
  ShareStoryStorysharelinkinfobylinkidParams,
  ShareStoryStorysharelinkinfobystoryidData,
  ShareStoryStorysharelinkinfobystoryidParams
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class ShareStory<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags ShareStory
   * @name ShareStoryAddpreviewer
   * @request PUT:/api/ShareStory/addpreviewer
   * @secure
   */
  shareStoryAddpreviewer = (data: ShareStoryAddpreviewerPayload, params: RequestParams = {}) =>
    this.request<ShareStoryAddpreviewerData, any>({
      path: `/api/ShareStory/addpreviewer`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: true,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags ShareStory
   * @name ShareStoryGeneratestorysharelink
   * @request POST:/api/ShareStory/generatestorysharelink
   * @secure
   */
  shareStoryGeneratestorysharelink = (
    data: ShareStoryGeneratestorysharelinkPayload,
    params: RequestParams = {}
  ) =>
    this.request<ShareStoryGeneratestorysharelinkData, any>({
      path: `/api/ShareStory/generatestorysharelink`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: true,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags ShareStory
   * @name ShareStoryPreviewstatus
   * @request GET:/api/ShareStory/previewstatus
   * @secure
   */
  shareStoryPreviewstatus = (query: ShareStoryPreviewstatusParams, params: RequestParams = {}) =>
    this.request<ShareStoryPreviewstatusData, any>({
      path: `/api/ShareStory/previewstatus`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags ShareStory
   * @name ShareStoryCheckvisitorstorypermission
   * @request GET:/api/ShareStory/checkvisitorstorypermission
   * @secure
   */
  shareStoryCheckvisitorstorypermission = (
    query: ShareStoryCheckvisitorstorypermissionParams,
    params: RequestParams = {}
  ) =>
    this.request<ShareStoryCheckvisitorstorypermissionData, any>({
      path: `/api/ShareStory/checkvisitorstorypermission`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags ShareStory
   * @name ShareStoryStorysharelinkinfobylinkid
   * @request GET:/api/ShareStory/storysharelinkinfobylinkid
   * @secure
   */
  shareStoryStorysharelinkinfobylinkid = (
    query: ShareStoryStorysharelinkinfobylinkidParams,
    params: RequestParams = {}
  ) =>
    this.request<ShareStoryStorysharelinkinfobylinkidData, any>({
      path: `/api/ShareStory/storysharelinkinfobylinkid`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags ShareStory
   * @name ShareStoryStorysharelinkinfobystoryid
   * @request GET:/api/ShareStory/storysharelinkinfobystoryid
   * @secure
   */
  shareStoryStorysharelinkinfobystoryid = (
    query: ShareStoryStorysharelinkinfobystoryidParams,
    params: RequestParams = {}
  ) =>
    this.request<ShareStoryStorysharelinkinfobystoryidData, any>({
      path: `/api/ShareStory/storysharelinkinfobystoryid`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: true,
      ...params
    });
}
