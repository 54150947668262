import axios from "axios";
import { v4 as uuidv4 } from "uuid";

// Msal
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfigForLogin, protectedResources } from "../../authConfig";

// Services
import {
  getAccessToken,
  getGoogleSessionId,
  getStartTreeRequestID,
  getUserFlow,
  removeCookies,
  setAccessToken,
  setStartTreeRequestID
} from "../../services";

//Store
import { REACT_APP_API, REACT_APP_CLIENT_ID } from "utils/env";
import reduxStore from "../store";

const BASE_URL = REACT_APP_API;
const CLIENT_ID = REACT_APP_CLIENT_ID;
const { dispatch } = reduxStore;

const getCustomHeader = () => {
  const accessToken = getAccessToken();
  const bearer = `Bearer ${accessToken}`;
  const Authorization = ("Authorization", bearer);
  const requestId = uuidv4();
  setStartTreeRequestID(requestId);
  return {
    "wa-clientId": CLIENT_ID,
    "wa-requestId": requestId,
    "wa-sessionId": getGoogleSessionId(),
    Authorization
  };
};

const refreshToken = () => {
  return new Promise((resolve) => {
    const instance = new PublicClientApplication(msalConfigForLogin);
    const account = instance.getAllAccounts()[0];
    if (account) {
      instance
        .acquireTokenSilent({
          scopes: protectedResources.apiStoried.scopes,
          account
        })
        .then((response) => {
          resolve(response.accessToken);
        })
        .catch((error) => {
          if (error) {
            removeCookies();
            instance.logoutRedirect({ postLogoutRedirectUri: "/" });
          }
          resolve(error);
        });
    }
  });
};

export const startTreeApiRequest = (
  method,
  url,
  data,
  onUploadProgress,
  source,
  headers = false
) => {
  axios.interceptors.response.use(null, (error) => {
    let userFlow = getUserFlow();
    const instance = new PublicClientApplication(msalConfigForLogin);
    if (error.config && error.response && error.response.status === 401 && userFlow) {
      return refreshToken().then((token) => {
        setAccessToken(token);
        const bearer = `Bearer ${token}`;
        const Authorization = ("Authorization", bearer);
        error.config.headers = {
          "wa-clientId": CLIENT_ID,
          "wa-requestId": getStartTreeRequestID(),
          "wa-sessionId": getGoogleSessionId(),
          Authorization,
          "content-type": "application/json"
        };
        return axios.request(error.config);
      });
    }
    if (error.config && error.response && error.response.status === 500 && userFlow) {
      dispatch({ type: "APP_ERROR_STATE" });
    }
    if (!userFlow) {
      removeCookies();
      instance.logoutRedirect({ postLogoutRedirectUri: "/" });
    }

    return Promise.reject(error);
  });

  const sourceJSON = source
    ? {
        cancelToken: source.token
      }
    : {};

  if (headers && headers instanceof Object && !Array.isArray(headers)) {
    headers = {
      ...getCustomHeader(),
      ...headers
    };
  } else {
    headers = getCustomHeader();
  }

  const onUploadProgressFn = function (progressEvent) {
    if (onUploadProgress) onUploadProgress(progressEvent);
  };

  const isUploadProgressEnabled = process.env.NODE_ENV !== "test";

  return axios({
    method,
    url: `${BASE_URL}/api/${url}`,
    headers,
    data,
    ...sourceJSON,
    onUploadProgress: isUploadProgressEnabled && onUploadProgressFn
  });
};
