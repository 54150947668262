import { useState, useRef } from "react";
import { searchTabMenu, peopleTabMenu } from "pages/PersonViewPage/menus";

export const usePeoplePopover = (type) => {
  const [open, setOpen] = useState(false);
  const [subnavMenu, setsubnavMenu] = useState(null);
  const anchorRef = useRef(null);
  const dropdownClass = (cond, treelist = false) => (cond ? `lft-0 block ${treelist && "people-dd"}` : "hidden");
  const [isSearchTab, setIsSearchTab] = useState(false);
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);

  const handleToggle = () => {
    if (type === "people") {
      setsubnavMenu(peopleTabMenu);
    } else {
      setsubnavMenu(searchTabMenu);
    }
    setOpen((previousOpen) => !previousOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  };

  const handleSelect = (e) => {
    if (type === 'people') {
      if (document.getElementById('people')) document.getElementById('people').classList.add("active");
    }
    else {
      if (document.getElementById('search')) document.getElementById('search').classList.add("active");
    }
    setOpen(false);
  };

  const getActiveClass = () => {
    if (type === "people" && (window.location.pathname === "/people" || window.location.pathname === '/family-trees')) {
      if (document?.getElementById('search')?.classList?.contains("active")) {
        document?.getElementById('search')?.classList?.remove("active");
      }
      return "nav-link active";
    }
    else if ((type === "search" && (window.location.toString().includes("search")))) {
      if (document?.getElementById('people')?.classList?.contains("active")) document?.getElementById('people')?.classList?.remove("active");
      return "nav-link active";
    }
    else if ((!window.location.toString().includes("family") && !window.location.toString().includes("people") && !(window.location.toString().includes("search")) && window.location.pathname === '/')) {
      return "nav-link";
    }
    return "nav-link";
  };

  return { open, subnavMenu, anchorRef, prevOpen, dropdownClass, isSearchTab, setIsSearchTab, handleToggle, handleClose, handleListKeyDown, handleSelect, getActiveClass };
};
