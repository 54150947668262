/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ContentSearch2Data,
  ContentSearch2Payload,
  ContentSearchContentForSearchLandingPageData,
  ContentSearchContentForSearchLandingPageParams,
  ContentSearchData,
  ContentSearchParams
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class ContentSearch<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags ContentSearch
   * @name ContentSearch
   * @request GET:/api/ContentSearch
   * @secure
   */
  contentSearch = (query: ContentSearchParams, params: RequestParams = {}) =>
    this.request<ContentSearchData, any>({
      path: `/api/ContentSearch`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags ContentSearch
   * @name ContentSearch2
   * @request POST:/api/ContentSearch
   * @originalName contentSearch
   * @duplicate
   * @secure
   */
  contentSearch2 = (data: ContentSearch2Payload, params: RequestParams = {}) =>
    this.request<ContentSearch2Data, any>({
      path: `/api/ContentSearch`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: true,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags ContentSearch
   * @name ContentSearchContentForSearchLandingPage
   * @request GET:/api/ContentSearch/contentForSearchLandingPage
   * @secure
   */
  contentSearchContentForSearchLandingPage = (
    query: ContentSearchContentForSearchLandingPageParams,
    params: RequestParams = {}
  ) =>
    this.request<ContentSearchContentForSearchLandingPageData, any>({
      path: `/api/ContentSearch/contentForSearchLandingPage`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: true,
      ...params
    });
}
