import * as CONSTANTS from "../constants/actionTypes";
import { getOwner } from "../../services";

import { SAVEDRAFTPREVIEWREMOVE, SAVEDRAFTPREVIEWSTORE } from './../constants';

const initialState = {
  isLoading: false,
  list: [],
  isListEmpty: true,
  view: null,
  draftView:null,
  deletePersonLoader: false,
  deleteStoryErr: true,
  leftPanelDetails: null,
  rightPanelDetails: null,
  spousesnchildren: null,
  viewPermission: true,
  paidUser: true,
  loadingCitation: false,
  citation: {},
  addingCitation: false,
  addedCitation: false,
  updatingCitation: false,
  updatedCitation: false,
  removingCitation: false,
  removedCitation: false,
  promptOptionSelected: null
};

const viewStoryFailure = (payload, state) => {
  if (payload.status === 403) {
    return {
      ...state,
      isLoading: false,
      viewPermission: false
    }
  }
  if (payload.status === 402) {
    return {
      ...state,
      isLoading: false,
      paidUser: false
    }
  }
  return {
    ...state,
    isLoading: false,
  }

}
const story = (state = initialState, { type = null, payload = null } = {}) => {
  const request = {
    ...state,
    list: [],
    isLoading: true,
    isListEmpty: true,
    viewPermission: true
  };
  const paginationRequest = {
    ...state,
    isPagintionLoading: true,
  };
  const paginationFailure = {
    ...state,
    isPagintionLoading: false,
  };
  switch (type) {
    case CONSTANTS.GETSTORY.REQUEST:
      return {
        ...state,
        // list: [],
        isListEmpty: true
      };
    case CONSTANTS.GETSTORY.SUCCESS:
      return {
        ...state,
        list: payload,
        isListEmpty: false,
      };
    case CONSTANTS.GETSTORY.FAILURE:
      return {
        ...state,
        isListEmpty: false,
      };
    case CONSTANTS.GETSTORYANDUPDATELIST.SUCCESS:
      return {
        ...state,
        list: state.list.map(item => typeof item === "string" && item === payload.storyId ? payload : item)
      }
    case CONSTANTS.GETSTORYPAGINATION.REQUEST:
      return paginationRequest;
    case CONSTANTS.GETSTORYPAGINATION.SUCCESS:
      return {
        ...state,
        list: [...state.list, ...payload],
        isPagintionLoading: false,
      };
    case CONSTANTS.GETSTORYPAGINATION.FAILURE:
      return paginationFailure;
    case CONSTANTS.POSTSTORY.REQUEST:
      return request;
    case CONSTANTS.VIEWSTORY.RESET:
      return {
        ...state,
        view: null
      }
    case CONSTANTS.POSTSTORY.SUCCESS:
    case CONSTANTS.POSTSTORY.FAILURE:  
      return {
        ...state,
        isLoading: false,
      };
    case CONSTANTS.UPDATESTORY.SUCCESS:
      return {
        ...state,
        view: {
          ...state.view,
          existImages: payload.existImages ?? [],
          existAdditionalImages: payload.existAdditionalImages ?? [],
        },
        isLoading: false,
      };
    case CONSTANTS.VIEWSTORY.REQUEST:
      return request;
    case CONSTANTS.VIEWSTORY.SUCCESS:
      return {
        ...state,
        isLoading: false,
        viewPermission: true,
        view: payload,
      };
    case CONSTANTS.VIEWSTORY.FAILURE:
      return viewStoryFailure(payload, state)
    case CONSTANTS.DELETESTORYPERSON.REQUEST:
      return {
        ...state,
        deletePersonLoader: true,
      };
    case CONSTANTS.DELETESTORYPERSON.SUCCESS:
      return {
        ...state,
        deletePersonLoader: false,
        view: {
          ...state.view,
          personDetail: state.view.personDetail.filter(
            (value) => value.id !== payload.id
          ),
        },
      };
    case CONSTANTS.DELETESTORYPERSON.FAILURE:
      return {
        ...state,
        deletePersonLoader: false,
      };
    case CONSTANTS.DELETESTORY.REQUEST:
      return {
        ...state,
        isLoading: true,
        deleteStoryErr: true,
      };
    case CONSTANTS.DELETESTORY.SUCCESS:
      return {
        ...state,
        deleteStoryErr: false,
        isLoading: false,
        list: state.list.filter((y) =>y?.storyId !== payload.storyId),
        leftPanelDetails:{...state.leftPanelDetails,Drafts:state?.leftPanelDetails?.Drafts-1,AllStories:state?.leftPanelDetails?.AllStories-1}
      };
    case CONSTANTS.DELETESTORY.FAILURE:
      return {
        ...state,
        isLoading: false,
        deleteStoryErr: payload,
      };
    case CONSTANTS.GETLEFTPANELDETAILS.REQUEST:
      return request;
    case CONSTANTS.GETLEFTPANELDETAILS.SUCCESS:
      return {
        ...state,
        leftPanelDetails: payload,
        isLoading: false,
      };
    case CONSTANTS.GETLEFTPANELDETAILS.FAILURE:
      return {
        ...state,
        isLoading: false,
        leftPanelDetails: payload,
      };
    case CONSTANTS.SPOUSESWITHCHILDREN.REQUEST:
      return request;
    case CONSTANTS.SPOUSESWITHCHILDREN.SUCCESS:
      return {
        ...state,
        spousesnchildren: payload,
        isLoading: false,
      };
    case CONSTANTS.UPDATEPRIVACYSTATUS.SUCCESS:
      return {
        ...state,
        view: {
          ...state.view,
          privacy: payload,
        },
      };
    case CONSTANTS.SPOUSESWITHCHILDREN.FAILURE:
      return {
        ...state,
        isLoading: false,
        spousesnchildren: payload,
      };
    case CONSTANTS.GETRIGHTPANELDETAILS.REQUEST:
      return request;
    case CONSTANTS.GETRIGHTPANELDETAILS.SUCCESS:
      return {
        ...state,
        rightPanelDetails: payload,
        isLoading: false,
      };
    case CONSTANTS.GETRIGHTPANELDETAILS.FAILURE:
      return {
        ...state,
        isLoading: false,
        rightPanelDetails: payload,
      };
    case CONSTANTS.SETFOLLOWUNFOLLOWVIEWSTORY.SUCCESS:
      state.view.author.isFollow = payload.isFollow;
      return {
        ...state,
      };
    case CONSTANTS.VIEWSTORYEMPTY:
      return {
        ...state,
        view: null
      };
    case CONSTANTS.UPDATEVIEWSTORYISLIKED.SUCCESS:
      let viewLike;
      if (payload.isLiked === "like") {
        viewLike = [...state.view.likes, { userId: getOwner() }];
      } else if (payload.isLiked === "unlike") {
        viewLike = state?.view?.likes.filter((value) => value.userId !== getOwner());
      }
      return {
        ...state,
        view: {
          ...state.view,
          likes: viewLike
        }
      };
    case CONSTANTS.SHARESTORYSTATUS.SUCCESS:
      return {
        ...state,
        sharedStory: payload,
      };
    case CONSTANTS.SHARESTORYSTATUS.FAILURE:
      return {
        ...state,
        sharedStory: {},
      };

    case CONSTANTS.GET_CITATION.REQUEST:
      return {
        ...state,
        loadingCitation: true
      }

    case CONSTANTS.GET_CITATION.SUCCESS:
      return {
        ...state,
        loadingCitation: false,
        addedCitation: false,
        updatedCitation: false,
        removedCitation: false,
        citation: payload
      }

    case CONSTANTS.GET_CITATION.FAILURE:
      return {
        ...state,
        loadingCitation: false
      }

    case CONSTANTS.ADD_CITATION.REQUEST:
      return {
        ...state,
        addingCitation: true
      }

    case CONSTANTS.ADD_CITATION.SUCCESS:
      return {
        ...state,
        addingCitation: false,
        addedCitation: true
      }

    case CONSTANTS.ADD_CITATION.FAILURE:
      return {
        ...state,
        addingCitation: false,
      }

    case CONSTANTS.UPDATE_CITATION.REQUEST:
      return {
        ...state,
        updatingCitation: true
      }

    case CONSTANTS.UPDATE_CITATION.SUCCESS:
      return {
        ...state,
        updatingCitation: false,
        updatedCitation: true
      }

    case CONSTANTS.UPDATE_CITATION.FAILURE:
      return {
        ...state,
        updatingCitation: false,
      }

    case CONSTANTS.REMOVE_CITATION.REQUEST:
      return {
        ...state,
        removingCitation: true
      }

    case CONSTANTS.REMOVE_CITATION.SUCCESS:
      return {
        ...state,
        removingCitation: false,
        removedCitation: true
      }

    case CONSTANTS.REMOVE_CITATION.FAILURE:
      return {
        ...state,
        removingCitation: false,
      }
    case CONSTANTS.FIRST_STORY_PROMPT_OPTION_SELECTED.SUCCESS:
      return {
        ...state,
        promptOptionSelected: payload
      }
    case CONSTANTS.SHAREABLE_STORY.SUCCESS:
      return {
        ...state,
        shareableStory: payload,
      };
    case CONSTANTS.SHAREABLE_STORY.FAILURE:
      return {
        ...state,
        shareableStory: {},
      };
      case SAVEDRAFTPREVIEWSTORE:
        return {...state,draftView:payload}
      case SAVEDRAFTPREVIEWREMOVE:
        return {...state,draftView:null}
    default:
      return state;
  }
};
export default story;
