import { v4 as uuidv4 } from "uuid";

// redux
import {
  DELETE,
  EMPTYGROUPMEMBERS,
  GET,
  INITIALUSERROLE,
  POST,
  RESETINVITEDETAILS,
  SAVE_HERO_IMAGE,
  THUMBNAIL_UPLOAD_COMPLETE
} from "redux/constants";
import * as CONSTANTS from "redux/constants/actionTypes";
import * as API_URLS from "redux/constants/apiUrl";
import { getUpdateGroupInfo, groupImageUploadPayload } from "redux/helpers";
import { combineGroupMembers } from "redux/helpers/groupMembers";
import { apiRequest } from "redux/requests";

// utils
import { actionCreator, callApi } from "redux/utils";

// services
import {
  decodeJWTtoken,
  getGoogleSessionId,
  getOwner,
  getSubscriptionDetails,
  setGroupInfoCookie
} from "services";

// local
import { basicPlanName, checkWebsitePlan, getPlanName } from "redux/helpers/dataLayerHelper";
import { planDetail } from "utils/constant";
import { REACT_APP_CLIENT_ID } from "utils/env";
import { dismissGroupPrompt, openCloseGroupModalViaHomepagePrompt } from "./homepagePrompts";
import {
  getUserGroups as getSideBarUserGroups,
  leaveUserGroup as leaveSidebarUserGroup
} from "./leftSideNav";
import { addMessage } from "./toastr";
import { dataLayerGroup } from "./utils/datalayer";

const createHeader = () => {
  return {
    "wa-clientId": REACT_APP_CLIENT_ID,
    "wa-requestId": uuidv4(),
    "wa-sessionId": getGoogleSessionId()
  };
};

export const createGroup = ({ data, callback, skip }) => {
  return (dispatch, getState) => {
    let url = API_URLS.CreateGroupApi,
      staticHeader = createHeader();
    if (skip) dispatch(actionCreator(CONSTANTS.SKIPINVITE.REQUEST));
    else dispatch(actionCreator(CONSTANTS.CREATEGROUP.REQUEST));
    callApi("POST", url, data, false, staticHeader)
      .then((res) => {
        callback(res.data);
        let getToken = decodeJWTtoken();
        const planDetails = getSubscriptionDetails();
        const plans = Object?.values(planDetail);
        let getPlan = basicPlanName(plans, planDetails);
        let planName = getPlanName(getPlan);
        let checkUserType = checkWebsitePlan(getToken, planName);
        dataLayerGroup(getOwner(), checkUserType);
        setTimeout(() => {
          dispatch(getSideBarUserGroups());
        }, 1000);
        dispatch(actionCreator(CONSTANTS.CREATEGROUP.SUCCESS, res.data));
        dispatch(dismissGroupPrompt());
        dispatch(addMessage("Your group has been created.", "success"));
      })
      .catch((err) => {
        dispatch(actionCreator(CONSTANTS.CREATEGROUP.FAILURE, err?.response?.data));
        dispatch(addMessage("Sorry, your group couldn't be saved. Please try again.", "error"));
      });
  };
};

export const editGroupApi = (payload, cb) => {
  return (dispatch, getState) => {
    let url = API_URLS.UpdateGroupApi,
      staticHeader = createHeader();
    dispatch(actionCreator(CONSTANTS.CREATEGROUP.REQUEST));
    callApi("POST", url, payload, false, staticHeader)
      .then(() => {
        cb();
        dispatch(getSideBarUserGroups());
        dispatch(actionCreator(CONSTANTS.CREATEGROUP.SUCCESS));
        dispatch(addMessage("Your group has been updated.", "success"));
      })
      .catch(() => {
        dispatch(actionCreator(CONSTANTS.CREATEGROUP.FAILURE));
        dispatch(addMessage("Sorry, your group couldn't be updated. Please try again.", "error"));
      });
  };
};

export const getGroupDetails = (groupUrl, refetch = false) => {
  return (dispatch, getState) => {
    let url = API_URLS.getGroupDetails(groupUrl),
      staticHeader = createHeader();
    if (getState().group.groupDetails?.url !== groupUrl || refetch) {
      if (!refetch) dispatch(actionCreator(CONSTANTS.GROUPDETAILS.REQUEST));
      return callApi("GET", url, groupUrl, false, staticHeader)
        .then((res) => {
          dispatch(actionCreator(CONSTANTS.GROUPDETAILS.SUCCESS, res.data || {}));
          return res.data;
        })
        .catch((err) => {
          dispatch(actionCreator(CONSTANTS.GROUPDETAILS.FAILURE, err?.response?.data));
        });
    }
  };
};

export const checkExistingGroup = ({ groupUrl, callback }) => {
  return (dispatch, getState) => {
    let url = API_URLS.checkExistingGroup(groupUrl),
      staticHeader = createHeader();
    dispatch(actionCreator(CONSTANTS.CHECKEXISTINGROUP.REQUEST));
    callApi("GET", url, {}, false, staticHeader)
      .then((res) => {
        dispatch(actionCreator(CONSTANTS.CHECKEXISTINGROUP.SUCCESS, res.data));
        callback(res.data);
      })
      .catch((err) => {
        callback([]);
        dispatch(actionCreator(CONSTANTS.CHECKEXISTINGROUP.FAILURE, err?.response?.data));
      });
  };
};

export const clearExistingGroup = () => {
  return (dispatch) => {
    dispatch(actionCreator(CONSTANTS.CHECKEXISTINGROUP.SUCCESS, []));
    dispatch(openCloseGroupModalViaHomepagePrompt(false));
  };
};

export const createInvite = (data, resetForm) => {
  return (dispatch, getState) => {
    let url = API_URLS.createInvite;
    let staticHeader = createHeader();
    dispatch(actionCreator(CONSTANTS.CREATEINVITE.REQUEST));
    callApi("POST", url, data, false, staticHeader)
      .then((res) => {
        resetForm();
        dispatch(actionCreator(CONSTANTS.CREATEINVITE.SUCCESS, res.data));
        dispatch(addMessage("Invite sent successfully.", "success"));
      })
      .catch((err) => {
        dispatch(actionCreator(CONSTANTS.CREATEINVITE.FAILURE, err?.response?.data));
        dispatch(addMessage("Sorry, Invite sent fail. Please try again." + err, "error"));
      });
  };
};

export const resetInvite = () => {
  return (dispatch, _getState) => {
    dispatch(actionCreator(CONSTANTS.RESETINVITE.REQUEST));
  };
};

export const isGroupInvitationValid = (invitationId) => {
  return (dispatch, getState) => {
    let url = API_URLS.isGroupInvitationValid(invitationId);
    let staticHeader = createHeader();
    dispatch(actionCreator(CONSTANTS.ISGROUPINVITATIONVALID.REQUEST));
    callApi("GET", url, {}, false, staticHeader)
      .then((res) => {
        dispatch(actionCreator(CONSTANTS.ISGROUPINVITATIONVALID.SUCCESS, res.data));
      })
      .catch((err) => {
        dispatch(
          actionCreator(CONSTANTS.ISGROUPINVITATIONVALID.FAILURE, err?.response?.data?.status)
        );
      });
  };
};

export const getGroupInfo = (groupUrl) => {
  return (dispatch, getState) => {
    let url = API_URLS.getGroupInfo(groupUrl);
    let staticHeader = createHeader();
    dispatch(actionCreator(CONSTANTS.GETGROUPINFO.REQUEST));
    callApi("GET", url, {}, false, staticHeader)
      .then((res) => {
        dispatch(actionCreator(CONSTANTS.GETGROUPINFO.SUCCESS, res.data));
      })
      .catch((err) => {
        dispatch(actionCreator(CONSTANTS.GETGROUPINFO.FAILURE, err?.response?.data?.status));
      });
  };
};

export const getAllMembers = (groupId, isSponsor) => {
  return async (dispatch, getState) => {
    try {
      dispatch(actionCreator(CONSTANTS.GETGROUPMEMBERS.REQUEST));
      const groupMembersUrl = API_URLS.getGroupMembers(groupId);
      const pendingMembersUrl = API_URLS.getPendingInvitations(groupId);
      const staticHeader = createHeader();
      const accepted = await callApi(GET, groupMembersUrl, {}, false, staticHeader);
      const pending = isSponsor
        ? await callApi(GET, pendingMembersUrl, {}, false, staticHeader)
        : { data: [] };
      const pendingData = typeof pending.data === "string" ? [] : pending.data;
      // combining data & adding additional keys according to requirement
      const payload = combineGroupMembers(accepted.data, pendingData);
      dispatch(actionCreator(CONSTANTS.GETGROUPMEMBERS.SUCCESS, payload));
    } catch (err) {
      dispatch(actionCreator(CONSTANTS.GETGROUPMEMBERS.FAILURE, err?.response?.data?.status));
    }
  };
};

export const getUserGroups = () => {
  return (dispatch, getState) => {
    let url = API_URLS.getUserGroupsListApi(),
      staticHeader = createHeader();
    dispatch(actionCreator(CONSTANTS.GETUSERGROUPSLIST.REQUEST));
    return callApi("GET", url, {}, false, staticHeader)
      .then((res) => {
        dispatch(actionCreator(CONSTANTS.GETUSERGROUPSLIST.SUCCESS, res.data));
        return res.data;
      })
      .catch(() => {
        dispatch(actionCreator(CONSTANTS.GETUSERGROUPSLIST.FAILURE));
      });
  };
};

export const getUserGroupSponsoredList = () => {
  return (dispatch, getState) => {
    let url = API_URLS.getUserGroupsSponsoredApi(),
      staticHeader = createHeader();
    dispatch(actionCreator(CONSTANTS.GETUSERGROUPSSPONSOREDLIST.REQUEST));
    return callApi("GET", url, {}, false, staticHeader)
      .then((res) => {
        dispatch(actionCreator(CONSTANTS.GETUSERGROUPSSPONSOREDLIST.SUCCESS, res.data));
      })
      .catch(() => {
        dispatch(actionCreator(CONSTANTS.GETUSERGROUPSSPONSOREDLIST.FAILURE));
      });
  };
};

export const groupAcceptInvite = (inviteId) => {
  return (dispatch, getState) => {
    let url = API_URLS.acceptGroupInviteApi(inviteId),
      staticHeader = createHeader();
    dispatch(actionCreator(CONSTANTS.ACCEPTGROUPINVITE.REQUEST));
    callApi("POST", url, {}, false, staticHeader)
      .then((res) => {
        dispatch(actionCreator(CONSTANTS.ACCEPTGROUPINVITE.SUCCESS, res.data));
      })
      .catch(() => {
        dispatch(actionCreator(CONSTANTS.ACCEPTGROUPINVITE.FAILURE));
      });
  };
};

export const groupAcceptJoin = (inviteId, groupInfo) => {
  return (dispatch, getState) => {
    let url = API_URLS.acceptGroupJoinApi(inviteId),
      staticHeader = createHeader();
    dispatch(actionCreator(CONSTANTS.ACCEPTGROUPJOIN.REQUEST));
    callApi("POST", url, {}, false, staticHeader)
      .then(async (res) => {
        dispatch(actionCreator(CONSTANTS.ACCEPTGROUPJOIN.SUCCESS, res.data));
        const response = await apiRequest(GET, `Users/sessiongroupmemberinfo`);
        setGroupInfoCookie(response.data?.groupMemberRole);
        if (response.data?.groupMemberRole) {
          let finalGroupPayload = [];
          finalGroupPayload = getUpdateGroupInfo(groupInfo, response.data?.groupMemberRole);
          dispatch(actionCreator("UPDATE_COOKIE_GROUP_INFO", finalGroupPayload));
        }
      })
      .catch(() => {
        dispatch(actionCreator(CONSTANTS.ACCEPTGROUPJOIN.FAILURE));
      });
  };
};

export const getUserRole = (groupId) => {
  return (dispatch, getState) => {
    let url = API_URLS.getUserRole(groupId);
    let staticHeader = createHeader();
    dispatch(actionCreator(CONSTANTS.GETUSERROLE.REQUEST));
    callApi("GET", url, {}, false, staticHeader)
      .then((res) => {
        dispatch(actionCreator(CONSTANTS.GETUSERROLE.SUCCESS, res.data));
      })
      .catch((err) => {
        dispatch(actionCreator(CONSTANTS.GETUSERROLE.FAILURE, err?.response?.data?.status));
      });
  };
};

export const initialUserRole = () => {
  return (dispatch) => {
    dispatch({
      type: INITIALUSERROLE,
      payload: ""
    });
  };
};

export const followUser = (userId, username, index, setFilteredMembers, fromModal = false) => {
  return (dispatch, getState) => {
    let url = API_URLS.followUser(userId),
      staticHeader = createHeader();
    dispatch(actionCreator(CONSTANTS.FOLLOWUSER.REQUEST, true));
    callApi("POST", url, {}, false, staticHeader)
      .then(() => {
        if (!fromModal) {
          setFilteredMembers((state) => {
            return state.map((item) => {
              return item.userId === userId ? { ...item, isFollowing: true } : item;
            });
          });
        }
        dispatch(actionCreator(CONSTANTS.FOLLOWUSER.SUCCESS, { userId, index, isFollowing: true }));
        dispatch(addMessage(`You are now following ${username}`, "success"));
      })
      .catch(() => {
        dispatch(actionCreator(CONSTANTS.FOLLOWUSER.FAILURE, false));
      });
  };
};

export const unfollowUser = (userId, username, index, setFilteredMembers, fromModal = false) => {
  return (dispatch, getState) => {
    let url = API_URLS.unfollowUser(userId),
      staticHeader = createHeader();
    dispatch(actionCreator(CONSTANTS.UNFOLLOWUSER.REQUEST, true));
    callApi("POST", url, {}, false, staticHeader)
      .then(() => {
        if (!fromModal) {
          setFilteredMembers((state) => {
            return state.map((item) => {
              return item.userId === userId ? { ...item, isFollowing: false } : item;
            });
          });
        }
        dispatch(
          actionCreator(CONSTANTS.UNFOLLOWUSER.SUCCESS, { userId, index, isFollowing: false })
        );
        dispatch(addMessage(`You are no longer following ${username}`, "success"));
      })
      .catch(() => {
        dispatch(actionCreator(CONSTANTS.UNFOLLOWUSER.FAILURE, false));
      });
  };
};

export const removeFromGroupApi = (payload, callback, leaveGroup = false) => {
  return (dispatch, getState) => {
    let url = API_URLS.RemoveFromGroupApi(payload),
      staticHeader = createHeader();
    dispatch(actionCreator(CONSTANTS.REMOVEFROMGROUP.REQUEST));
    callApi("POST", url, {}, false, staticHeader)
      .then(() => {
        callback(payload);
        dispatch(actionCreator(CONSTANTS.REMOVEFROMGROUP.SUCCESS, payload));
        if (leaveGroup) {
          dispatch(addMessage(`You left the group`, "success"));
        } else {
          dispatch(addMessage(`Group member removed successfully`, "success"));
        }
      })
      .catch(() => {
        dispatch(actionCreator(CONSTANTS.REMOVEFROMGROUP.FAILURE));
        dispatch(addMessage("Sorry, something went wrong. Please try again.", "error"));
      });
  };
};

export const checkGroupCreationRightsApi = (successCallback, errorCallback) => {
  return async (dispatch, getState) => {
    let url = API_URLS.CheckGroupCreationRightsApi(),
      staticHeader = createHeader();
    try {
      await callApi("GET", url, {}, false, staticHeader);
      successCallback(true);
    } catch (err) {
      const statusCode = [402, 403];
      if (statusCode.includes(err.response.status)) {
        return errorCallback(err.response);
      }
      dispatch(addMessage("Sorry, something went wrong. Please try again.", "error"));
    }
  };
};

export const getPendingInvites = () => {
  return async (dispatch, getState) => {
    let url = API_URLS.getPendingInvites;
    let staticHeader = createHeader();
    dispatch(actionCreator(CONSTANTS.GETPENDINGINVITES.REQUEST));
    try {
      const res = await callApi("GET", url, {}, false, staticHeader);
      dispatch(actionCreator(CONSTANTS.GETPENDINGINVITES.SUCCESS, res.data));
    } catch (error) {
      dispatch(actionCreator(CONSTANTS.GETPENDINGINVITES.FAILURE));
    }
  };
};

export const getInviterInfo = (inviteId) => {
  return (dispatch, getState) => {
    let url = API_URLS.getInviterInfo(inviteId);
    let staticHeader = createHeader();
    dispatch(actionCreator(CONSTANTS.GETINVITERINFO.REQUEST));
    callApi("GET", url, {}, false, staticHeader)
      .then((res) => {
        dispatch(actionCreator(CONSTANTS.GETINVITERINFO.SUCCESS, res.data));
      })
      .catch(() => {
        dispatch(actionCreator(CONSTANTS.GETINVITERINFO.FAILURE));
      });
  };
};

export const resetInviteDetails = () => {
  return (dispatch) => {
    dispatch({
      type: RESETINVITEDETAILS
    });
  };
};
export const importStoriesTree = (param, setShowImportDialog, setLoadStoriesData) => {
  return (dispatch, getState) => {
    if (param.groupId !== getState().feeds.groupId) {
      let url = API_URLS.getTreeUserpageStories(param);
      let staticHeader = createHeader();
      dispatch(actionCreator(CONSTANTS.GETSTORIESFEED.REQUEST));
      callApi("GET", url, {}, false, staticHeader)
        .then((res) => {
          const dataRespo = res.data;
          if (dataRespo.length > 0) {
            setShowImportDialog(true);
            setLoadStoriesData(dataRespo);
          }
        })
        .catch((err) => {
          dispatch(actionCreator(CONSTANTS.GETSTORIESFEED.FAILURE, err?.response?.data));
        });
    }
  };
};

export const groupImportStoriesApi = (param, type, callback, storiesImported = []) => {
  return (dispatch, getState) => {
    let url =
        type === "YES"
          ? API_URLS.GroupImportStoriesApi(param)
          : API_URLS.GroupImportStoriesDismisApi(param),
      staticHeader = createHeader();
    callApi("POST", url, {}, false, staticHeader)
      .then(() => {
        dispatch(actionCreator(CONSTANTS.UPDATEGROUPDETAILS.SUCCESS, param.isImportSotries));
        if (type === "YES") {
          dispatch(
            actionCreator(CONSTANTS.GETSTORIESFEED.SUCCESS, [
              ...storiesImported,
              ...getState().feeds.stories
            ])
          );
        }
        callback(false);
      })
      .catch(() => {
        callback(false);
        dispatch(addMessage("Sorry, something went wrong. Please try again.", "error"));
      });
  };
};

export const uploadGroupImage = (imagePayload) => async (dispatch) => {
  try {
    dispatch({ type: CONSTANTS.UPLOAD_GROUP_IMAGE.REQUEST, payload: imagePayload });
    dispatch({ type: THUMBNAIL_UPLOAD_COMPLETE });
    const payload = groupImageUploadPayload(imagePayload);
    await apiRequest(POST, `Media/uploadgroupbackgroundimage`, payload);
    dispatch(getSideBarUserGroups());
    dispatch({ type: SAVE_HERO_IMAGE });
    dispatch(addMessage("Group image uploaded successfully.", "success"));
  } catch (err) {
    dispatch({ type: CONSTANTS.UPLOAD_GROUP_IMAGE.FAILURE });
    dispatch(addMessage("Sorry, something went wrong. Please try again.", "error"));
  }
};

export const checkTreePersonStatus = (groupId) => async (dispatch) => {
  try {
    dispatch({ type: CONSTANTS.CHECK_TREE_PERSON_STATUS.REQUEST });
    const res = await apiRequest(GET, `Group/memberIsTreePersonStatus?groupId=${groupId}`);
    if (res.data?.treePersonId && !res.data.isConfirmed) {
      const personId = res.data.treePersonId;
      const res1 = await apiRequest(GET, `Persons/${personId}/treePersonInfo`);
      dispatch({ type: CONSTANTS.CHECK_TREE_PERSON_STATUS.SUCCESS, payload: res1.data });
    } else {
      dispatch({ type: CONSTANTS.CHECK_TREE_PERSON_STATUS.FAILURE });
    }
  } catch (err) {
    dispatch({ type: CONSTANTS.CHECK_TREE_PERSON_STATUS.FAILURE });
  }
};

export const confirmMemberStatus = (groupId, isAccepted) => async (dispatch) => {
  try {
    dispatch({ type: CONSTANTS.CONFIRM_MEMBER_STATUS.REQUEST });
    await apiRequest(
      POST,
      `Group/confirmMemberIsTreePerson?groupId=${groupId}&isAccepted=${isAccepted}`
    );
    dispatch({ type: CONSTANTS.CONFIRM_MEMBER_STATUS.SUCCESS });
    dispatch(addMessage("Confirmed successfully.", "success"));
  } catch (err) {
    dispatch({ type: CONSTANTS.CONFIRM_MEMBER_STATUS.FAILURE });
    dispatch(addMessage("Sorry, something went wrong. Please try again.", "error"));
  }
};

export const editGroupImage = (imagePayload) => async (dispatch) => {
  try {
    dispatch({ type: CONSTANTS.UPLOAD_GROUP_IMAGE.REQUEST, payload: imagePayload });
    dispatch({ type: THUMBNAIL_UPLOAD_COMPLETE });
    const payload = groupImageUploadPayload(imagePayload, true);
    await apiRequest(POST, `Media/resizegroupbackgroundimage`, payload);
    dispatch(getSideBarUserGroups());
    dispatch({ type: SAVE_HERO_IMAGE });
    dispatch(addMessage("Group image updated successfully", "success"));
  } catch (err) {
    dispatch({ type: CONSTANTS.UPLOAD_GROUP_IMAGE.FAILURE });
    dispatch(addMessage("Sorry, something went wrong. Please try again.", "error"));
  }
};

export const claimSponsorshipApi = (groupId, groupName, callback) => {
  return async (dispatch, getState) => {
    let url = API_URLS.ClaimSponsorshipApi(groupId);
    let staticHeader = createHeader();
    dispatch({ type: CONSTANTS.CLAIMSPONSORSHIP.REQUEST });
    try {
      await callApi("POST", url, {}, false, staticHeader);
      const isSponsorshipActive = true;
      dispatch({ type: CONSTANTS.CLAIMSPONSORSHIP.SUCCESS, payload: isSponsorshipActive });
      dispatch(addMessage(`You are now the sponsor for ${groupName} Group`, "success"));
      callback && callback();
    } catch (err) {
      dispatch(addMessage(err.response?.data?.[0]?.description, "error"));
      dispatch({ type: CONSTANTS.CLAIMSPONSORSHIP.FAILURE });
    }
  };
};

export const deleteGroupImage = (groupId) => async (dispatch) => {
  try {
    dispatch({ type: CONSTANTS.DELETE_GROUP_IMAGE.REQUEST });
    await apiRequest(DELETE, `Group/deletegroupbackgroundimage/${groupId}`);
    dispatch(getSideBarUserGroups());
    dispatch({ type: CONSTANTS.DELETE_GROUP_IMAGE.SUCCESS });
    dispatch(addMessage("Group image removed successfully.", "success"));
  } catch (err) {
    dispatch({ type: CONSTANTS.DELETE_GROUP_IMAGE.FAILURE });
    dispatch(addMessage("Sorry, something went wrong. Please try again.", "error"));
  }
};

export const leaveGroupApi = (groupId, callback) => {
  return async (dispatch, getState) => {
    let url = API_URLS.LeaveGroupApi(groupId);
    let staticHeader = createHeader();
    dispatch(actionCreator(CONSTANTS.LEAVEGROUP.REQUEST));
    try {
      await callApi("POST", url, {}, false, staticHeader);
      dispatch(leaveSidebarUserGroup({ groupId }));
      dispatch(actionCreator(CONSTANTS.LEAVEGROUP.SUCCESS, groupId));
      dispatch(addMessage("Group removed successfully.", "success"));
      callback();
    } catch (err) {
      dispatch(actionCreator(CONSTANTS.LEAVEGROUP.FAILURE));
      dispatch(addMessage("Sorry, something went wrong. Please try again.", "error"));
    }
  };
};

export const cancelGroupSponsorShipApi = (groupId, callback) => {
  return async (dispatch, getState) => {
    let url = API_URLS.CancelGroupSponsorShipApi(groupId);
    let staticHeader = createHeader();
    dispatch(actionCreator(CONSTANTS.LEAVEGROUP.REQUEST));
    try {
      await callApi("POST", url, {}, false, staticHeader);
      dispatch(actionCreator(CONSTANTS.LEAVEGROUP.SUCCESS, groupId));
      dispatch(addMessage("Group sponsorship removed successfully.", "success"));
      callback();
    } catch (error) {
      dispatch(actionCreator(CONSTANTS.LEAVEGROUP.FAILURE));
      dispatch(addMessage("Sorry, something went wrong. Please try again.", "error"));
    }
  };
};

export const getGroupUrl = (invitationId) => {
  return async (dispatch, getState) => {
    let url = API_URLS.getGroupUrl(invitationId);
    let staticHeader = createHeader();
    dispatch(actionCreator(CONSTANTS.GETGROUPURL.REQUEST));
    try {
      const res = await callApi("GET", url, {}, false, staticHeader);
      dispatch(actionCreator(CONSTANTS.GETGROUPURL.SUCCESS, res.data));
    } catch (err) {
      dispatch(actionCreator(CONSTANTS.GETGROUPURL.FAILURE, err?.response?.data?.status));
    }
  };
};

export const emptyGroupMembers = () => {
  return (dispatch) => {
    dispatch({ type: EMPTYGROUPMEMBERS });
  };
};

export const cancelInvite = (invitationId, callback) => {
  return async (dispatch, getState) => {
    let url = API_URLS.cancelInvite(invitationId);
    let staticHeader = createHeader();
    dispatch(actionCreator(CONSTANTS.CANCELINVITE.REQUEST));
    try {
      await callApi("POST", url, {}, false, staticHeader);
      dispatch(actionCreator(CONSTANTS.CANCELINVITE.SUCCESS, invitationId));
      callback(invitationId);
      dispatch(addMessage(" Cancelled invite successfully.", "success"));
    } catch (error) {
      dispatch(actionCreator(CONSTANTS.CANCELINVITE.FAILURE));
      dispatch(addMessage("Sorry, something went wrong. Please try again.", "error"));
    }
  };
};
