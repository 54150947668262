import * as components from "pages/Routes/components/publicPages";

const publicRoutes = [
  {
    path: "/",
    component: components.LoggedOutHomepage
  },
  {
    path: "/getStarted",
    component: components.LoggedOutHomepage
  },
  {
    path: "/terms",
    component: components.Terms
  },
  {
    path: "/privacy",
    component: components.Privacy
  },
  {
    path: "/r/*",
    component: components.PublicRedirector
  },
  {
    path: "/dpa",
    component: components.DPA
  },
  {
    path: "/group-invitation/:inviteId",
    component: components.GroupInvitationPage
  },
  {
    path: "/groups",
    component: components.AboutGroup
  },
  {
    path: "/plans",
    component: components.PlanPage
  },
  {
    path: "/family-trees",
    component: components.LoggedOutTreespage
  },
  {
    path: "/people",
    component: components.LoggedOutConnectionspage
  },
  {
    path: "/stories",
    component: components.LoggedOutStoriesPage
  },
  {
    path: "/freetrials",
    component: components.LoggedOutFreeTrials
  },
  {
    path: "/payment/bundle/trial/old",
    component: components.LoggedOutFreeTrialBundle
  },
  {
    path: "/payment/bundle/annual-only",
    component: components.AnnualOnly
  },
  {
    path: "/payment/bundle/trial/ultimate",
    component: components.Ultimate
  },
  {
    path: "/payment/bundle/trial",
    component: components.OfferPage,
    props: {
      isToggle: true
    }
  },
  {
    path: "/payment/bundle/trial/grid",
    component: components.OfferPage,
    props: {
      isToggle: false
    }
  },
  {
    path: "/about",
    component: components.AboutPage
  },
  {
    path: "/ccpa",
    component: components.Ccpa
  },
  {
    path: "/storiedbook/view/:bookId",
    component: components.ViewStoryBook
  },
  {
    path: "/storiedbook/order/:bookId",
    component: components.OrderStoryBook
  },
  {
    path: "/storiedbooks",
    component: components.StoriedBooksPage
  },
  {
    path: "/plan-selection/:planName",
    component: components.LoggedOutPlanSelection
  },
  {
    path: "/mobile-iframe-test",
    component: components.MobileIframeTest
  },
  {
    path: "/person/profile/:primaryPersonId",
    component: components.PersonProfile
  }
];

export default publicRoutes;
