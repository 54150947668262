import * as CONSTANTS from "../constants/actionTypes";
import { actionCreator } from "../utils";
import { AppDispatch } from "redux/store";

export type SnackbarType = "success" | "error" | "notification";
type Cta = { action?: () => void, text?: "View" };

export type SnackbarMessage = {
    key: any,
    content: string,
    type: SnackbarType,
    canClose: boolean,
    url?: string,
    cta?: Cta,
    showIcon: boolean | undefined
};

export function addMessage(message: string, type: SnackbarType = "success", meta?: { url?: string, cta?: Cta, showIcon: true }) {
    const options = { canClose: true, cta: null, ...meta };
    if (options.url && type !== 'notification') {
        return (dispatch: AppDispatch) => {
            dispatch(actionCreator<SnackbarMessage>(CONSTANTS.ADDTMESSAGE, {
                key: new Date().getTime(),
                content: message,
                type: type,
                canClose: false,
                url: options.url,
                showIcon: options.showIcon,
                ...(options.cta?.action ? { cta: options.cta } : {})
            }));
        };
    } else if (options.url && type === 'notification') {
        return (dispatch: AppDispatch) => {
            dispatch(actionCreator<SnackbarMessage>(CONSTANTS.ADDTMESSAGE, {
                key: new Date().getTime(),
                content: message,
                type: type,
                canClose: options.canClose,
                url: options.url,
                showIcon: options.showIcon,
                ...(options.cta?.action ? { cta: options.cta } : {})
            }));
        };
    } else {
        return (dispatch: AppDispatch) => {
            dispatch(actionCreator<SnackbarMessage>(CONSTANTS.ADDTMESSAGE, {
                key: new Date().getTime(),
                content: message,
                type: type,
                canClose: options.canClose,
                showIcon: options.showIcon,
                ...(options.cta?.action ? { cta: options.cta } : {})
            }));
        };
    }
}
export const removeMessage = (messageId: any) => {
    return (dispatch: AppDispatch) => {
        dispatch(actionCreator(CONSTANTS.REMOVETMESSAGE, messageId));
    }
}