/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  WhitelistAddToWhitelistData,
  WhitelistAddToWhitelistPayload,
  WhitelistIsInWhitelistData,
  WhitelistIsInWhitelistParams,
  WhitelistWhiteListData
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Whitelist<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Whitelist
   * @name WhitelistAddToWhitelist
   * @request PUT:/api/Whitelist/AddToWhitelist
   * @secure
   */
  whitelistAddToWhitelist = (data: WhitelistAddToWhitelistPayload, params: RequestParams = {}) =>
    this.request<WhitelistAddToWhitelistData, any>({
      path: `/api/Whitelist/AddToWhitelist`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Whitelist
   * @name WhitelistIsInWhitelist
   * @request GET:/api/Whitelist/IsInWhitelist
   * @secure
   */
  whitelistIsInWhitelist = (query: WhitelistIsInWhitelistParams, params: RequestParams = {}) =>
    this.request<WhitelistIsInWhitelistData, any>({
      path: `/api/Whitelist/IsInWhitelist`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags Whitelist
   * @name WhitelistWhiteList
   * @request GET:/api/Whitelist/WhiteList
   * @secure
   */
  whitelistWhiteList = (params: RequestParams = {}) =>
    this.request<WhitelistWhiteListData, any>({
      path: `/api/Whitelist/WhiteList`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    });
}
