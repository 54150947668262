import { showBirthandDeath } from "shared-logics";
export const cardType = {
  FOCUSED_LIVING: "focused-living",
  FOCUSED_DECEASED: "focused-deceased",
  FOCUSED_DECEASED_AddIMAGE: "focused-deceased-AddImage",
  FOCUSED_DECEASED_NOIMAGE: "focused-deceased-NoImage",
  FOCUSED_DECEASED_DATE: "focused-deceased-Date",
  MEDIUM_IMG: "medium-img",
  MEDIUM: "medium",
  MEDIUM_NEXT_GEN: "medium-next-gen",
  SMALL_IMG: "small-img",
  SMALL_BLANK: "small-blank",
  SMALL: "small",
  SMALL_NEXT_GEN: "small-next-gen"
};

export const cardGender = {
  MALE: "Male",
  FEMALE: "Female",
  OTHER: "Other",
  DEFAULT: "Default"
};

export const tr = (t, tkey) => {
  const getQueryParams = new URLSearchParams(window.location.search);
  const lng = getQueryParams.get("lng");
  let wLang = false;
  if (lng && lng.toLocaleLowerCase() === "w") {
    wLang = true;
  }
  const w = (wlng) => {
    let ttext = t(wlng);
    let str = "";
    for (const text of ttext.split(" ")) {
      let len = text.length;
      while (len > 0) {
        str = str + "w";
        len--;
      }
      str = str + " ";
    }
    return str;
  };
  if (wLang) {
    return w(`${tkey}`);
  } else {
    return t(`${tkey}`);
  }
};

/**
 *
 * @param {string[][] | Record<string, string> | string | URLSearchParams | null | undefined} addQuery
 * @returns
 */
export const getQueryParam = (addQuery = null) => {
  if (window.location.href.split("?").length > 1) {
    const existingQuery = window.location.href.split("?")[1];
    const existingParams = new URLSearchParams(existingQuery);
    if (addQuery) {
      const newParams = new URLSearchParams(addQuery);
      for (const [key, value] of newParams) {
        existingParams.set(key, value);
      }
      return `?${existingParams.toString()}`;
    } else {
      return `?${existingQuery}`;
    }
  } else {
    return addQuery ? `?${addQuery}` : "";
  }
};

export const getType = (path, title, type, nodesizecheck, nodetypecheck) => {
  const nodePath = path ? path.split("/") : [];
  switch (true) {
    case nodetypecheck === "Blank":
      return cardType.SMALL_BLANK;
    case nodesizecheck > 20 && nodetypecheck === "sChild":
    case nodesizecheck > 20 && nodetypecheck === "directChildren":
    case nodetypecheck === "directChildspouse":
    case nodetypecheck === "sibling":
    case type === cardType.SMALL_IMG:
      return cardType.SMALL_IMG;
    case nodetypecheck === "spouse":
      return cardType.MEDIUM_IMG;
    case nodePath.length === 0: {
      if (nodetypecheck === "focus") return cardType.FOCUSED_LIVING;
      else return cardType.FOCUSED_DECEASED;
    }
    default: {
      switch (true) {
        case nodePath.length % 4 === 0 && title !== "":
          return cardType.SMALL_NEXT_GEN;
        case nodePath.length <= 3 && title !== "":
        case nodePath.length >= 5 && title !== "":
          return cardType.MEDIUM_NEXT_GEN;
        case nodePath.length % 4 === 0:
          return cardType.SMALL_IMG;
        case nodePath.length >= 5:
        case nodePath.length <= 3:
          return cardType.MEDIUM_IMG;
        default:
          return cardType.SMALL_IMG;
      }
    }
  }
};

export const dateToLocalString = (
  date,
  zone = undefined,
  options = { weekday: "long", year: "numeric", month: "short", day: "numeric" }
) => new Date(date).toLocaleDateString(zone, options);

export const isObjEmpty = (object = {}) => (Object.keys(object).length ? false : true);

export const getDateString = (option, isOwnerFlag, isMember) => {
  const birthDate = option?.birthDate?.year || option?.birthYear;
  const deathDate = option?.deathDate?.year || option?.deathYear;
  const isLiving = option?.isLiving;
  const isOwner = isOwnerFlag || isMember;
  const isPrivate = option?.isPrivateTree;
  if (option?.petType) {
    return option.petType;
  }
  return showBirthandDeath(birthDate, deathDate, isLiving, isOwner, isPrivate);
};
export const generateRandomNumber = (min, max) => {
  // Create byte array and fill with 1 random number
  let byteArray = new Uint8Array(1);
  window?.crypto?.getRandomValues(byteArray);

  let range = max - min + 1;
  let max_range = 256;
  if (byteArray[0] >= Math.floor(max_range / range) * range) return generateRandomNumber(min, max);
  return min + (byteArray[0] % range);
};

export const tableTypes = {
  PERSONAL_INFO: "PERSONAL_INFO",
  EVENTS: "EVENTS",
  SPOUSES_AND_CHILDREN: "SPOUSES_AND_CHILDREN",
  PARENTS_AND_SIBLINGS: "PARENTS_AND_SIBLINGS",
  LIFE_EVENTS: "LIFE_EVENTS",
  PET_DETAILS: "PET_DETAILS"
};

export const iconsList = [
  "iconLink2",
  "collapse",
  "share",
  "info",
  "comment",
  "folder",
  "iconDrawerClose",
  "home",
  "plus",
  "minus",
  "iconUsers",
  "aim",
  "delete",
  "family",
  "upload",
  "user-single",
  "dashboard",
  "edit",
  "add-circle",
  "search",
  "plant",
  "file",
  "tree",
  "downArrow",
  "upArrow",
  "addPhoto",
  "uploadFile",
  "uploadFileError",
  "errorImg",
  "hamburger",
  "camera",
  "happySmile",
  "moneyWallet",
  "xlargeTree",
  "rightArrow",
  "story2",
  "mIconChevronDown",
  "mIconBookPersion",
  "mIconPhotoGallery",
  "mIconHome",
  "mIconBookPersion",
  "mIconPlus",
  "mIconChevronUp",
  "mIconChevronUpBold",
  "mIconStory",
  "mIconNewspaper",
  "mIconRecord",
  "mIconPeople",
  "mIconGroup",
  "mIconChevronDownBold",
  "mIconTree",
  "card",
  "newspaperSearch",
  "recordSearch",
  "people",
  "groups",
  "close",
  "uploadUp",
  "iconEdit",
  "reportstorydropdown",
  "ellipsisHorizontal",
  "selectInputDownArrow",
  "personOutline",
  "editOutline",
  "addPeople",
  "personRecenter",
  "treeThin",
  "iconInvite",
  "iconOpenBook",
  "iconRecord",
  "iconCreateGroup",
  "iconAccess",
  "iconHistory",
  "iconCurrency",
  "iconClue",
  "iconClueLine",
  "iconClueHover",
  "iconInfo",
  "iconTools",
  "iconGlobe",
  "iconHistory2",
  "lightBulb",
  "searchOne",
  "aim2",
  "filter",
  "minus2",
  "plus2",
  "tooltipQuesMark",
  "connection",
  "menuHorizontal",
  "arrowLeft",
  "cross",
  "iconTree",
  "tooltipQuesMark",
  "rightArrowThin",
  "settings",
  "profile-circle",
  "trees",
  "envolope",
  "leftArrow",
  "trash",
  "iconPrint",
  "iconDrawerOpen",
  "iconDrawerClose",
  "iconDownload",
  "iconLink",
  "closePanel",
  "checkCircle",
  "IncompleteCircle",
  "ellipsisVertical",
  "longArrowLeft",
  "iconUpload",
  "help",
  "iconWarning",
  "storyCrossIcon",
  "iconExpand",
  "iconOpenBookSolidLine",
  "iconMagic",
  "iconUsers",
  "plusThin",
  "logo",
  "watermark",
  "iconDrag",
  "cropResize",
  "earth",
  "iconEarth",
  "FilterControlSettings",
  "ClockHistory",
  "iconQR",
  "Photo",
  "iconPages",
  "checkThick",
  "PromptPen",
  "uploadnew",
  "doubleArrow",
  "uploadGedcom",
  "plusStartTree",
  "LineIcon"
];

export const getFileSizeLocizeKey = (fileSize) => {
  let size = parseFloat(fileSize);
  switch (true) {
    case size >= 0 && size < 100:
      return "treeInfo.delay1";
    case size >= 100 && size <= 499.999:
      return "treeInfo.delay2";
    case size >= 500 && size <= 1999:
      return "treeInfo.delay5";
    case size >= 2000 && size <= 3999:
      return "treeInfo.delay10";
    case size >= 4000 && size <= 16999:
      return "treeInfo.delay30";
    case size >= 17000:
      return "treeInfo.delayMax";
    default:
      return; //nothing
  }
};

export const basePersonPayload = {
  "id": "",
  "parentId": "c7346f6a-fd05-4714-b477-2f48038e88d7",
  "cFilialId": "",
  "type": "medium-next-gen",
  "path": "0/0/1",
  "title": "Potential Mother",
  "firstName": "",
  "firstNameWithInitials": "",
  "lastName": "",
  "isLiving": true,
  "gender": "Female",
  "birth": "",
  "birthPlace": "",
  "death": "",
  "deathPlace": "",
  "imgsrc": "",
  "relatedParentIds": null,
  "residenceEvents": []
} 

export const potentialParentsPayload = {
  "potentialParents": [
      {
          "personId": "00000000-0000-0000-0000-000000000000",
          "givenName": "Rawsel",
          "surname": "Bradford",
          "gender": "Male",
          "birthDate": {
              "rawDate": "13 July 1833",
              "isApproximate": false,
              "normalizedDate": "1833-07-13",
              "qualifier": {
                  "value": "None"
              },
              "isRange": false,
              "fromDate": null,
              "toDate": null,
              "isNormalized": true,
              "day": 13,
              "month": 7,
              "year": 1833,
              "id": {
                  "type": "DateId",
                  "id": "69d917d3-ff97-44f8-be4b-a24afce1f0b5"
              },
              "lastChangedDate": "2024-08-02T06:29:00.7547017Z",
              "notes": [],
              "supportingEvidence": [],
              "externalIds": []
          },
          "birthLocation": "Cotton Township, Switzerland, Indiana, United States",
          "deathDate": {
              "rawDate": "13 October 1897",
              "isApproximate": false,
              "normalizedDate": "1897-10-13",
              "qualifier": {
                  "value": "None"
              },
              "isRange": false,
              "fromDate": null,
              "toDate": null,
              "isNormalized": true,
              "day": 13,
              "month": 10,
              "year": 1897,
              "id": {
                  "type": "DateId",
                  "id": "78196a35-18b6-4ad0-803e-419086483046"
              },
              "lastChangedDate": "2024-08-02T06:29:00.7548699Z",
              "notes": [],
              "supportingEvidence": [],
              "externalIds": []
          },
          "deathLocation": "Salt Lake City, Salt Lake, Utah, United States",
          "collectionID": "4f2e7041-8339-4caf-a465-ed2b8016014d",
          "collectionName": "FamilySearch Family Tree",
          "imageId": "00000000-0000-0000-0000-000000000000",
          "recordPersonId": {
              "id": "085174c7-0423-156a-bf04-53f136ea8923",
              "partitionKey": "4f2e7041-8339-4caf-a465-ed2b8016014d@KWDS-2LK"
          },
          "newPersonHintType": "Father",
          "source": "AcceptedHint:daa44633-8aac-4bd8-a6c4-7380dfe9b977",
          "pendingParentHintId": "64474855-6f63-48b2-95f7-0278d9619f00",
          "hintStatus": "pending",
          "age": "64"
      },
      {
          "personId": "00000000-0000-0000-0000-000000000000",
          "givenName": "Jane",
          "surname": "Gardner",
          "gender": "Female",
          "birthDate": {
              "rawDate": "21 August 1833",
              "isApproximate": false,
              "normalizedDate": "1833-08-21",
              "qualifier": {
                  "value": "None"
              },
              "isRange": false,
              "fromDate": null,
              "toDate": null,
              "isNormalized": true,
              "day": 21,
              "month": 8,
              "year": 1833,
              "id": {
                  "type": "DateId",
                  "id": "acc39e7c-6e3f-4bee-ad7b-8ff4f55151ce"
              },
              "lastChangedDate": "2024-08-02T06:29:00.7634658Z",
              "notes": [],
              "supportingEvidence": [],
              "externalIds": []
          },
          "birthLocation": "Dalhousie Township, Lanark, Ontario, Canada",
          "deathDate": {
              "rawDate": "23 February 1907",
              "isApproximate": false,
              "normalizedDate": "1907-02-23",
              "qualifier": {
                  "value": "None"
              },
              "isRange": false,
              "fromDate": null,
              "toDate": null,
              "isNormalized": true,
              "day": 23,
              "month": 2,
              "year": 1907,
              "id": {
                  "type": "DateId",
                  "id": "62f1df70-bad8-4898-bb3c-1a18489c5194"
              },
              "lastChangedDate": "2024-08-02T06:29:00.7636884Z",
              "notes": [],
              "supportingEvidence": [],
              "externalIds": []
          },
          "deathLocation": "Salt Lake City, Salt Lake, Utah, United States",
          "collectionID": "4f2e7041-8339-4caf-a465-ed2b8016014d",
          "collectionName": "FamilySearch Family Tree",
          "imageId": "00000000-0000-0000-0000-000000000000",
          "recordPersonId": {
              "id": "fd4107f0-5608-2780-2322-8987c021869c",
              "partitionKey": "4f2e7041-8339-4caf-a465-ed2b8016014d@KWJH-XLK"
          },
          "newPersonHintType": "Mother",
          "source": "AcceptedHint:daa44633-8aac-4bd8-a6c4-7380dfe9b977",
          "pendingParentHintId": "9bdea4a2-8ec5-402c-9e3e-945e76b829d0",
          "hintStatus": "pending",
          "age": "73"
      }
  ],
  "child": [
      {
          "personId": "c7346f6a-fd05-4714-b477-2f48038e88d7",
          "givenName": "William",
          "surname": "Bradford",
          "gender": "Male",
          "birthDate": {
              "rawDate": "1 Jan 1858",
              "isApproximate": false,
              "normalizedDate": "1858-01-01",
              "qualifier": {
                  "value": "None"
              },
              "isRange": false,
              "fromDate": null,
              "toDate": null,
              "isNormalized": true,
              "day": 1,
              "month": 1,
              "year": 1858,
              "id": {
                  "type": "DateId",
                  "id": "aaae0cf8-9f06-481d-99d6-0bce5103cddd"
              },
              "lastChangedDate": "2024-08-02T06:29:00.7644155Z",
              "notes": [],
              "supportingEvidence": [],
              "externalIds": []
          },
          "birthLocation": "Salt Lake City, Salt Lake, Utah, USA",
          "deathDate": {
              "rawDate": "1924",
              "isApproximate": false,
              "normalizedDate": "",
              "qualifier": {
                  "value": "None"
              },
              "isRange": false,
              "fromDate": null,
              "toDate": null,
              "isNormalized": false,
              "day": null,
              "month": null,
              "year": 1924,
              "id": {
                  "type": "DateId",
                  "id": "838da538-84b3-43ac-854f-294b3f2ba57e"
              },
              "lastChangedDate": "2024-08-02T06:29:00.7645304Z",
              "notes": [],
              "supportingEvidence": [],
              "externalIds": []
          },
          "deathLocation": "Provo, Utah, USA",
          "collectionID": null,
          "collectionName": null,
          "imageId": "00000000-0000-0000-0000-000000000000",
          "recordPersonId": null,
          "newPersonHintType": null,
          "source": null,
          "pendingParentHintId": "00000000-0000-0000-0000-000000000000",
          "hintStatus": "pending",
          "age": "66"
      }
  ]
}