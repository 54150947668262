import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { publicRoutes } from "pages/Routes";

import Loader from "components/Loader";
import { setUserFlow } from "services";

const RedirectToLogin = () => {
  const { loginWithRedirect: auth0LoginWithRedirect, isAuthenticated } = useAuth0();
  const locationUse = useLocation();
  const history = useHistory();
  const locationName = locationUse.pathname;
  const publicRoute = publicRoutes.find((route) => route.path === locationName);
  const search = locationUse?.search;

  useEffect(() => {
    if (isAuthenticated) return;

    if (!publicRoute) {
      setUserFlow("signIn");
      if (locationName === "/login") {
        auth0LoginWithRedirect();
      } else if (locationName === "/link-accounts") {
        auth0LoginWithRedirect({
          authorizationParams: {
            roleOptions: "hideSignUpSocial",
          },
          appState: {
            returnTo: `/accounts${search}`,
          },
        });
      } else {
        auth0LoginWithRedirect({
          appState: {
            returnTo: `${locationName}${search}`,
          },
        });
      }
    }
  }, [auth0LoginWithRedirect, history, locationName, publicRoute, search, isAuthenticated]);

  return (
    <>
      <Loader />
    </>
  );
};

export default RedirectToLogin;
