import API from "API/__generated__";
import { titleCase } from "components/utils/titlecase";
import { PLACEAUTHIRITY } from "redux/constants/apiUrl";
import { callApi } from "redux/utils";
import {
  getLayoutAspect as get_layout_aspects,
  getWidgetOption as getwidgetOption,
  LAYOUT_ID as layout_ids,
  trimString
} from "shared-logics";
import { v4 as uuidv4 } from "uuid";
import {
  decodeJWTtoken,
  getAccessToken,
  getForceFailureApis,
  getGoogleSessionId,
  getOwner,
  getPaymentCookies,
  getSubscription,
  getSubscriptionDetails
} from "../services";
import {
  REACT_APP_CLIENT_ID,
  REACT_APP_COMMUNICATION_GRAPH,
  REACT_APP_ENV,
  REACT_APP_URL
} from "./env";

export const MALE = "Male";
export const FEMALE = "Female";
export const OTHER = "Other";
const UNKNOWN = "Unknown";
export const BLANKID = "00000000-0000-0000-0000-000000000000";
export const FAMILY_SEARCH_COLLECTION_ID = "4f2e7041-8339-4caf-a465-ed2b8016014d";
export const INVALID = "inValid";
export const LAYOUT_ID = layout_ids;
const potentialFatherKey = "00000000-1111-0000-0000-000000000000";
const potentialMotherKey = "00000000-2222-0000-0000-000000000000";
export const getWidgetOption = getwidgetOption;
export const getLayoutAspect = get_layout_aspects;

const createHeader = () => {
  return {
    "wa-clientId": REACT_APP_CLIENT_ID,
    "wa-requestId": uuidv4(),
    "wa-sessionId": getGoogleSessionId(),
    "Cache-Control": "no-cache"
  };
};

export const getLocationGUIDGetName = (id) => {
  let url = `${PLACEAUTHIRITY}/${id}`,
    staticHeader = createHeader();
  return callApi("GET", url, null, false, staticHeader)
    .then((res) => {
      const data = res.data;
      return data?.fullChainName ? data.fullChainName : "";
    })
    .catch(() => {
      return "";
    });
};

export const getLocationGUID = (id) => {
  let url = `${PLACEAUTHIRITY}/${id}`,
    staticHeader = createHeader();
  return callApi("GET", url, null, false, staticHeader)
    .then((res) => {
      const data = res.data;
      let { options, optionsLevel } = getWwiLevelLocation(data.level, id, data.parent, {}, {});
      options["search.form.dropdown.broad"] = id;
      optionsLevel["4"] = "search.form.dropdown.broad";
      return {
        residenceId: options,
        residenceLevel: optionsLevel
      };
    })
    .catch(() => {
      return {
        residenceId: {},
        residenceLevel: {}
      };
    });
};

export const getResidence = () => {
  return {
    "search.form.dropdown.exactcity": "0",
    "search.form.dropdown.exactcounty": "1",
    "search.form.dropdown.exactstate": "2",
    "search.form.dropdown.exactcountry": "3",
    "search.form.dropdown.broad": "4"
  };
};

const getWwiLevelLocation = (level, id, parentData, options, optionsLevel) => {
  const levelCheck = getLevelCheck();
  const residence = getResidence();
  if (levelCheck[level]) {
    const type = levelCheck[level];
    if (residence[type] && !options[type]) {
      options[type] = id;
      optionsLevel[residence[type]] = type;
    }
  }
  if (parentData) {
    return getWwiLevelLocation(
      parentData.level,
      parentData.placeId,
      parentData.parent,
      options,
      optionsLevel
    );
  }
  return {
    options,
    optionsLevel
  };
};

export const fetchAllUsersTypeahead = async () => {
  const response = await API.Users.usersTypeaheadAll();

  return response?.map((person) => {
    const name = `${person.givenName}${person.surname ? ` ${person.surname}` : ""}`;
    return {
      ...person,
      name
    };
  });
};

// add other types at component level before FF turned on, add here afterwards
export const acceptedApplicationTypes = ["application/pdf"];
export const acceptedImageTypes = ["image/jpg", "image/jpeg", "image/png"];
export const acceptedAudioTypes = [
  "audio/mpeg",
  "audio/mp3",
  "audio/wav",
  "audio/m4a",
  "audio/mp4",
  "audio/x-m4a",
  "audio/mpeg-4"
];
export const acceptedMediaTypesAll = [
  ...acceptedApplicationTypes,
  ...acceptedImageTypes,
  ...acceptedAudioTypes
];
export const imageAndPdfSizeLimit = 1024 * 1024 * 15; // 15Mb
export const audioSizeLimit = 1024 * 1024 * 20; // 50Mb

export const checkUnknown = (obj, type) => {
  if (type === "FAMILY") {
    const blankid = "00000000-0000-0000-0000-000000000000";
    if (obj.id === blankid && obj.gender.Gender === MALE) {
      return `Unknown Father`;
    } else if (obj.id === blankid && obj.gender.Gender === FEMALE) {
      return `Unknown Mother`;
    } else if (obj.id === blankid) {
      return `Unknown Spouse`;
    }
    else if (obj?.gender?.Gender === MALE && obj.id === potentialFatherKey) {
      return 'Potential Father';
    }
    else if (obj?.gender?.Gender === FEMALE && obj.id === potentialMotherKey) {
      return "Potential Mother";
    }
    else {
      return `${obj.firstName.GivenName} ${obj.lastName.Surname}`;
    }
  } else if (type === "SIBLING") {
    return checkSiblings(obj);
  } else if (type === "CHILD") {
    if (obj.nodeGender === MALE) return "Unknown Mother";
    else if (obj.nodeGender === FEMALE) return "Unknown Father";
    else return "Unknown Parent";
  }
};

const checkSiblings = (obj) => {
  const blankid = "00000000-0000-0000-0000-000000000000";
  if (obj.id === blankid && obj.gender === MALE) {
    return `Unknown Father`;
  } else if (obj.id === blankid && obj.gender === FEMALE) {
    return `Unknown Mother`;
  } else if (obj.id === blankid) {
    return `Unknown Parent`;
  } else {
    return `${obj.firstName} ${obj.lastName}`;
  }
};

export const getShortGender = (gender) => {
  switch (gender) {
    case MALE:
      return "M";
    case FEMALE:
      return "F";
    case OTHER:
      return "O";
    case UNKNOWN:
    default:
      return "";
  }
};
export const getLongGender = (gender) => {
  switch (gender) {
    case "M":
      return MALE;
    case "F":
      return FEMALE;
    case "O":
      return OTHER;
    default:
      return UNKNOWN;
  }
};

export const isMale = (gender) => {
  return gender === MALE;
};

export const isFemale = (gender) => {
  return gender === FEMALE;
};

export const isOther = (gender) => {
  return gender === OTHER;
};

export const checkFamilyGroup = (index) => {
  return index % 2 !== 0;
};

export const checkErrorUrl = (url) => {
  if (REACT_APP_ENV === "Test") {
    const apiList = getForceFailureApis();
    const guidPattern =
      /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/g; // Replace the GUIDs with an empty string
    const cleanedURL = url.replace(guidPattern, ""); // Clean up any potential leftover delimiters (like multiple slashes)
    const removedGuidUrl = cleanedURL
      .replace(/\/{2,}/g, "/")
      .replace(/:\//g, "://")
      .replace(/\/\?/g, "?")
      .replace(/\/&/g, "&")
      .replace(/\/$/, "");
    let firstIndex = removedGuidUrl.indexOf("/");
    let beforeFirstSlash = removedGuidUrl.slice(0, Number(firstIndex) + 1);
    let afterFirstSlash = removedGuidUrl.slice(Number(firstIndex) + 1).replace(/\//g, "");
    const completeUrl = beforeFirstSlash + afterFirstSlash;
    const finalUrl = completeUrl.replace(/\?/g, "").trim();
    if (apiList && Object.prototype.hasOwnProperty.call(apiList, finalUrl)) {
      const operatorSign = url.includes("?") ? "&" : "?";
      const forceFailureUrl = url + `${operatorSign}forceFailure=500`;
      return forceFailureUrl;
    }
    return url;
  }
  return url;
};

export const getPathString = (pathString, isParent) => {
  if (pathString === "") return null;
  let elements = pathString && pathString.split("/");
  if (elements) {
    for (let i = 0; i < elements.length; i++) {
      elements[i] = "parents[" + elements[i] + "]";
    }
  }
  if (isParent) {
    elements.pop(elements.length - 1);
  }
  elements = elements.join(".");
  return elements;
};

export const getNewTreeProgressParentPath = (newData, newParent) => {
  switch (true) {
    case newData.gender === "Male" && (newParent.path === "1" || newParent.path === "0"):
      return "0";
    case newData.gender === "Female" && (newParent.path === "1" || newParent.path === "0"):
      return "1";
    case newData.gender === "Male" && (newParent.path === "0/1" || newParent.path === "0/0"):
      return "0/0";
    case newData.gender === "Female" && (newParent.path === "0/1" || newParent.path === "0/0"):
      return "0/1";
    case newData.gender === "Male" && (newParent.path === "1/0" || newParent.path === "1/1"):
      return "1/0";
    case newData.gender === "Female" && (newParent.path === "1/1" || newParent.path === "1/0"):
      return "1/1";
    default:
      return `Other-${newParent.path}`;
  }
};

export const getConnectedNonFamilyValue = () => {
  return {
    pn: "",
    sr: "",
    rn: "",
    nm: "",
    fn: "",
    mn: ""
  };
};
export const getConnectedPetsValue = (firstName, addPetOnwerId) => {
  return {
    firstName: { id: "", name: firstName ?? "" },
    gender: "",
    petType: "",
    ownerId: addPetOnwerId ?? ""
  };
};
export const getNewspapperDefaultValue = () => {
  return {
    k: [{ m: "an", t: "" }],
    fn: "",
    ln: "",
    cu: "",
    st: "",
    ci: "",
    pu: "",
    nm: "",
    bt: { y: "", m: "", d: "", ey: "", em: "", ed: "" },
    ex: { y: "", m: "", d: "" },
    ye: { y: "", eb: "0" },
    be: { y: "" },
    af: { y: "" }
  };
};
export const getEmailDefaultValue = () => {
  return {
    k: [{ rn: "", re: "" }],
    yn: "",
    ye: "",
    msg: ""
  };
};

const getLevelCheck = () => {
  return {
    1: "search.form.dropdown.exactcity",
    2: "search.form.dropdown.exactcity",
    3: "search.form.dropdown.exactcity",
    4: "search.form.dropdown.exactcity",
    5: "search.form.dropdown.exactcounty",
    6: "search.form.dropdown.exactstate",
    7: "search.form.dropdown.exactcountry",
    8: "search.form.dropdown.exactcountry",
    9: "search.form.dropdown.exactcountry",
    10: "search.form.dropdown.exactcountry"
  };
};
export const editPersonData = (data) => {
  return {
    firstName: trimString(data.firstName),
    lastName: trimString(data.lastName),
    isLiving: data.isLiving,
    id: data.id,
    nodeType: data.nodeType,
    gender: data.gender,
    birth: trimString(data.birth),
    birthPlace: trimString(data.birthPlace),
    death: trimString(data.death),
    deathPlace: trimString(data.deathPlace),
    residenceEvents: data?.residenceEvents || [],
    preferredFocus: data.preferredFocus
  };
};

export const newPersonData = (modalAction, data) => {
  switch (modalAction) {
    case modalType.ADD_PARENT:
      return {
        id: data.id,
        treeId: data.treeId,
        filialRelationshipId: data.filialRelationshipId,
        childId: data.childId,
        selectedName: trimString(data.selectedName),
        firstName: trimString(data.firstName),
        lastName: trimString(data.lastName),
        isLiving: data.isLiving,
        gender: data.gender,
        requiredGender: data.requiredGender,
        birth: trimString(data.birth),
        birthPlace: trimString(data.birthPlace),
        death: trimString(data.death),
        birthLocationId: "",
        deathLocationId: "",
        deathPlace: trimString(data.deathPlace),
        siblings: data.siblings,
        relationAdded: data.relationAdded,
        nodeType: data.nodeType,
        homePersonId: data.homePersonId,
        generation: data.generation,
        residenceEvents: []
      };

    case modalType.ADD_SIBLING:
      return {
        id: data.id,
        treeId: data.treeId,
        filialRelationshipId: data.filialRelationshipId,
        selectedName: trimString(data.selectedName),
        gender: data.gender,
        firstName: trimString(data.firstName),
        lastName: trimString(data.lastName),
        isLiving: data.isLiving,
        requiredGender: data.requiredGender,
        birth: trimString(data.birth),
        birthPlace: trimString(data.birthPlace),
        death: trimString(data.death),
        deathPlace: trimString(data.deathPlace),
        pfirstName: trimString(data.pfirstName),
        plastName: trimString(data.plastName),
        primaryPersonId: data.primaryPersonId,
        parents: data.parents,
        relationAdded: data.relationAdded,
        nodeType: data.nodeType,
        homePersonId: data.homePersonId,
        generation: data.generation,
        birthLocationId: "",
        deathLocationId: "",
        residenceEvents: []
      };

    case modalType.ADD_CHILD:
      return {
        id: data.id,
        treeId: data.treeId,
        treePersonId: data.treePersonId,
        filialRelationshipId: data.filialRelationshipId,
        selectedName: trimString(data.selectedName),
        firstName: trimString(data.firstName),
        lastName: trimString(data.lastName),
        isLiving: data.isLiving,
        gender: data.gender,
        nodeGender: data.nodeGender,
        requiredGender: data.requiredGender,
        birth: trimString(data.birth),
        birthPlace: trimString(data.birthPlace),
        death: trimString(data.death),
        deathPlace: trimString(data.deathPlace),
        pfirstName: trimString(data.pfirstName),
        plastName: trimString(data.plastName),
        primaryPersonId: data.primaryPersonId,
        spouses: data.spouses,
        relationAdded: data.relationAdded,
        nodeType: data.nodeType,
        homePersonId: data.homePersonId,
        generation: data.generation,
        birthLocationId: "",
        deathLocationId: "",
        residenceEvents: []
      };

    case modalType.ADD_SPOUSE:
      return {
        id: data.id,
        treeId: data.treeId,
        treePersonId: data.treePersonId,
        filialRelationshipId: data.filialRelationshipId,
        selectedName: trimString(data.selectedName),
        firstName: trimString(data.firstName),
        lastName: trimString(data.lastName),
        isLiving: data.isLiving,
        gender: data.gender,
        requiredGender: data.requiredGender,
        birth: trimString(data.birth),
        birthPlace: trimString(data.birthPlace),
        death: trimString(data.death),
        deathPlace: trimString(data.deathPlace),
        children: data.children,
        relationAdded: data.relationAdded,
        nodeType: data.nodeType,
        spousesLength: data.spousesLength,
        homePersonId: data.homePersonId,
        generation: data.generation,
        directChildren: data.directChildren,
        birthLocationId: "",
        deathLocationId: "",
        residenceEvents: []
      };
    case modalType.ADD_FATHER_OR_MOTHER:
      return {
        id: data.id,
        treeId: data.treeId,
        filialRelationshipId: data.filialRelationshipId,
        childId: data.childId,
        firstName: trimString(data.firstName),
        lastName:
          data.title === "Add Father" ? trimString(data.childSurname) : trimString(data.lastName),
        isLiving: data.isLiving,
        generation: data.generation,
        gender: data.gender,
        birth: "",
        birthPlace: trimString(data.birthPlace),
        death: "",
        deathPlace: trimString(data.deathPlace),
        imgsrc: "",
        birthLocationId: "",
        deathLocationId: "",
        residenceEvents: []
      };
    case modalType.ADD_NONFAMILY:
    case modalType.ADD_INDIVIDUAL:
      return {
        id: data.id,
        firstName: trimString(data.firstName),
        lastName: trimString(data.lastName),
        isLiving: data.isLiving,
        gender: data.gender,
        birth: data.birth,
        birthPlace: trimString(data.birthPlace),
        death: data.death,
        deathPlace: trimString(data.deathPlace),
        imgsrc: "",
        birthLocationId: "",
        deathLocationId: "",
        residenceEvents: []
      };
    default:
      return {
        firstName: trimString(data.firstName),
        lastName: trimString(data.lastName),
        isLiving: true,
        gender: data.gender,
        birth: trimString(data.birth),
        birthPlace: trimString(data.birthPlace),
        death: trimString(data.death),
        deathPlace: trimString(data.deathPlace),
        birthLocationId: "",
        deathLocationId: "",
        residenceEvents: []
      };
  }
};

export const checkValue = (value) => {
  if (value) {
    return value;
  }
  return "";
};

export const getAvatarName = (firstName, lastName, showBoth = false) => {
  if (showBoth && firstName && lastName) {
    const firstNameChar = String(firstName.charAt(0));
    const lastNameChar = String(lastName.charAt(0));
    return firstNameChar.toUpperCase() + lastNameChar.toUpperCase();
  } else if (firstName) {
    const firstChar = String(firstName.charAt(0));
    return firstChar.toUpperCase();
  } else if (lastName) {
    const firstChar = String(lastName.charAt(0));
    return firstChar.toUpperCase();
  } else {
    return "";
  }
};

export const modalType = {
  ADD_CHILD: "ADD_CHILD",
  ADD_PARENT: "ADD_PARENT",
  ADD_SIBLING: "ADD_SIBLING",
  ADD_SPOUSE: "ADD_SPOUSE",
  EDIT_PERSON: "EDIT_PERSON",
  ADD_FATHER_OR_MOTHER: "ADD_FATHER_OR_MOTHER",
  ADD_INDIVIDUAL: "ADD_INDIVIDUAL",
  ADD_NONFAMILY: "ADD_NONFAMILY",
  ADD_RELATIONSHIP: "ADD_RELATIONSHIP",
  ADD_PETS: "ADD_PETS"
};

export const getSelectedGender = (gender) => {
  if (gender === MALE) return FEMALE;
  if (gender === FEMALE) return MALE;
  else return "";
};

export const getRelatedParentIdOrGender = (obj) => {
  for (const [key, value] of Object.entries(obj)) {
    return {
      parentId: key,
      gender: getSelectedGender(value)
    };
  }
};
export const getKeywordsArr = (tr, t) => ({
  wo: tr(t, "Without the Word(s)"),
  an: tr(t, "At Least One of These"),
  ex: tr(t, "With the Exact Phrase"),
  al: tr(t, "With All of the Words")
});

export const encodeDataToURL = (data, prefix = "") => {
  let dataValue = [];
  if (Array.isArray(data)) {
    return data
      .map((value, index) => {
        if (typeof value === "object" || Array.isArray(value)) {
          const indexString = `[${index}]`;
          return encodeDataToURL(value, prefix.replace(/[.]$/, "") + indexString + ".");
        } else {
          return `${prefix.replace(/[.]$/, "")}[${index}]=${encodeURIComponent(value)}`;
        }
      })
      .join("&");
  } else {
    dataValue = Object.keys(data ?? {});
    const prefixString = prefix !== "" ? prefix.replace(/[.]$/, "") + "." : "";
    return dataValue
      .map((value) => {
        let returnString = "";
        if (Array.isArray(data[value]) || typeof data[value] === "object") {
          returnString = `${encodeDataToURL(data[value], prefixString + value)}`;
        } else {
          returnString = `${prefixString + value}=${encodeURIComponent(data[value])}`;
        }
        return returnString;
      })
      .filter(function (el) {
        return !!el;
      })
      .join("&");
  }
};

export const decodeDataToURL = (str) => {
  try {
    if (str[0] === "?") str = str.substring(1);
    let obj = {};
    let arr = str.split(/&+/);
    for (let elem of arr) {
      let arrField = elem.split(/[[\]/]/),
        fieldLength = arrField.length;
      if (fieldLength > 1 && fieldLength % 2) {
        getArrayObj(arrField, obj);
      } else {
        let [key, val] = getKeyValArr(elem),
          keyArr = key.split(".");
        obj = mergeArray(keyArr, obj, val);
      }
    }
    return obj;
  } catch (err) {
    return false;
  }
};
const mergeArray = (keyArr, obj, value, index = 0) => {
  if (index === keyArr.length - 1) {
    obj[keyArr[index]] = decodeURIComponent(value);
    return obj;
  } else {
    if (!obj[keyArr[index]]) {
      obj[keyArr[index]] = {};
    }
    obj[keyArr[index]] = mergeArray(keyArr, obj[keyArr[index]], value, index + 1);
    return obj;
  }
};

const getKeyValArr = (str) => {
  let arr = ["", ""];
  let splitArr = str.split("=");
  if (splitArr[0]) arr[0] = splitArr[0];
  if (splitArr[1]) arr[1] = splitArr[1];
  return arr;
};
const getArrayObj = (arrField, existObj) => {
  let [key, val] = getKeyValArr(arrField[2]),
    keyArr = key.split(".").filter((y) => y),
    builtObj = strToObject(keyArr, val);
  if (arrField[0] in existObj) {
    if (keyArr.length === 1) {
      existObj[arrField[0]][arrField[1]] = {
        ...existObj[arrField[0]][arrField[1]],
        ...builtObj
      };
    } else if (keyArr.length === 2) {
      if (existObj[arrField[0]][arrField[1]]) {
        if (keyArr[0] in existObj[arrField[0]][arrField[1]] || {}) {
          existObj[arrField[0]][arrField[1]][keyArr[0]] = {
            ...existObj[arrField[0]][arrField[1]][keyArr[0]],
            ...builtObj[keyArr[0]]
          };
        }
      } else {
        existObj[arrField[0]][arrField[1]] = {
          ...existObj[arrField[0]][arrField[1]],
          ...builtObj
        };
      }
    }
  } else {
    existObj[arrField[0]] = [{ ...builtObj }];
  }
};
const strToObject = (arr, val) => {
  let object = {},
    result = object;
  for (let i = 0; i < arr.length - 1; i++) {
    object = object[arr[i]] = {};
  }
  object[arr[arr.length - 1]] = decodeURIComponent(val);
  return result;
};

export const getPageSize = (ps) => {
  let size = 20;
  if (isNaN(ps)) {
    return size;
  }
  if (ps <= 10) size = 10;
  if (ps > 20 && ps <= 50) size = 50;
  if (ps > 50 && ps <= 100) size = 100;
  if (ps > 100) size = 100;
  return size;
};
export const formDataTrim = (obj) => {
  for (let elem in obj) {
    if (Array.isArray(obj[elem])) {
      let arr = obj[elem];
      if (!arr.length) {
        delete obj[elem];
      } else {
        for (let arrElem of arr) {
          formDataTrim(arrElem);
        }
      }
    } else {
      _ifObj(obj, elem);
    }
  }
  return obj;
};

const deleteFromObj = (obj, elem, elem1) => {
  if (obj[elem] && !obj[elem][elem1]) {
    delete obj[elem][elem1];
  }
  if (obj[elem]) {
    let keyArr = Object.keys(obj[elem]);
    if (!keyArr.length || (keyArr.length === 1 && keyArr.includes("s"))) {
      delete obj[elem];
    }
  }
};
const _ifObj = (obj, elem) => {
  if (typeof obj[elem] === "object" && obj[elem] !== null) {
    for (let elem1 in obj[elem]) {
      if (obj[elem] && typeof obj[elem][elem1] === "object" && !Array.isArray(obj[elem][elem1])) {
        _ifObj(obj[elem], elem1);
      } else if (obj[elem] && Array.isArray(obj[elem][elem1])) {
        formDataTrim(obj[elem][elem1]);
      }
      deleteFromObj(obj, elem, elem1);
    }
  } else if (typeof obj[elem] === "string" && !obj[elem]) {
    delete obj[elem];
  }
};
function isObject(item) {
  return item && typeof item === "object" && !Array.isArray(item);
}
export function mergeDeep(target, source) {
  let output = Object.assign({}, target);
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!(key in target)) Object.assign(output, { [key]: source[key] });
        else output[key] = mergeDeep(target[key], source[key]);
      } else {
        Object.assign(output, { [key]: source[key] });
      }
    });
  }
  return output;
}

export const dataURLtoFile = (dataurl, filename) => {
  let arr = dataurl.split(","),
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: "image/png" });
};

export const blobToDataUrl = async (imageUrl) => {
  const data = await fetch(imageUrl);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

const getImageSizeCalculate = (imageWidth, imageHeight, mediaWidth, mediaHeight, count = 0) => {
  let width = imageWidth;
  let height = imageHeight;
  if (!(imageWidth <= mediaWidth && imageHeight <= mediaHeight)) {
    if (imageHeight > imageWidth && imageHeight > mediaHeight) {
      height = mediaHeight;
      let widthPer = ((imageHeight - mediaHeight) / imageHeight) * 100;
      let heightPerV = (imageWidth * widthPer) / 100;
      width = Math.round((imageWidth - heightPerV) * 1000) / 1000;
    } else if (imageHeight === imageWidth) {
      width = mediaWidth;
      height = mediaWidth;
    } else if (imageWidth > mediaWidth) {
      width = mediaWidth;
      let widthPer = ((imageWidth - mediaWidth) / imageWidth) * 100;
      let heightPerV = (imageHeight * widthPer) / 100;
      height = Math.round((imageHeight - heightPerV) * 1000) / 1000;
    }
    if (count <= 3 && (height > mediaHeight || width > mediaWidth)) {
      count++;
      const imageSize = getImageSizeCalculate(width, height, mediaWidth, mediaHeight, count);
      width = imageSize.width;
      height = imageSize.height;
    }
  }
  return {
    width,
    height,
    widthActual: mediaWidth,
    heightActual: mediaHeight
  };
};
const fill = {
  Desktop: {
    width: 500,
    height: 700
  },
  iPad: {
    width: "full",
    height: 700
  },
  Mobile: {
    width: "full",
    height: 526
  }
};
const fit = {
  Desktop: {
    width: 500,
    height: 700
  },
  iPad: {
    width: "full",
    height: 700
  },
  Mobile: {
    width: "full",
    height: 525
  }
};
const square = {
  Desktop: {
    width: 400,
    height: 400
  },
  iPad: {
    width: "full-50",
    height: "width"
  },
  Mobile: {
    width: "full",
    height: "width"
  }
};
const border = {
  Desktop: {
    width: 400,
    height: 600
  },
  iPad: {
    width: "full-50",
    height: 600
  },
  Mobile: {
    width: "full",
    height: 449
  }
};
const twoImage = {
  Desktop: {
    width: 400,
    height: 287
  },
  iPad: {
    width: "full-50",
    height: 215
  },
  Mobile: {
    width: 300,
    height: 215
  }
};
const getWidthHeightImage = (widthHeight, _widthHeight) => {
  return widthHeight > _widthHeight ? _widthHeight : widthHeight;
};
const labelArray = {
  [LAYOUT_ID.FIT]: "Fit to page",
  [LAYOUT_ID.FILL]: "Fill",
  [LAYOUT_ID.SQUARE]: "Square",
  [LAYOUT_ID.BORDER]: "Border",
  [LAYOUT_ID.TWO_IMAGE]: "2-image"
};
export const getWidgetLabel = (id) => {
  return labelArray[id];
};
const getIpadImageWidth = (width) => {
  let newWidth = width;
  const divWidth = (getWindowWidth() - 24 * 2) / 2;
  if (newWidth === "full") {
    newWidth = divWidth;
  } else if (newWidth === "full-50") {
    newWidth = divWidth - 80;
  }
  return newWidth;
};
const getIpadImageHeight = (width, height) => {
  let newHeight = height;
  if (newHeight === "width") {
    newHeight = width;
  }
  return newHeight;
};
const getScreenSize = ({ width, height }, screen) => {
  let newWidth = width;
  let newHeight = height;
  if (screen === "Mobile") {
    if (newWidth === "full" && newHeight === "width") {
      newWidth = getWindowWidth();
      newHeight = getWindowWidth();
    } else if (newWidth === "full") {
      newWidth = getWindowWidth();
    }
  } else if (screen === "iPad") {
    newWidth = getIpadImageWidth(newWidth);
    newHeight = getIpadImageHeight(newWidth, newHeight);
  }
  return { width: newWidth, height: newHeight };
};
export const getImageSizeList = (
  imageWidth,
  imageHeight,
  layout,
  mediaObj = { width: 259, height: 186 }
) => {
  //Two Images only
  let { width, height } = mediaObj;
  let { width: _width, height: _height } = { width, height };
  if ([LAYOUT_ID.TWO_IMAGE].includes(layout)) {
    if (imageWidth > width || imageHeight > height) {
      width = getWidthHeightImage(imageWidth, width);
      height = getWidthHeightImage(imageHeight, height);
    } else {
      width = imageWidth;
      height = imageHeight;
    }
    return {
      width: width,
      height: height,
      widthActual: 640,
      heightActual: 250
    };
  } else {
    return getImageSizeCalculate(imageWidth, imageHeight, _width, _height);
  }
};
export const getImageSize = (imageWidth, imageHeight, layout, screen) => {
  let mediaSize = {
    width: imageWidth,
    height: imageHeight
  };
  let { width, height } = getScreenSize(fill[screen], screen);
  if ([LAYOUT_ID.FIT].includes(layout)) {
    let screnRatio = getScreenSize(fit[screen], screen);
    width = getWindowWidth() < screnRatio.width ? getWindowWidth() : screnRatio.width;
    height = screnRatio.height;
    mediaSize = getImageSizeCalculate(imageWidth, imageHeight, width, height);
  } else if ([LAYOUT_ID.SQUARE].includes(layout)) {
    let screnRatio = getScreenSize(square[screen], screen);
    width = screnRatio.width;
    height = screnRatio.height;
    mediaSize = {
      width: width,
      height: height,
      widthActual: width,
      heightActual: height
    };
  } else if ([LAYOUT_ID.BORDER].includes(layout)) {
    let screnRatio = getScreenSize(border[screen], screen);
    width = screnRatio.width;
    height = screnRatio.height;
    mediaSize = getImageSizeCalculate(imageWidth, imageHeight, width, height);
  } else if ([LAYOUT_ID.TWO_IMAGE].includes(layout)) {
    let { width: _width, height: _height } = getScreenSize(twoImage[screen], screen);
    width = imageWidth;
    height = imageHeight;
    if (imageWidth > _width || imageHeight > _height) {
      width = getWidthHeightImage(imageWidth, _width);
      height = getWidthHeightImage(imageHeight, _height);
    }
    mediaSize = {
      width: width,
      height: height,
      widthActual: 400,
      heightActual: 287
    };
  } else if ([LAYOUT_ID.FILL].includes(layout) && (imageWidth > 375 || imageHeight > height)) {
    mediaSize = {
      width: width,
      height: height,
      widthActual: 350,
      heightActual: 490
    };
  }
  return mediaSize;
};

export const getWindowWidth = () => {
  return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
};

export const strFirstUpCase = (str) => (str ? str.charAt(0).toUpperCase() + str.slice(1) : "");

export const getScreen = () => {
  const width = getWindowWidth();
  let screen = "Desktop";
  if (width <= 547) {
    screen = "Mobile";
  }
  return screen;
};
export const getFullWidthHeight = (obj) => {
  if (obj.width) {
    if (obj.width === "full") {
      return {
        width: getWindowWidth() + "px",
        height: obj.height + "px"
      };
    } else {
      return {
        width: obj.width + "px",
        height: obj.height + "px"
      };
    }
  }
};

export const getImageProps = (file, layout) => {
  if (file.mediaObj && file.mediaObj[layout] && file.mediaObj[layout].calculate) {
    const { width, height, widthActual, heightActual } = file.mediaObj[layout].calculate;
    return {
      width: widthActual,
      height: heightActual,
      style: {
        width: width || file.calculate?.width || widthActual,
        height: height || file.calculate?.height || heightActual,
        objectFit: "cover"
      }
    };
  } else {
    return {
      width: file.width,
      height: file.height,
      style: {
        ...(file.calculate ? file.calculate && getFullWidthHeight(file.calculate) : {}),
        objectFit: "cover"
      }
    };
  }
};
export const numToLocaleString = (num) => {
  return num.toLocaleString();
};

export const getWidgetClass = (type) => {
  let str = "";
  switch (type) {
    case LAYOUT_ID.FILL:
      str = "fill";
      break;
    case LAYOUT_ID.BORDER:
      str = "border-view";
      break;
    case LAYOUT_ID.TWO_IMAGE:
      str = "multiple-images";
      break;
    case LAYOUT_ID.SQUARE:
      str = "square";
      break;
    default:
      str = "fit";
  }

  return str;
};
export const IMGAPI_URL = "https://imgapi.storied.com/StoriedThumbnail";
/**
 * @param {string | undefined | null} guid
 */
export const removeAtFromGuid = (guid) => (guid ? guid.split("@")[0] : "");

export const getRelatedValue = (matchExact, value) => {
  if (matchExact) {
    return value;
  }
  return "4";
};

//to search spouse node in family json
const searchSpouses = (arr, nodeId, key) => {
  let obj = arr.filter((el) => el.id === nodeId)[0];
  if (obj) {
    return key;
  }
};

//Find edited node in family json
const findItem = (arr, itemId, nestingKey) =>
  arr.reduce((a, item) => {
    if (a) return a;
    if (item.id === itemId) return nestingKey;
    if (nestingKey) {
      if (item[nestingKey]) return findItem(item[nestingKey], itemId, nestingKey);
    } else {
      return searchSpouses(arr, itemId, "spouses");
    }
    if (item.schildspouse) return searchSpouses(item.schildspouse, itemId, "schildSpouse");
    return "";
  }, null);

//Find edited node in family json
export const findNodeItem = ((arr, itemId, nestingKey) =>
  arr.reduce((a, item) => {
    if (a) return a;
    if (item.id === itemId) return item;
    if (nestingKey) {
      if (item[nestingKey]) return findNodeItem(item[nestingKey], itemId, nestingKey);
    } else {
      return searchSpouses(arr, itemId);
    }
    if (item.schildspouse) return searchSpouses(item.schildspouse, itemId);
    else return null;
  }, null));

export const upDatePotentialFamily = (result, treeData, parentGender) => {
  const potentialTitle = parentGender.toLowerCase() === "male" ? "Potential Father" : "Potential Mother";
  const potentialRelatedId = parentGender.toLowerCase() === "male" ? { "00000000-1111-0000-0000-000000000000": "Male" } : { "00000000-2222-0000-0000-000000000000": "Female" };
  const generation = getGenerationFromPath(result?.attributes?.path);
  if (generation % 4 === 0) {
    result.attributes.relatedParentIds = {
      ...result.attributes.relatedParentIds,
      ...potentialRelatedId
    };
  }
  const updatedParents = result?.parents?.map((item) => {
    if (item.parentId === result.id && item.attributes?.gender === parentGender) {
      item.attributes.title = potentialTitle;
    }
    return item;
  });
  if (updatedParents) result.parents = updatedParents;
  return { ...treeData, result };
};

export const getNodeTypeById = (Id, family) => {
  const treeData = { ...family };
  let nodeType = "";
  if (treeData.id === Id) nodeType = "FOCUS";
  if (nodeType === "") nodeType = findItem(treeData.parents, Id, "parents");
  if (!nodeType) nodeType = findItem(treeData.spouses, Id, "spouses");
  if (!nodeType) nodeType = findItem(treeData.spouses, Id, "schild");
  if (!nodeType) nodeType = findItem(treeData.directChildren, Id, "directChildren");
  if (!nodeType) nodeType = findItem(treeData.siblings, Id, "sibling");
  if (nodeType) {
    return nodeType;
  }
};

export const getGenerationFromPath = (path) => {
  if (path === "") return 0;
  if (path === 0 || path === 1) return 1;
  else return path.split("/").length;
};

const findSpouses = (arr, itemId, nestingKey) =>
  arr.reduce((a, item) => {
    if (a) return a;
    if (item.id === itemId) return arr;
    if (nestingKey) {
      if (item[nestingKey]) return findSpouses(item[nestingKey], itemId, nestingKey);
    }
    return "";
  }, null);

export const getNoOfSpouses = (Id, family, type) => {
  const treeData = { ...family };
  let arrayLength = "";
  if (arrayLength === "" && type === "schild")
    arrayLength = findSpouses(treeData.spouses, Id, "schild");
  else {
    arrayLength = findSpouses(treeData.parents, Id, "parents");
  }
  if (arrayLength) {
    let IdArray = arrayLength.map((ele) => ele.id);
    let IdCount = IdArray.filter((e) => e !== "").length - 1;
    let children = arrayLength.filter((e) => e.id === Id);
    let childSpouse = arrayLength.filter((ele) => ele.id === Id);
    if (type === "parents") return IdCount;
    if (type === "schild") return childSpouse[0].schildspouse.length;
    return children[0].parent;
  }
};

const getDirectChildCheck = (node) => {
  if (node.children && node.children.length > 0 && node.directChildren)
    return node.children.filter((ele) => ele.id === node.directChildren.id)[0].check;
  return null;
};

const getSiblingOrChildForFocus = (node) => {
  if (node && node.existingParentIds) {
    return node.existingParentIds.includes(node.homePersonId);
  }
};

const relationCheckForSchildspouse = (relation) => {
  return relation === "ADD_SIBLING" || relation === "ADD_CHILD" || relation === "ADD_SPOUSE";
};

export const refetchLogic = (node) => {
  let nodeType = node.nodeType;
  let relation = node.relationAdded;
  let spousesLength = node.spousesLength;
  let generation = node.generation;
  const directChildCheck = getDirectChildCheck(node);
  const siblingorChildAddedForFocus = getSiblingOrChildForFocus(node);
  switch (true) {
    case nodeType === "FOCUS" && relation === "ADD_SIBLING":
      return true;
    case nodeType === "parents" &&
      generation === 1 &&
      (relation === "ADD_CHILD" || (relation === "ADD_SPOUSE" && !directChildCheck)):
      return true;
    case nodeType === "parents" &&
      (relation === "ADD_CHILD" ||
        relation === "ADD_SIBLING" ||
        (relation === "ADD_SPOUSE" && !directChildCheck)):
    case nodeType === "schild" &&
      (relation === "ADD_CHILD" ||
        (relation === "ADD_SIBLING" && !siblingorChildAddedForFocus) ||
        (relation === "ADD_SPOUSE" && spousesLength !== 0)):
    case nodeType === "directChildren" && relation === "ADD_CHILD":
    case nodeType === "spouses" &&
      (relation === "ADD_SPOUSE" ||
        relation === "ADD_SIBLING" ||
        (relation === "ADD_CHILD" && !siblingorChildAddedForFocus)):
    case nodeType === "schildSpouse" && relationCheckForSchildspouse(relation):
    case nodeType === "sibling" && (relation === "ADD_SPOUSE" || relation === "ADD_CHILD"):
      return false;
    default:
      return true;
  }
};

export const months = {
  Jan: "01",
  Feb: "02",
  Mar: "03",
  Apr: "04",
  May: "05",
  Jun: "06",
  Jul: "07",
  Aug: "08",
  Sept: "09",
  Oct: "10",
  Nov: "11",
  Dec: "12"
};

const treePersonStoryUrlCond = (refType, treeId, primaryPersonId) => {
  return refType === "1" && treeId && primaryPersonId;
};
const petStoryUrlCond = (refType, petId) => {
  return refType === "7" && petId;
};

export const getLinkStory = ({
  refType,
  treeId,
  primaryPersonId,
  storyId,
  checkComment,
  groupName,
  petId,
  groupId
}) => {
  let url;
  let storyUrl = storyId ? `/${storyId}` : ``;
  let conditionOne = treePersonStoryUrlCond(refType, treeId, primaryPersonId);
  let petcond = petStoryUrlCond(refType, petId);
  if (groupName && groupId) {
    url = `/5${storyUrl}/group/${groupName}`;
  } else if (groupName && !groupId) {
    let commentFeed = checkComment === "checkComment" ? `?comment=true` : ``;
    url = `/feed${storyUrl}/group/${groupName}${commentFeed}`;
  } else if (conditionOne) {
    url = `/1${storyUrl}/${treeId}/${primaryPersonId}`;
  } else if (refType === "1") {
    url = `/1${storyUrl}`;
  } else if (refType === "2") {
    url = `/2${storyUrl}/${treeId}/${primaryPersonId}?tab=0`;
  } else if (treeId && primaryPersonId) {
    url = `/2${storyUrl}/${treeId}/${primaryPersonId}?tab=0`;
  } else if (petcond) {
    url = `/7${storyUrl}?petid=${petId}&tab=0`;
  } else {
    if (checkComment === "checkComment") {
      url = `/0${storyUrl}?comment=true`;
    } else {
      url = `/0${storyUrl}`;
    }
  }
  return url;
};
export const getStoryRedirectUrl = ({
  location,
  recordId,
  refType,
  treeId,
  primaryPersonId,
  groupName
}) => {
  let url = "/";
  if (refType === "1") {
    url = "/stories";
  } else if (refType === "2" || (treeId && primaryPersonId)) {
    url = `/family/person-page/stories/${treeId}/${primaryPersonId}`;
  } else if (refType === "3") {
    url = "/notifications";
  } else if (recordId) {
    const params = new URLSearchParams(location.search);
    const cords = params.get("cords");
    params.delete("cords");
    localStorage.setItem("Cords", cords || "");
    url = `/newspaper-search/newspaper/${recordId}?${params.toString().replaceAll("+", " ")}`;
  } else if (refType === "5" && groupName) {
    url = `/groups/${groupName}/feed`;
  }
  return url;
};

export const getStringLengthWithoutHtmlTags = (str) => {
  const noHtml = str?.replace(/<[^>]*>/g, "");
  return noHtml;
};

export const getLabel = (option) => {
  const name = [];
  if (option.givenName) {
    name.push(option.givenName);
  }
  if (option.surname) {
    name.push(option.surname);
  }
  return name.join(" ");
};

export const getCardNames = (fullName) => {
  const names = fullName.split(" ");
  return {
    firstName: names[0],
    lastName: names[1] || ""
  };
};

export const getDateStrings = (date) => {
  const names = date.split("/");
  return {
    month: names[0],
    year: names[1]
  };
};

export const getDate = (dateObj) => {
  const { day, month, year, isNormalized, isRange, rawDate, fromDate, toDate } = dateObj || {};
  if (isNormalized) {
    const date = `${year}/${month}/${day}`;
    return new Date(date).toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric"
    });
  } else if (isRange) {
    if (!toDate) return getDate(fromDate);
    return `${getDate(fromDate)}-${getDate(toDate)}`;
  } else {
    return rawDate;
  }
};
export const getMaskDate = (dateObj) => {
  const { day, month, year } = dateObj || {};
  const res = [];
  if (day) {
    res.push(day);
  }
  if (month) {
    res.push(month);
  }
  if (year) {
    res.push(year);
  }
  return res.join(",");
};

export const userPayWallDetail = (token) => {
  let cond = "";
  if (token?.extension_EndDate) {
    let expireDate = new Date(token?.extension_EndDate).getTime(),
      today = new Date().getTime();
    if (expireDate > today) {
      cond = {
        planId: token?.extension_PlanId,
        recurlyId: token?.extension_RecurlySubscriptionUuid
      };
    }
  }
  return cond;
};
export const userPayWallDetailCancel = (token) => {
  let cond = "";
  if (token?.extension_EndDate) {
    let expireDate = new Date(token?.extension_EndDate).getTime(),
      today = new Date().getTime();
    if (expireDate > today) {
      cond = {
        planId: token?.extension_PlanId,
        recurlyId: token?.extension_RecurlySubscriptionUuid
      };
    }
  } else if (token?.extension_PlanId) {
    cond = {
      planId: token?.extension_PlanId,
      recurlyId: token?.extension_RecurlySubscriptionUuid
    };
  }
  return cond;
};
export const userPayWallDetailAll = (token) => {
  let cond = "";
  if (token?.extension_EndDate) {
    cond = {
      planId: token?.extension_PlanId,
      recurlyId: token?.extension_RecurlySubscriptionUuid,
      endDate: token?.extension_EndDate,
      startDate: token?.extension_StartDate
    };
  }
  return cond;
};
export const userPayWallVaildation = (token) => {
  let res = "",
    cond = userPayWallDetail(token);
  if (cond) {
    cond = cond.planId;
  }
  res = cond;
  return res;
};

export const capitalFirst = (name) => {
  if (!name) return "";
  return `${name.charAt(0)?.toUpperCase()}${name.slice(1)}`;
};

export const getFieldName = (ipadView, name, modalName) => {
  return ipadView ? modalName : name;
};
export const phoneFormat = (input) => {
  if (typeof input !== "string") input = input.toString();
  if (input.length === 12) {
    let num = input.substring(2, 12);
    return num.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  }
};

export const getCustomImageUrl = (options, imgsrc) => {
  if (/^\/src\/assets/i.test(imgsrc) || imgsrc?.includes("blob") || /\.bmp$/gi.test(imgsrc)) {
    return imgsrc;
  }

  const base64ImagePattern = /image\/.*;base64/;
  if (options && imgsrc && !base64ImagePattern.test(imgsrc)) {
    return `${REACT_APP_URL}/cdn-cgi/image/${options}/${imgsrc}`;
  }

  return imgsrc;
};

export const getTreeNodeTooltipTitle = (type, isOwnerTree, isLiving, firstName, lastName) => {
  if (isOwnerTree || (!isOwnerTree && !isLiving)) {
    const name = `${titleCase(firstName)} ${titleCase(lastName)}`;
    if (
      (type.includes("focus") && name.length >= 29) ||
      (type.includes("focused-living") && name.length >= 19) ||
      (type.includes("focused-deceased") && name.length >= 29) ||
      (type.includes("medium") && name.length > 21) ||
      (type.includes("small") && name.length > 21)
    ) {
      return name;
    }
  } else return "";
};
// bg color
export const BG_GRAY_1 = "bg-gray-1";
export const BG_GRAY_2 = "bg-gray-2";
export const BG_WHITE = "bg-white";

export const newSubscriberCheck = (data) => {
  let cond = "";
  if (data.endDate) {
    let expireDate = new Date(data.endDate).getTime(),
      today = new Date().getTime();
    if (expireDate > today) {
      cond = data.planId;
    }
  }
  return cond;
};

export const newSubscriberData = (data) => {
  let cond = "";
  if (data?.endDate) {
    let expireDate = new Date(data.endDate).getTime(),
      today = new Date().getTime();
    if (expireDate > today) {
      cond = { ...data, recurlyId: data.subscriptionId };
    }
  }
  return cond;
};

const checkGroupInClaims = (groupInfo, info, role) => {
  const filteredArray = groupInfo?.filter((value) => info?.includes(value.groupId));
  return (
    filteredArray &&
    filteredArray[0] &&
    filteredArray[0].role &&
    filteredArray[0].role.toLowerCase() === role
  );
};

export const checkRole = (info, groupInfo, role) => {
  if (info?.ownerId === getOwner()) {
    return true;
  } else if (info?.ownerId !== getOwner()) {
    if (!groupInfo) return false;
    else if (checkGroupInClaims(groupInfo, info?.treeSharedWithGroupIds, role)) return true;
  }
  return false;
};

export const checkGroupMemberRole = (family, groupInfo) => {
  if (groupInfo && groupInfo.length > 0) {
    let result = [];
    family?.treeSharedWithGroupIds?.some((ele) => {
      result = groupInfo?.find((obj) => obj.groupId === ele);
      if (result) return result;
      else return "";
    });
    return result?.role?.toLowerCase();
  }
  return false;
};

export const checkGroupStoryMemberRole = (groupId, groupInfo) => {
  let result = [];
  groupId?.some((ele) => {
    result = groupInfo?.find((obj) => obj.groupId === ele);
    if (result) return result;
    else return "";
  });
  return result?.role?.toLowerCase();
};

export const truncateStr = (source, size) => {
  return source.length > size ? source.slice(0, size - 1) + "..." : source;
};

const getDateMonthForPlanWise = (nextRenewPlan) => {
  return (
    (nextRenewPlan.getMonth() + 1 < 10 ? "0" : "") +
    (nextRenewPlan.getMonth() + 1) +
    "/" +
    (nextRenewPlan.getDate() + 1 < 10 ? "0" : "") +
    nextRenewPlan.getDate() +
    "/" +
    nextRenewPlan.getFullYear().toString().substring(2)
  );
};
export const futureDatesViaXdays = (x) => {
  let future = new Date(); // get today date
  future.setDate(future.getDate() + x); // add 7 days
  let finalDate = getDateMonthForPlanWise(future);
  return finalDate;
};

export const futureDateViaMonths = (timePeriodinMonth) => {
  let currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() + timePeriodinMonth);
  let finalDate = getDateMonthForPlanWise(currentDate);
  return finalDate;
};

export const futureDateByDay = (day) => {
  if (!isNaN(day)) {
    return new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * Number(day));
  } else {
    return new Date();
  }
};
export const shuffleArray = (items) => {
  const typedArray = new Uint8Array(1);
  const randomValue = crypto.getRandomValues(typedArray)[0];
  const randomFloat = randomValue / Math.pow(2, 8);
  for (let i = items.length - 1; i > 0; i--) {
    const j = Math.floor(randomFloat * (i + 1));
    [items[i], items[j]] = [items[j], items[i]];
  }
  return items;
};
export const removeItemFromWidgetSettings = (widgetDisplayOrder, item) => {
  let indexRemove = widgetDisplayOrder.indexOf(item);
  if (indexRemove !== -1) {
    widgetDisplayOrder.splice(indexRemove, 1);
  }
};
export const displaySteps = (steps, total) => {
  if (!getPaymentCookies()) {
    return `Step ${steps} of ${total}`;
  }
  return "";
};

export const removeSelectedStyle = () => {
  let isOnDiv = false;
  const body = document.body;
  const para = document.querySelector(
    "#menu-list-grow, #header-menu-list, #interaction-menu ul, #menu-gender ul"
  );
  const allevents = document.querySelector(".family-event-popover");
  body.onpointermove = () => {
    document
      .querySelector(
        "#menu-list-grow, #header-menu-list, #interaction-menu ul, #menu-gender ul, .family-event-popover"
      )
      ?.addEventListener("pointermove", function () {
        isOnDiv = true;
      });
    document
      .querySelector(
        "#menu-list-grow, #header-menu-list, #interaction-menu ul, #menu-gender ul, .family-event-popover"
      )
      ?.addEventListener("pointerout", function () {
        isOnDiv = false;
      });
    if (isOnDiv === false) {
      para?.focus();
      if (allevents) {
        let ele = document.querySelector(".MuiAutocomplete-option[data-focus='true']");
        ele?.setAttribute("data-focus", "false");
      }
    }
  };
};

export const getCommunicationGraphUrl = (treeId, personId) => {
  let userId = getOwner();
  let authToken = getAccessToken();
  return `${REACT_APP_COMMUNICATION_GRAPH}?userID=${userId}&clientID=550833b9-cc11-edd0-8e24-9f2ffa260df0&personID=${personId}&treeID=${treeId}&authToken=${authToken}&generations=2`;
};

const petIconArray = [
  "Dog",
  "Cat",
  "Bird",
  "Rabbit",
  "Rodent",
  "Horse",
  "Fish",
  "Reptile",
  "Spider"
];
export const petIcons = (x) => {
  if (x?.petType) {
    return !petIconArray.includes(x.petType) ? "iconOtherPet" : "icon" + x.petType;
  }
};
export const petIconCss = (x) => {
  let gender = x?.gender || x?.sex;
  if (gender === "M") {
    return "bg-skyblue-1 text-skyblue-4";
  } else if (gender === "F") {
    return "text-maroon-3 bg-maroon-1";
  } else {
    return "bg-gray-2 text-gray-5";
  }
};
export const petGender = (x) => {
  let gender = x?.gender || x?.sex;
  if (gender === "O") {
    gender = "Other";
  }
  return gender;
};

export const expiryPaywall = () => {
  const subscription = getSubscription();
  if (subscription) {
    const subdata = getSubscriptionDetails();
    if (subdata) {
      return { ...subdata, recurlyId: subdata.subscriptionId };
    }
  } else {
    const decodedToken = decodeJWTtoken();
    const subdata = userPayWallDetailAll(decodedToken);
    if (subdata) {
      return subdata;
    }
  }
  return null;
};

export const getAppendData = (values) => {
  let ob = {};
  switch (values?.nm) {
    case "byyear":
      ob = { ye: values.ye };
      break;
    case "before":
      ob = { be: values.be };
      break;
    case "after":
      ob = { af: values.af };
      break;
    case "between":
      ob = { bt: values.bt };
      break;
    case "exact":
      ob = { ex: values.ex };
      break;
    default:
  }
  const k = values?.k?.filter((_k) => !!_k.m && !!_k.t);
  if (k?.length > 0) {
    ob["k"] = k;
  }
  return ob;
};
