import { UseDataLayer } from "hooks/usedataLayer";
import _ from "lodash";
import { getNameForPurchase } from "redux/helpers/dataLayerHelper";
import { SetCookieSubs, getGoogleSessionId, getOwner, paymentSuccessNewSubs } from "services";
import { futureDateByDay } from "utils";
import { REACT_APP_CLIENT_ID } from "utils/env";
import {
  subscriptionCancelTriggerEmail,
  subscriptionConfirmationTriggerEmail,
  subscriptionUpdateTriggerEmail
} from "utils/salesForceTriggerEmail";
import { v4 as uuidv4 } from "uuid";
import { strFirstUpCase } from "../../utils";
import * as CONSTANTS from "../constants/actionTypes";
import * as API_URLS from "../constants/apiUrl";
import { actionCreator, callApi } from "../utils";
import { claimSponsorshipApi } from "./group";
import { dismissFreeTrialPrompt } from "./homepagePrompts";
import { dataLayerPayment } from "./payments/utils/dataLayerPayment";
import { getDuration } from "./payments/utils/getDuration";
import { addMessage } from "./toastr";

const createHeaderForStoried = () => {
  return {
    "wa-clientId": REACT_APP_CLIENT_ID,
    "wa-requestId": uuidv4(),
    "wa-sessionId": getGoogleSessionId()
  };
};
const createHeader = () => {
  return {
    "X-Api-Key": "C5BFF7F0-B4DF-475E-A331-F737424F013C"
  };
};
const subscriptionInfoFunc = (subData) => {
  let endDate = futureDateByDay(7);
  SetCookieSubs(JSON.stringify({ ...subData, endDate }));
  submitSubscriptionInfo(subData).then((y) => y);
};
export const resetStatus = () => {
  return (dispatch, _getState) => {
    dispatch(actionCreator(CONSTANTS.SUBMITCARDDETAILS.RESET));
  };
};
export const hideBillingToast = () => {
  return (dispatch) => {
    dispatch(actionCreator(CONSTANTS.SUBMITCARDDETAILS.HIDEBILLINGTOAST));
  };
};
export const showBillingToast = () => {
  return (dispatch) => {
    dispatch(actionCreator(CONSTANTS.SUBMITCARDDETAILS.SHOWBILLINGTOAST));
  };
};
export const submitCardDetails = (
  formData,
  setordersummarypage,
  userId,
  { plan, tax, totalAmount },
  groupId,
  groupName,
  planFailedCallback,
  refreshAuth0Token,
  refreshSubscription,
  setSubmitting
) => {
  const requestData = { ...formData };
  delete requestData.shouldDisplayFreeTrialPrompt;
  return async (dispatch, getState) => {
    try {
      let url = API_URLS.SUBMITCARDDETAILS,
        staticHeader = createHeaderForStoried();
      dispatch(actionCreator(CONSTANTS.SUBMITCARDDETAILS.REQUEST));
      setSubmitting(false);
      let res = await callApi("POST", url, requestData, false, staticHeader);
      paymentSuccessNewSubs(true);
      let subData = {
        subscriptionId: res?.data?.recurlySubscriptionUuid,
        planId: formData?.UserInformation?.PlanId,
        startDate: new Date()
      };
      subscriptionInfoFunc(subData);
      if (formData?.UserInformation?.EmailAddress && plan?.[0]) {
        const { basicName } = UseDataLayer();
        let userType = getNameForPurchase(basicName);
        const actionField = {
          id: res?.data?.recurlySubscriptionUuid,
          affiliation: "Storied",
          revenue: totalAmount ?? 0.0,
          tax: tax
        };
        const product = {
          id: plan[0].planid,
          name: plan[0].planName,
          brand: "Storied",
          quantity: 1,
          price: plan[0].amount,
          duration: getDuration(plan[0].prefix),
          userType: userType,
          user_id: getOwner()
        };
        const products = [product];
        dataLayerPayment(actionField, products);
        subscriptionConfirmationTriggerEmail(subData, formData, plan, tax, totalAmount, userId);
      }
      if (formData.shouldDisplayFreeTrialPrompt) {
        dispatch(dismissFreeTrialPrompt());
      }
      dispatch(actionCreator(CONSTANTS.SUBMITCARDDETAILS.SUCCESS, requestData));

      setTimeout(() => refreshAuth0Token(), 10000);
      if (formData.AffiliateCartId) refreshSubscription(formData.AffiliateCartId);
    } catch (err) {
      setordersummarypage(false);
      let errorMsg = _.get(err, "response.data.errorMessage") || "Something went wrong";
      if (err?.response?.data?.chargeStatus === "TRANSACTION-CHARGEFAILED") {
        errorMsg =
          errorMsg + "<br>Please note that we do not accept prepaid cards as a form of payment.";
      }
      dispatch(actionCreator(CONSTANTS.SUBMITCARDDETAILS.FAILURE, err?.response?.data));
      dispatch(addMessage(strFirstUpCase(errorMsg), "error"));
    }
  };
};
const submitSubscriptionInfo = (data) => {
  let url = API_URLS.SubmitSubscriptionInfo,
    getState = () => {
      return {};
    },
    staticHeader = createHeaderForStoried();
  return callApi("POST", url, data, false, staticHeader)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};
export const getTaxApiDetails = (
  taxDetailsformData,
  setformData,
  formData,
  setordersummarypage
) => {
  return (dispatch, getState) => {
    let url = `${API_URLS.TAXAPIDETAILS}?amount=${taxDetailsformData.amount}&zipCode=${taxDetailsformData.zipcode}&country=${taxDetailsformData.country}`,
      staticHeader = createHeaderForStoried();
    dispatch(actionCreator(CONSTANTS.TAXAPIDETAILS.REQUEST));
    return callApi("GET", url, {}, false, staticHeader)
      .then((res) => {
        dispatch(actionCreator(CONSTANTS.TAXAPIDETAILS.SUCCESS, res?.data));
        setformData({ ...formData, ...taxDetailsformData });
        return res?.data;
      })
      .catch((_err) => {
        dispatch(actionCreator(CONSTANTS.TAXAPIDETAILS.SUCCESS, 0));
        setformData({ ...formData, ...taxDetailsformData });
      });
  };
};
export const getInitialTaxApiDetails = (taxDetailsformData, setformData, formData) => {
  return (dispatch) => {
    let url = `${API_URLS.TAXAPIDETAILS}?amount=${taxDetailsformData.amount}&zipCode=${taxDetailsformData.zipcode}&country=${taxDetailsformData.country}`,
      staticHeader = createHeaderForStoried();
    dispatch(actionCreator(CONSTANTS.TAXAPIDETAILS.REQUEST));
    return callApi("GET", url, {}, false, staticHeader)
      .then((res) => {
        dispatch(actionCreator(CONSTANTS.TAXAPIDETAILS.SUCCESS, res?.data));
        dispatch(storeOldTaxData(res?.data));
        console.log(formData, setformData, "dfhgjgh");
        setformData({ ...formData, ...taxDetailsformData });
        return res?.data;
      })
      .catch((_err) => {
        dispatch(actionCreator(CONSTANTS.TAXAPIDETAILS.SUCCESS, 0));
        setformData({ ...formData, ...taxDetailsformData });
      });
  };
};
//for coupon code discount
export const getTaxForCouponCodeApiDetails = (taxDetailsformData, setformData, formData) => {
  return (dispatch, getState) => {
    let url = `${API_URLS.TAXAPIDETAILS}?amount=${taxDetailsformData.taxableAmount}&zipCode=${taxDetailsformData.zipcode}&country=${taxDetailsformData.country}`,
      staticHeader = createHeaderForStoried();
    dispatch(actionCreator(CONSTANTS.TAXAPIDETAILS.REQUEST));
    callApi("GET", url, {}, false, staticHeader)
      .then((res) => {
        dispatch(actionCreator(CONSTANTS.TAXAPIDETAILS.SUCCESS, res?.data));
        setformData({ ...formData, ...taxDetailsformData });
      })
      .catch((_err) => {
        dispatch(actionCreator(CONSTANTS.TAXAPIDETAILS.SUCCESS, 0));
        setformData({ ...formData, ...taxDetailsformData });
      });
  };
};

const storeOldTaxData = (paymentTax) => {
  return (dispatch) => {
    dispatch(actionCreator(CONSTANTS.STOREOLDTAXAPIDETAILS.REQUEST, paymentTax));
  };
};

export const revertOldTaxData = () => {
  return (dispatch) => {
    dispatch(actionCreator(CONSTANTS.REVERTOLDTAXAPIDETAILS.REQUEST));
  };
};

export const resetTaxDetails = () => {
  return (dispatch) => {
    dispatch(actionCreator(CONSTANTS.RESETTAXAPIDETAILS.REQUEST));
  };
};

export const updatePaymentTaxDetails = (
  taxDetailsformData,
  setformData,
  formData,
  setordersummarypage
) => {
  return (dispatch) => {
    return dispatch(
      getTaxApiDetails(taxDetailsformData, setformData, formData, setordersummarypage)
    ).then((tax) => {
      dispatch(storeOldTaxData(tax));
      return tax;
    });
  };
};

export const cancelPayment = (
  values,
  { setMemCancelModal, setMemDropModal, setSubmitting, refreshAuth0Token }
) => {
  const requestData = { ...values };
  delete requestData.PlanDetails;
  delete requestData.UserInformation;
  return (dispatch, getState) => {
    let url = `${API_URLS.cancelSubscription()}`,
      staticHeader = createHeaderForStoried();
    return callApi("POST", url, values, false, staticHeader)
      .then((_res) => {
        if (_res.data.success) {
          subscriptionCancelTriggerEmail(values);
          setSubmitting(false);
          setMemCancelModal(false);
          setMemDropModal(true);
          setTimeout(() => refreshAuth0Token(), 5000);
          return null;
        } else {
          dispatch(addMessage("Something Wrong Happend", "error"));
        }
      })
      .catch((_err) => {
        setSubmitting(false);
        dispatch(addMessage("Something Wrong Happened", "error"));
      });
  };
};

export const getBillingInformation = (recurlyUuid) => {
  return (dispatch, getState) => {
    let url = `${API_URLS.GetBillingInformation(recurlyUuid)}`,
      staticHeader = createHeaderForStoried();
    dispatch(actionCreator(CONSTANTS.BILLINGDETAILS.REQUEST));
    return callApi("GET", url, {}, false, staticHeader)
      .then((res) => {
        if (res?.status === 200) {
          dispatch(actionCreator(CONSTANTS.BILLINGDETAILS.SUCCESS, res?.data));
          return res.data;
        } else {
          dispatch(actionCreator(CONSTANTS.BILLINGDETAILS.FAILURE, null));
        }

        return null;
      })
      .catch((err) => {
        console.log(err);
        dispatch(actionCreator(CONSTANTS.BILLINGDETAILS.FAILURE, null));
        dispatch(addMessage("Card information is not found.", "error"));
      });
  };
};

export const getCalculateRefundAmount = (planID, startDate) => {
  return (dispatch, getState) => {
    let url = `${API_URLS.CalculateRefundAmount(planID, startDate)}`,
      staticHeader = createHeaderForStoried();
    return callApi("GET", url, {}, false, staticHeader)
      .then((res) => {
        if (res?.status === 200) {
          return res.data;
        }
        return null;
      })
      .catch((err) => {
        console.log(err);
        dispatch(addMessage("Card information is not found.", "error"));
      });
  };
};
//to check discount basis coupon code
export const getCouponCodeDiscount = (couponCode, planId) => {
  return (dispatch, getState) => {
    let url = `${API_URLS.checkCouponCodeDiscount(couponCode, planId)}`,
      staticHeader = createHeaderForStoried();
    return callApi("GET", url, {}, false, staticHeader)
      .then((res) => {
        if (res?.status === 200) {
          return res.data;
        }
        return null;
      })
      .catch((err) => {
        console.log(err);
        dispatch(addMessage("Something Wrong Happend", "error"));
      });
  };
};

const getrecurring = (recurring) => {
  if (recurring) {
    recurring = recurring.split("=");
    if (recurring.length === 2 && recurring[0] === "NewRecurlySubscriptionId") {
      recurring = recurring[1];
    } else {
      recurring = false;
    }
  }
  return recurring;
};
export const updateUpgradePlans = (
  formData,
  setordersummarypage,
  { plan, tax, totalAmount },
  groupId,
  groupName,
  planFailedCallback,
  refreshAuth0Token,
  setSubmitting
) => {
  const upgradePlanInfo = formData.UpgradePlanInformation;
  let newPrice = parseInt(formData.UpgradePlanInformation.NewPlanAmount);
  let oldPrice = parseInt(formData.UpgradePlanInformation.OldPrice);
  return async (dispatch, getState) => {
    let url = `${API_URLS.ChangeSubscription}`,
      staticHeader = createHeaderForStoried();
    setSubmitting(false);
    dispatch(actionCreator(CONSTANTS.SUBMITCARDDETAILS.REQUEST));
    const errorMessage = "You already have a subscription to this plan.";
    try {
      const res = await callApi("POST", url, upgradePlanInfo, false, staticHeader);
      if (res?.data?.success) {
        let recurring = res.data.messages?.[0];
        recurring = getrecurring(recurring);
        const { basicName } = UseDataLayer();
        let userTypes = getNameForPurchase(basicName);
        if (recurring) {
          const actionField = {
            id: recurring,
            affiliation: "Storied",
            revenue: totalAmount ?? 0.0,
            tax: tax
          };
          const product = {
            id: plan[0].planid,
            name: plan[0].planName,
            brand: "Storied",
            quantity: 1,
            price: plan[0].amount,
            duration: getDuration(plan[0].prefix),
            userType: userTypes,
            user_id: getOwner()
          };
          const products = [product];
          dataLayerPayment(actionField, products);
          paymentSuccessNewSubs(true);
          let subData = {
            subscriptionId: recurring,
            planId: formData.UpgradePlanInformation.NewPlanId,
            startDate: new Date()
          };
          subscriptionInfoFunc(subData);
          if (formData?.UserInformation?.EmailAddress && plan?.[0] && newPrice > oldPrice) {
            subscriptionUpdateTriggerEmail(formData, tax, totalAmount);
          }
        }
        dispatch(actionCreator(CONSTANTS.SUBMITCARDDETAILS.SUCCESS, upgradePlanInfo));
        groupId && dispatch(claimSponsorshipApi(groupId, groupName));
        setTimeout(() => refreshAuth0Token(), 10000);
      } else {
        setordersummarypage(false);
        dispatch(actionCreator(CONSTANTS.SUBMITCARDDETAILS.FAILURE, res?.errorMessage));
        dispatch(addMessage(strFirstUpCase(res?.data?.errors?.[0] || errorMessage), "error"));
        groupId && planFailedCallback();
      }
    } catch (err) {
      setordersummarypage(false);
      dispatch(actionCreator(CONSTANTS.SUBMITCARDDETAILS.FAILURE, err));
      dispatch(
        addMessage(strFirstUpCase(err?.response?.data?.errors?.[0] || errorMessage), "error")
      );
      groupId && planFailedCallback();
    }
  };
};

export const UpdatePaymentCard = (formData, setSubmitting) => {
  const requestData = { ...formData };
  return (dispatch, getState) => {
    let url = API_URLS.UpdatePaymentCard(),
      staticHeader = createHeaderForStoried();
    return callApi("POST", url, requestData, false, staticHeader)
      .then((res) => {
        setSubmitting(false);
        if (res.data.success) {
          return res.data;
        } else {
          let errorMsg =
            _.get(res, "data.errors[0]") ||
            _.get(res, "data.errorWithField[0]") ||
            "Sorry, your card is not added. Please try again.";
          errorMsg =
            errorMsg + "<br>Please note that we do not accept prepaid cards as a form of payment.";
          dispatch(addMessage(errorMsg, "error"));
          return false;
        }
      })
      .catch((err) => {
        let errorMsg =
          _.get(err, "response.data.errors[0]") ||
          _.get(err, "response.data.errorWithField[0]") ||
          _.get(err, "response.data.errorMessage") ||
          "Sorry, your card is not added. Please try again.";
        errorMsg =
          errorMsg + "<br>Please note that we do not accept prepaid cards as a form of payment.";
        dispatch(addMessage(errorMsg, "error"));
        setSubmitting(false);
      });
  };
};
