/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  HistoricalSearch2Data,
  HistoricalSearch2Payload,
  HistoricalSearchData,
  HistoricalSearchShortenData,
  HistoricalSearchShortenPayload
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class HistoricalSearch<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags HistoricalSearch
   * @name HistoricalSearch
   * @request GET:/api/HistoricalSearch/{hash}
   * @secure
   */
  historicalSearch = (hash: string, params: RequestParams = {}) =>
    this.request<HistoricalSearchData, any>({
      path: `/api/HistoricalSearch/${hash}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags HistoricalSearch
   * @name HistoricalSearch2
   * @request POST:/api/HistoricalSearch
   * @originalName historicalSearch
   * @duplicate
   * @secure
   */
  historicalSearch2 = (data: HistoricalSearch2Payload, params: RequestParams = {}) =>
    this.request<HistoricalSearch2Data, any>({
      path: `/api/HistoricalSearch`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags HistoricalSearch
   * @name HistoricalSearchShorten
   * @request POST:/api/HistoricalSearch/shorten/{hash}
   * @secure
   */
  historicalSearchShorten = (
    hash: string,
    data: HistoricalSearchShortenPayload,
    params: RequestParams = {}
  ) =>
    this.request<HistoricalSearchShortenData, any>({
      path: `/api/HistoricalSearch/shorten/${hash}`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    });
}
