/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AdminMediaCompleteadminreviewData,
  AdminMediaCompleteadminreviewPayload,
  AdminMediaReportedData,
  AdminMediaReportedParams,
  Media2Data,
  MediaAddpersonorpettomediaData,
  MediaAddpersonorpettomediaPayload,
  MediaAddpersonstomediaData,
  MediaAddpersonstomediaPayload,
  MediaAddpettomediaData,
  MediaAddpettomediaPayload,
  MediaAttachPersonAudioData,
  MediaAttachPersonAudioPayload,
  MediaAttachPersonPdfData,
  MediaAttachPersonPdfPayload,
  MediaAttachPetAudioData,
  MediaAttachPetAudioPayload,
  MediaAttachPetPdfData,
  MediaAttachPetPdfPayload,
  MediaAudioData,
  MediaData,
  MediaDetachPersonAudioData,
  MediaDetachPersonAudioPayload,
  MediaDetachPersonPdfData,
  MediaDetachPersonPdfPayload,
  MediaDetachPetAudioData,
  MediaDetachPetAudioPayload,
  MediaDetachPetPdfData,
  MediaDetachPetPdfPayload,
  MediaEditbackgroundimageData,
  MediaEditbackgroundimagePayload,
  MediaEditpetbackgroundimageData,
  MediaEditpetbackgroundimagePayload,
  MediaEditpetprofileimageData,
  MediaEditpetprofileimagePayload,
  MediaEditprofileimageData,
  MediaEditprofileimagePayload,
  MediaEdituserprofileimageData,
  MediaEdituserprofileimagePayload,
  MediaMedialistData,
  MediaMedialistParams,
  MediaNaClippingDetailData,
  MediaNaclipdetailData,
  MediaOriginalImageData,
  MediaOriginalImageParams,
  MediaPdfData,
  MediaRemovepersonsfrommediaData,
  MediaRemovepersonsfrommediaPayload,
  MediaRemovepetsfrommediaData,
  MediaRemovepetsfrommediaPayload,
  MediaReportmediaData,
  MediaReportmediaPayload,
  MediaResizegroupbackgroundimageData,
  MediaResizegroupbackgroundimagePayload,
  MediaUpdatemediametadataData,
  MediaUpdatemediametadataPayload,
  MediaUploadGroupAudioData,
  MediaUploadGroupAudioPayload,
  MediaUploadGroupPdfData,
  MediaUploadGroupPdfPayload,
  MediaUploadPersonAudioData,
  MediaUploadPersonAudioPayload,
  MediaUploadPersonPdfData,
  MediaUploadPersonPdfPayload,
  MediaUploadPetAudioData,
  MediaUploadPetAudioPayload,
  MediaUploadPetPdfData,
  MediaUploadPetPdfPayload,
  MediaUploadUserAudioData,
  MediaUploadUserAudioPayload,
  MediaUploadUserPdfData,
  MediaUploadUserPdfPayload,
  MediaUploadbackgroundimageData,
  MediaUploadbackgroundimagePayload,
  MediaUploadgroupbackgroundimageData,
  MediaUploadgroupbackgroundimagePayload,
  MediaUploadgroupimageData,
  MediaUploadgroupimagePayload,
  MediaUploadpersonmediaData,
  MediaUploadpersonmediaPayload,
  MediaUploadpetbackgroundimageData,
  MediaUploadpetbackgroundimagePayload,
  MediaUploadpetmediaData,
  MediaUploadpetmediaPayload,
  MediaUploadpetprofileimageData,
  MediaUploadpetprofileimagePayload,
  MediaUploadprofileimageData,
  MediaUploadprofileimagePayload,
  MediaUploadstorymediaData,
  MediaUploadstorymediaPayload,
  MediaUploadusermediaData,
  MediaUploadusermediaPayload,
  MediaUploaduserprofileimageData,
  MediaUploaduserprofileimagePayload,
  V2MediaData,
  V2MediaMedialistData,
  V2MediaMedialistParams,
  V2MediaParams,
  V2MediaTranscribedAudioData,
  V2MediaTranscribedAudioParams
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Media<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Media
   * @name MediaUpdatemediametadata
   * @request POST:/api/Media/updatemediametadata
   * @secure
   */
  mediaUpdatemediametadata = (data: MediaUpdatemediametadataPayload, params: RequestParams = {}) =>
    this.request<MediaUpdatemediametadataData, any>({
      path: `/api/Media/updatemediametadata`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name MediaUploadstorymedia
   * @request POST:/api/Media/uploadstorymedia
   * @secure
   */
  mediaUploadstorymedia = (data: MediaUploadstorymediaPayload, params: RequestParams = {}) =>
    this.request<MediaUploadstorymediaData, any>({
      path: `/api/Media/uploadstorymedia`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name MediaUploaduserprofileimage
   * @request POST:/api/Media/uploaduserprofileimage
   * @secure
   */
  mediaUploaduserprofileimage = (
    data: MediaUploaduserprofileimagePayload,
    params: RequestParams = {}
  ) =>
    this.request<MediaUploaduserprofileimageData, any>({
      path: `/api/Media/uploaduserprofileimage`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name MediaUploadUserAudio
   * @request POST:/api/Media/upload-user-audio
   * @secure
   */
  mediaUploadUserAudio = (data: MediaUploadUserAudioPayload, params: RequestParams = {}) =>
    this.request<MediaUploadUserAudioData, any>({
      path: `/api/Media/upload-user-audio`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name MediaUploadUserPdf
   * @request POST:/api/Media/upload-user-pdf
   * @secure
   */
  mediaUploadUserPdf = (data: MediaUploadUserPdfPayload, params: RequestParams = {}) =>
    this.request<MediaUploadUserPdfData, any>({
      path: `/api/Media/upload-user-pdf`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name MediaUploadusermedia
   * @request POST:/api/Media/uploadusermedia
   * @secure
   */
  mediaUploadusermedia = (data: MediaUploadusermediaPayload, params: RequestParams = {}) =>
    this.request<MediaUploadusermediaData, any>({
      path: `/api/Media/uploadusermedia`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name MediaEdituserprofileimage
   * @request POST:/api/Media/edituserprofileimage
   * @secure
   */
  mediaEdituserprofileimage = (
    data: MediaEdituserprofileimagePayload,
    params: RequestParams = {}
  ) =>
    this.request<MediaEdituserprofileimageData, any>({
      path: `/api/Media/edituserprofileimage`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name MediaUploadprofileimage
   * @request POST:/api/Media/uploadprofileimage
   * @secure
   */
  mediaUploadprofileimage = (data: MediaUploadprofileimagePayload, params: RequestParams = {}) =>
    this.request<MediaUploadprofileimageData, any>({
      path: `/api/Media/uploadprofileimage`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name MediaUploadpersonmedia
   * @request POST:/api/Media/uploadpersonmedia
   * @secure
   */
  mediaUploadpersonmedia = (data: MediaUploadpersonmediaPayload, params: RequestParams = {}) =>
    this.request<MediaUploadpersonmediaData, any>({
      path: `/api/Media/uploadpersonmedia`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name MediaUploadPersonAudio
   * @request POST:/api/Media/upload-person-audio
   * @secure
   */
  mediaUploadPersonAudio = (data: MediaUploadPersonAudioPayload, params: RequestParams = {}) =>
    this.request<MediaUploadPersonAudioData, any>({
      path: `/api/Media/upload-person-audio`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name MediaUploadPersonPdf
   * @request POST:/api/Media/upload-person-pdf
   * @secure
   */
  mediaUploadPersonPdf = (data: MediaUploadPersonPdfPayload, params: RequestParams = {}) =>
    this.request<MediaUploadPersonPdfData, any>({
      path: `/api/Media/upload-person-pdf`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name MediaEditbackgroundimage
   * @request POST:/api/Media/editbackgroundimage
   * @secure
   */
  mediaEditbackgroundimage = (data: MediaEditbackgroundimagePayload, params: RequestParams = {}) =>
    this.request<MediaEditbackgroundimageData, any>({
      path: `/api/Media/editbackgroundimage`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name MediaEditprofileimage
   * @request POST:/api/Media/editprofileimage
   * @secure
   */
  mediaEditprofileimage = (data: MediaEditprofileimagePayload, params: RequestParams = {}) =>
    this.request<MediaEditprofileimageData, any>({
      path: `/api/Media/editprofileimage`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name MediaAddpersonstomedia
   * @request POST:/api/Media/addpersonstomedia
   * @secure
   */
  mediaAddpersonstomedia = (data: MediaAddpersonstomediaPayload, params: RequestParams = {}) =>
    this.request<MediaAddpersonstomediaData, any>({
      path: `/api/Media/addpersonstomedia`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name MediaAttachPersonAudio
   * @request POST:/api/Media/attach-person-audio
   * @secure
   */
  mediaAttachPersonAudio = (data: MediaAttachPersonAudioPayload, params: RequestParams = {}) =>
    this.request<MediaAttachPersonAudioData, any>({
      path: `/api/Media/attach-person-audio`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name MediaAttachPersonPdf
   * @request POST:/api/Media/attach-person-pdf
   * @secure
   */
  mediaAttachPersonPdf = (data: MediaAttachPersonPdfPayload, params: RequestParams = {}) =>
    this.request<MediaAttachPersonPdfData, any>({
      path: `/api/Media/attach-person-pdf`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name MediaRemovepersonsfrommedia
   * @request POST:/api/Media/removepersonsfrommedia
   * @secure
   */
  mediaRemovepersonsfrommedia = (
    data: MediaRemovepersonsfrommediaPayload,
    params: RequestParams = {}
  ) =>
    this.request<MediaRemovepersonsfrommediaData, any>({
      path: `/api/Media/removepersonsfrommedia`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name MediaDetachPersonAudio
   * @request POST:/api/Media/detach-person-audio
   * @secure
   */
  mediaDetachPersonAudio = (data: MediaDetachPersonAudioPayload, params: RequestParams = {}) =>
    this.request<MediaDetachPersonAudioData, any>({
      path: `/api/Media/detach-person-audio`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name MediaDetachPersonPdf
   * @request POST:/api/Media/detach-person-pdf
   * @secure
   */
  mediaDetachPersonPdf = (data: MediaDetachPersonPdfPayload, params: RequestParams = {}) =>
    this.request<MediaDetachPersonPdfData, any>({
      path: `/api/Media/detach-person-pdf`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name MediaUploadpetmedia
   * @request POST:/api/Media/uploadpetmedia
   * @secure
   */
  mediaUploadpetmedia = (data: MediaUploadpetmediaPayload, params: RequestParams = {}) =>
    this.request<MediaUploadpetmediaData, any>({
      path: `/api/Media/uploadpetmedia`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name MediaUploadPetAudio
   * @request POST:/api/Media/upload-pet-audio
   * @secure
   */
  mediaUploadPetAudio = (data: MediaUploadPetAudioPayload, params: RequestParams = {}) =>
    this.request<MediaUploadPetAudioData, any>({
      path: `/api/Media/upload-pet-audio`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name MediaUploadPetPdf
   * @request POST:/api/Media/upload-pet-pdf
   * @secure
   */
  mediaUploadPetPdf = (data: MediaUploadPetPdfPayload, params: RequestParams = {}) =>
    this.request<MediaUploadPetPdfData, any>({
      path: `/api/Media/upload-pet-pdf`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name MediaUploadpetprofileimage
   * @request POST:/api/Media/uploadpetprofileimage
   * @secure
   */
  mediaUploadpetprofileimage = (
    data: MediaUploadpetprofileimagePayload,
    params: RequestParams = {}
  ) =>
    this.request<MediaUploadpetprofileimageData, any>({
      path: `/api/Media/uploadpetprofileimage`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name MediaUploadpetbackgroundimage
   * @request POST:/api/Media/uploadpetbackgroundimage
   * @secure
   */
  mediaUploadpetbackgroundimage = (
    data: MediaUploadpetbackgroundimagePayload,
    params: RequestParams = {}
  ) =>
    this.request<MediaUploadpetbackgroundimageData, any>({
      path: `/api/Media/uploadpetbackgroundimage`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name MediaEditpetbackgroundimage
   * @request POST:/api/Media/editpetbackgroundimage
   * @secure
   */
  mediaEditpetbackgroundimage = (
    data: MediaEditpetbackgroundimagePayload,
    params: RequestParams = {}
  ) =>
    this.request<MediaEditpetbackgroundimageData, any>({
      path: `/api/Media/editpetbackgroundimage`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name MediaEditpetprofileimage
   * @request POST:/api/Media/editpetprofileimage
   * @secure
   */
  mediaEditpetprofileimage = (data: MediaEditpetprofileimagePayload, params: RequestParams = {}) =>
    this.request<MediaEditpetprofileimageData, any>({
      path: `/api/Media/editpetprofileimage`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name MediaAddpettomedia
   * @request POST:/api/Media/addpettomedia
   * @secure
   */
  mediaAddpettomedia = (data: MediaAddpettomediaPayload, params: RequestParams = {}) =>
    this.request<MediaAddpettomediaData, any>({
      path: `/api/Media/addpettomedia`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name MediaAttachPetAudio
   * @request POST:/api/Media/attach-pet-audio
   * @secure
   */
  mediaAttachPetAudio = (data: MediaAttachPetAudioPayload, params: RequestParams = {}) =>
    this.request<MediaAttachPetAudioData, any>({
      path: `/api/Media/attach-pet-audio`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name MediaAttachPetPdf
   * @request POST:/api/Media/attach-pet-pdf
   * @secure
   */
  mediaAttachPetPdf = (data: MediaAttachPetPdfPayload, params: RequestParams = {}) =>
    this.request<MediaAttachPetPdfData, any>({
      path: `/api/Media/attach-pet-pdf`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name MediaRemovepetsfrommedia
   * @request POST:/api/Media/removepetsfrommedia
   * @secure
   */
  mediaRemovepetsfrommedia = (data: MediaRemovepetsfrommediaPayload, params: RequestParams = {}) =>
    this.request<MediaRemovepetsfrommediaData, any>({
      path: `/api/Media/removepetsfrommedia`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name MediaDetachPetAudio
   * @request POST:/api/Media/detach-pet-audio
   * @secure
   */
  mediaDetachPetAudio = (data: MediaDetachPetAudioPayload, params: RequestParams = {}) =>
    this.request<MediaDetachPetAudioData, any>({
      path: `/api/Media/detach-pet-audio`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name MediaDetachPetPdf
   * @request POST:/api/Media/detach-pet-pdf
   * @secure
   */
  mediaDetachPetPdf = (data: MediaDetachPetPdfPayload, params: RequestParams = {}) =>
    this.request<MediaDetachPetPdfData, any>({
      path: `/api/Media/detach-pet-pdf`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name MediaUploadbackgroundimage
   * @request POST:/api/Media/uploadbackgroundimage
   * @secure
   */
  mediaUploadbackgroundimage = (
    data: MediaUploadbackgroundimagePayload,
    params: RequestParams = {}
  ) =>
    this.request<MediaUploadbackgroundimageData, any>({
      path: `/api/Media/uploadbackgroundimage`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name MediaUploadgroupbackgroundimage
   * @request POST:/api/Media/uploadgroupbackgroundimage
   * @secure
   */
  mediaUploadgroupbackgroundimage = (
    data: MediaUploadgroupbackgroundimagePayload,
    params: RequestParams = {}
  ) =>
    this.request<MediaUploadgroupbackgroundimageData, any>({
      path: `/api/Media/uploadgroupbackgroundimage`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name MediaUploadgroupimage
   * @request POST:/api/Media/uploadgroupimage
   * @secure
   */
  mediaUploadgroupimage = (data: MediaUploadgroupimagePayload, params: RequestParams = {}) =>
    this.request<MediaUploadgroupimageData, any>({
      path: `/api/Media/uploadgroupimage`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name MediaUploadGroupAudio
   * @request POST:/api/Media/upload-group-audio
   * @secure
   */
  mediaUploadGroupAudio = (data: MediaUploadGroupAudioPayload, params: RequestParams = {}) =>
    this.request<MediaUploadGroupAudioData, any>({
      path: `/api/Media/upload-group-audio`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name MediaUploadGroupPdf
   * @request POST:/api/Media/upload-group-pdf
   * @secure
   */
  mediaUploadGroupPdf = (data: MediaUploadGroupPdfPayload, params: RequestParams = {}) =>
    this.request<MediaUploadGroupPdfData, any>({
      path: `/api/Media/upload-group-pdf`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name MediaResizegroupbackgroundimage
   * @request POST:/api/Media/resizegroupbackgroundimage
   * @secure
   */
  mediaResizegroupbackgroundimage = (
    data: MediaResizegroupbackgroundimagePayload,
    params: RequestParams = {}
  ) =>
    this.request<MediaResizegroupbackgroundimageData, any>({
      path: `/api/Media/resizegroupbackgroundimage`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name Media
   * @request DELETE:/api/Media/{mediaId}
   * @secure
   */
  media = (mediaId: string, params: RequestParams = {}) =>
    this.request<MediaData, any>({
      path: `/api/Media/${mediaId}`,
      method: "DELETE",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name Media2
   * @request GET:/api/Media/{mediaId}
   * @originalName media
   * @duplicate
   * @secure
   */
  media2 = (mediaId: string, params: RequestParams = {}) =>
    this.request<Media2Data, any>({
      path: `/api/Media/${mediaId}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name MediaAudio
   * @request DELETE:/api/Media/audio/{mediaId}
   * @secure
   */
  mediaAudio = (mediaId: string, params: RequestParams = {}) =>
    this.request<MediaAudioData, any>({
      path: `/api/Media/audio/${mediaId}`,
      method: "DELETE",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name MediaPdf
   * @request DELETE:/api/Media/pdf/{mediaId}
   * @secure
   */
  mediaPdf = (mediaId: string, params: RequestParams = {}) =>
    this.request<MediaPdfData, any>({
      path: `/api/Media/pdf/${mediaId}`,
      method: "DELETE",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name MediaReportmedia
   * @request POST:/api/Media/reportmedia
   * @secure
   */
  mediaReportmedia = (data: MediaReportmediaPayload, params: RequestParams = {}) =>
    this.request<MediaReportmediaData, any>({
      path: `/api/Media/reportmedia`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name MediaAddpersonorpettomedia
   * @request POST:/api/Media/addpersonorpettomedia
   * @secure
   */
  mediaAddpersonorpettomedia = (
    data: MediaAddpersonorpettomediaPayload,
    params: RequestParams = {}
  ) =>
    this.request<MediaAddpersonorpettomediaData, any>({
      path: `/api/Media/addpersonorpettomedia`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name AdminMediaCompleteadminreview
   * @request PUT:/api/admin/Media/completeadminreview
   * @secure
   */
  adminMediaCompleteadminreview = (
    data: AdminMediaCompleteadminreviewPayload,
    params: RequestParams = {}
  ) =>
    this.request<AdminMediaCompleteadminreviewData, any>({
      path: `/api/admin/Media/completeadminreview`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name MediaOriginalImage
   * @request GET:/api/Media/{profileImageId}/OriginalImage
   * @secure
   */
  mediaOriginalImage = (
    { profileImageId, ...query }: MediaOriginalImageParams,
    params: RequestParams = {}
  ) =>
    this.request<MediaOriginalImageData, any>({
      path: `/api/Media/${profileImageId}/OriginalImage`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name MediaNaClippingDetail
   * @request GET:/api/Media/naClippingDetail/{externalImageId}
   * @secure
   */
  mediaNaClippingDetail = (externalImageId: string, params: RequestParams = {}) =>
    this.request<MediaNaClippingDetailData, any>({
      path: `/api/Media/naClippingDetail/${externalImageId}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name MediaNaclipdetail
   * @request GET:/api/Media/naclipdetail/{clipId}
   * @secure
   */
  mediaNaclipdetail = (clipId: number, params: RequestParams = {}) =>
    this.request<MediaNaclipdetailData, any>({
      path: `/api/Media/naclipdetail/${clipId}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name MediaMedialist
   * @request GET:/api/Media/medialist
   * @secure
   */
  mediaMedialist = (query: MediaMedialistParams, params: RequestParams = {}) =>
    this.request<MediaMedialistData, any>({
      path: `/api/Media/medialist`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name V2Media
   * @request GET:/api/v2/Media
   * @secure
   */
  v2Media = (query: V2MediaParams, params: RequestParams = {}) =>
    this.request<V2MediaData, any>({
      path: `/api/v2/Media`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name V2MediaMedialist
   * @request GET:/api/v2/Media/medialist
   * @secure
   */
  v2MediaMedialist = (query: V2MediaMedialistParams, params: RequestParams = {}) =>
    this.request<V2MediaMedialistData, any>({
      path: `/api/v2/Media/medialist`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name V2MediaTranscribedAudio
   * @request GET:/api/v2/Media/transcribed-audio
   * @secure
   */
  v2MediaTranscribedAudio = (query: V2MediaTranscribedAudioParams, params: RequestParams = {}) =>
    this.request<V2MediaTranscribedAudioData, any>({
      path: `/api/v2/Media/transcribed-audio`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Media
   * @name AdminMediaReported
   * @request GET:/api/admin/Media/reported/{pageNumber}/{pageSize}
   * @secure
   */
  adminMediaReported = (
    { pageNumber, pageSize, ...query }: AdminMediaReportedParams,
    params: RequestParams = {}
  ) =>
    this.request<AdminMediaReportedData, any>({
      path: `/api/admin/Media/reported/${pageNumber}/${pageSize}`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    });
}
