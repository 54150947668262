import {
  GET_USER,
  USER_ERROR,
  SHOW_PROFILE,
  PROFILE_LOADING,
  SAVE_PROFILE,
  GET_USER_PROFILE_IMAGE,
  CLEAR_PROFILE_IMAGE,
  PROFILE_CHANGED,
  SET_PROFILE_IMAGE,
  CLEAR_EDIT_IMAGE,
  SHOW_EDIT_IMAGE,
  PROFILE_IMAGE_UPLOADED,
  CLEAR_REFETCH_INFO,
  ADD_PROFILE_THUMBNAIL,
  ACCESS_CODE_VALIDATING,
  ACCESS_CODE_INVALID,
  ACCESS_CODE_VALID,
  CLEAR_ACCESS,
  UPDATE_USER,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_EMAIL_ERROR,
  UPDATE_ORIGINAL_EMAIL,
  PROFILE_IMAGE_DELETED,
  APP_ERROR_STATE,
  CLEAR_APP_ERROR_STATE,
  SET_MILO_DETAILS,
  GET_ALL_TREES,
  UPDATE_COOKIE_GROUP_INFO,
  UPDATE_USER_PREFERENCES,
  UPDATE_PROMPTS_GROUP,
  UPDATE_PROMPTS_TELL_STORY,
  UPDATE_PROMPTS_SEARCH,
  UPDATE_PROMPTS_FEATURE_STORY,
  UPDATE_PROMPTS_FREE_TRIAL,
  UPDATE_INFO_MODAL_TOOLTIP,
  UPDATE_FIRST_HINT_SEEN,
  REDIRECT_TO_REGISTER,
  UPDATE_USER_BITHDATE,
  UPDATE_PROMPT_RECIPE,
  UPDATE_TOOLTIP_RECIPE,
} from "../constants";

const initialState = {
  loading: true,
  error: null,
  user: null,
  userName: null,
  userFirstName: null,
  userLastName: null,
  userEmail: null,
  userEmailError: null,
  userId: null,
  imgSrc: null,
  showProfileModalModal: false,
  profileLoading: false,
  originalProfileImage: null,
  originalImageId: null,
  profileChanged: false,
  showEditImageCropper: false,
  originalUserImage: null,
  imageUploaded: false,
  accessCodeValidating: false,
  accessCodeInvalid: false,
  redirectToLogin: false,
  appError: false,
  allUserTrees: null,
  communicationPreferences: null,
  groupInfo: [],
  authenticationProvider: null,
  registrationCheckComplete: false,
  originalUserEmail: null,
  firstHintSeen: true,
  userProfileAccount: null,
};

const user = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case GET_USER:
      return {
        ...state,
        userProfileAccount: payload,
        userAccount: payload,
        userId: payload.id,
        userFirstName: payload.firstName,
        userLastName: payload.lastName,
        userEmail: payload.email,
        originalUserEmail: payload.email,
        userBirthDate: payload.birthDate,
        userBirthPlace: payload.birthPlace,
        gender: payload.gender,
        imgSrc: payload.imgSrc,
        isExternalImage: payload.isExternalImage,
        profileImageId: payload.profileImageId,
        mobileNumber: payload.mobileNumber,
        optInStatus: payload.optInStatus,
        frequency: payload.frequency,
        userLoading: false,
        signInType: payload.signInType,
        groupInfo: payload.groupInfo,
        shouldDisplayFollowFeaturedStoriesPrompt: payload.shouldDisplayFollowFeaturedStoriesPrompt,
        shouldDisplayStoryPrompt: payload.shouldDisplayStoryPrompt,
        shouldDisplayRecipePrompt: payload.shouldDisplayRecipePrompt,
        shouldDisplayRecipeToolTipPrompt: payload.shouldDisplayRecipeToolTipPrompt,
        shouldDisplayGroupPrompt: payload.shouldDisplayGroupPrompt,
        shouldDisplaySearchPrompt: payload.shouldDisplaySearchPrompt,
        shouldDisplayFreeTrialPrompt: payload.shouldDisplayFreeTrialPrompt,
        widgetDisplayOrder: payload.widgetDisplayOrder,
        shouldDisplayQuickViewModalToolTip: payload.shouldDisplayQuickViewModalToolTip,
        firstHintSeen: payload.firstHintSeen,
      };
    case UPDATE_USER:
      return {
        ...state,
        userFirstName: payload.userFirstName,
        userLastName: payload.userLastName,
      };
    case UPDATE_COOKIE_GROUP_INFO:
      return {
        ...state,
        groupInfo: payload,
      };
    case UPDATE_EMAIL_SUCCESS:
      return {
        ...state,
        userEmail: payload.email,
        userEmailError: false,
      };
    case UPDATE_EMAIL_ERROR:
      return {
        ...state,
        userEmailError: true,
        userEmail: state.originalUserEmail,
      };
    case UPDATE_ORIGINAL_EMAIL:
      return {
        ...state,
        originalUserEmail: payload.email,
      };
    case PROFILE_IMAGE_UPLOADED:
      return {
        ...state,
        originalProfileImage: null,
        originalImageId: null,
      };
    case CLEAR_REFETCH_INFO:
      return {
        ...state,
        imageUploaded: false,
      };
    case USER_ERROR:
      return {
        ...state,
        error: payload,
        userLoading: false,
        profileLoading: false,
        showProfileModal: false,
      };
    case SHOW_PROFILE:
      return {
        ...state,
        showProfileModal: true,
        profileLoading: false,
      };
    case PROFILE_LOADING:
      return {
        ...state,
        profileLoading: true,
      };
    case ADD_PROFILE_THUMBNAIL:
      return {
        ...state,
        imgSrc: payload.accountThumbnail,
        showEditImageCropper: false,
      };
    case PROFILE_IMAGE_DELETED:
      return {
        ...state,
        imgSrc: "",
      };
    case SAVE_PROFILE:
      return {
        ...state,
        profileLoading: false,
        showProfileModal: false,
        profileChanged: false,
      };
    case GET_USER_PROFILE_IMAGE:
      return {
        ...state,
        originalProfileImage: payload.originalImagePath,
        originalImageId: payload.originalImageId,
        originalUserImage: payload,
      };
    case CLEAR_PROFILE_IMAGE:
      return {
        ...state,
        originalProfileImage: null,
        showProfileModal: false,
        originalImageId: null,
      };
    case PROFILE_CHANGED:
      return {
        ...state,
        profileChanged: true,
      };

    case SET_PROFILE_IMAGE:
      return {
        ...state,
        imgSrc: payload.profileImageUrl,
        profileImageId: payload.profileImageId,
      };
    case CLEAR_EDIT_IMAGE:
      return {
        ...state,
        showEditImageCropper: false,
        originalProfileImage: "",
      };
    case SHOW_EDIT_IMAGE:
      return {
        ...state,
        showEditImageCropper: true,
      };
    case ACCESS_CODE_VALIDATING:
      return {
        ...state,
        accessCodeValidating: true,
        accessCodeInvalid: false,
      };
    case ACCESS_CODE_INVALID:
      return {
        ...state,
        accessCodeValidating: false,
        accessCodeInvalid: true,
      };
    case ACCESS_CODE_VALID:
      return {
        ...state,
        accessCodeValidating: false,
        accessCodeInvalid: false,
        redirectToLogin: true,
      };
    case SET_MILO_DETAILS:
      return {
        ...state,
        mobileNumber: payload.mobileNumber,
        optInStatus: payload.optInStatus,
        frequency: payload.frequency,
      };
    case CLEAR_ACCESS:
      return {
        redirectToLogin: false,
      };
    case APP_ERROR_STATE:
      return {
        ...state,
        appError: payload,
      };
    case CLEAR_APP_ERROR_STATE:
      return {
        ...state,
        appError: false,
      };
    case GET_ALL_TREES:
      return {
        ...state,
        allUserTrees: payload.data,
        ownTrees: payload.ownUserTrees,
      };
    case UPDATE_USER_PREFERENCES:
      return {
        ...state,
        communicationPreferences: payload,
      };
    case UPDATE_PROMPTS_TELL_STORY:
      return {
        ...state,
        shouldDisplayStoryPrompt: payload,
      };
    case UPDATE_PROMPTS_GROUP:
      return {
        ...state,
        shouldDisplayGroupPrompt: payload,
      };
    case UPDATE_PROMPTS_SEARCH:
      return {
        ...state,
        shouldDisplaySearchPrompt: payload,
      };
    case UPDATE_PROMPTS_FEATURE_STORY:
      return {
        ...state,
        shouldDisplayFollowFeaturedStoriesPrompt: payload,
      };
    case UPDATE_PROMPTS_FREE_TRIAL:
      return {
        ...state,
        shouldDisplayFreeTrialPrompt: payload,
      };
    case UPDATE_INFO_MODAL_TOOLTIP:
      return {
        ...state,
        shouldDisplayQuickViewModalToolTip: false,
      };
    case REDIRECT_TO_REGISTER:
      return {
        ...state,
        registrationCheckComplete: true,
      };
    case UPDATE_FIRST_HINT_SEEN:
      return {
        ...state,
        firstHintSeen: true,
      };
    case UPDATE_PROMPT_RECIPE:
      return {
        ...state,
        shouldDisplayRecipePrompt: payload,
      };
    case UPDATE_TOOLTIP_RECIPE:
      return {
        ...state,
        shouldDisplayRecipeToolTipPrompt: payload,
      };
    case UPDATE_USER_BITHDATE:
      return {
        ...state,
        userBirthDate: payload,
      };
    default:
      return state;
  }
};

export default user;
