import {
  GET,
  POST,
  GET_FS_USER,
  GET_IMPORTED_TREE,
  FAMILY_SEARCH_IMPORT_PROGRESS_COMPLETE,
  FAMILY_SEARCH_MEMORIES_IMPORT_COMPLETE,
  CLEAR_MEMORIES_SNACKBAR,
} from "redux/constants";
import { v4 as uuid } from "uuid";
import { apiRequest } from "redux/requests";
import { addMessage } from "./toastr";
import {
  setNewFamilySearchTreeId, setFamilySearchhomePersonId, setFamilySearchRequestId,
  getFamilySearchRequestId, getFamilySearchHomePersonId, getFamilySearchTreeId
} from "services";
import { apiFamilySearchApiRequest } from "redux/requests/familysearch";

export const getFSUserDetails = () => async (dispatch) => {
  try {
    const res = await apiFamilySearchApiRequest(GET, `https://api.familysearch.org/platform/tree/current-person`);
    if (res) {
      dispatch({
        type: GET_FS_USER,
        payload: res.data
      })
    }

  } catch (err) {
    dispatch({
      type: GET_FS_USER,
      payload: { msg: err }
    })
  }
}

export const importFamilySearchTree = (externalId, treeName, isShared, fsAccesstoken) => async (dispatch) => {
  try {
    const newTreeId = uuid();
    const homePersonId = uuid();
    const fsRequestId = uuid();
    setNewFamilySearchTreeId(newTreeId);
    setFamilySearchhomePersonId(homePersonId);
    setFamilySearchRequestId(fsRequestId);
    const familySearchHeaders = {
      "wa-requestId": fsRequestId,
    }
    const res = await apiRequest(POST, `Trees/${externalId}/${treeName}/${isShared}/${fsAccesstoken}/${newTreeId}/${homePersonId}/importTreeFromFamilySearch`, undefined, undefined, undefined, familySearchHeaders);
    if (res.status === 200) {
      dispatch({
        type: GET_IMPORTED_TREE,
        payload: true
      })
    }
  }
  catch (err) {
    console.log({ err })
  }
}

export const refreshFsDetails = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_IMPORTED_TREE,
      payload: null
    })
    dispatch({
      type: GET_FS_USER,
      payload: null
    })
    dispatch({
      type: FAMILY_SEARCH_IMPORT_PROGRESS_COMPLETE,
      payload: null
    })
  }
  catch (err) {
    console.log({ err })
  }
}

export const updateImportTreeProgress = (treeId, id) => async (dispatch) => {
  try {
    const payload = {
      id: id,
      treeId: treeId
    }
    dispatch({
      type: FAMILY_SEARCH_IMPORT_PROGRESS_COMPLETE,
      payload: payload
    })
  }
  catch (err) {
    console.log({ err })
  }
}

export const clearMemoriesSnackbar = () => async (dispatch) => {
  try {
    dispatch({
      type: CLEAR_MEMORIES_SNACKBAR,
    });
  } catch (err) {
    console.log({ err });
  }
};

export const displayMemoriesSuccessSnackbar = (treeId, id) => async (dispatch) => {
  try {
    if (window.location.href.includes("pedigree-view")) {
      dispatch(addMessage("Your FamilySearch Memories have been added!", "success"));
    } else if (!window.location.href.includes("pedigree-view")) {
      const url = `/family/pedigree-view/${treeId}/${id}/4`;
      dispatch(addMessage("Your FamilySearch Memories have been added!", "notification", { url }));
    }
    dispatch({
      type: CLEAR_MEMORIES_SNACKBAR,
    });
  } catch (err) {
    console.log({ err });
  }
};

export const displayFSTreeSuccessSnackbar = (treeId, id) => async (dispatch) => {
  try {
    const url = `/family/pedigree-view/${treeId}/${id}/4`;
    dispatch(addMessage("Your FamilySearch Tree has been imported!", "notification", { url }));
    dispatch({
      type: FAMILY_SEARCH_IMPORT_PROGRESS_COMPLETE,
      payload: null,
    });
  } catch (err) {
    console.log({ err });
  }
};

export const getFamilySearchTreeStatus = () => async (dispatch) => {
  try {
    const requestId = getFamilySearchRequestId();
    const res = await apiRequest(GET, `Trees/${requestId}/familysearchtreeimportstatus`);
    const payload = {
      id: getFamilySearchHomePersonId(),
      treeId: getFamilySearchTreeId()
    }
    if (res.data === true) {
      dispatch({
        type: FAMILY_SEARCH_IMPORT_PROGRESS_COMPLETE,
        payload: payload
      })
      dispatch({
        type: GET_IMPORTED_TREE,
        payload: false
      })
    }
  } catch (err) {
    console.log({ err })
  }
}

export const getFSMemoriesImportStatus = () => async (dispatch) => {
  try {
    const requestId = getFamilySearchRequestId();
    const res = await apiRequest(GET, `Trees/${requestId}/familysearchmemoriesimportstatus`);
    const payload = {
      id: getFamilySearchHomePersonId(),
      treeId: getFamilySearchTreeId()
    }
    if (res.data === true) {
      dispatch({
        type: FAMILY_SEARCH_MEMORIES_IMPORT_COMPLETE,
        payload: payload
      })
    }
  } catch (err) {
    console.log({ err })
  }
}
