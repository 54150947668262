import Private from "pages/MainEntry/Private";
import RedirectToLogin from "pages/RedirectToLogin";
import ShareStoryRoutes from "pages/Routes/ShareStoryRoutes";
import ShareStory from "pages/ShareStory";
import ShareableStory from "pages/ShareStory/ShareableStory/ShareableStory";
import StorySharePreview from "pages/ShareStory/ShareableStory/StorySharePreview";
import { lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

const AccessPage = lazy(() => import("pages/AccessPage"));
const Registration = lazy(() => import("pages/Registration"));
const Accounts = lazy(() => import("pages/Accounts"));
const AccountLinking = lazy(() => import("pages/AccountLinking"));
const StoryPreview = lazy(() => import("pages/ShareStory/StoryPreview"));

const Test = ({ appErrorState, isAccessed, isAccessToken, allRoutes, isLoading, handleLogout }) => {
  const storyViewUrl = "view";
  const storyV1Url = "v1/view";
  const previewStoryRoute = !isAccessToken && (
    <ShareStoryRoutes exact path="/story-preview/:storyId" component={StoryPreview} />
  );
  const shareableStoryRoute = !isAccessToken && (
    <ShareStoryRoutes exact path="/story-shared/:storyId" component={StorySharePreview} />
  );
  const shareStoryRoute = !isAccessToken && (
    <ShareStoryRoutes
      exact
      path={`/stories/${storyViewUrl}/:refType/:storyId`}
      component={StorySharePreview}
    />
  );
  const shareGroupStoryRoute = !isAccessToken && (
    <ShareStoryRoutes
      exact
      path={`/stories/${storyViewUrl}/:refType/:storyId/group/:groupName`}
      component={StorySharePreview}
    />
  );
  const sharePersonStoryRoute = !isAccessToken && (
    <ShareStoryRoutes
      exact
      path={`/stories/${storyViewUrl}/:refType/:storyId/:treeId/:primaryPersonId`}
      component={StorySharePreview}
    />
  );
  const accessRoute = !isAccessed && <Route exact path="/" component={AccessPage} />;
  const publicRoutes = isAccessed && !isAccessToken && allRoutes;
  const privateRoutes = isAccessed && isAccessToken && (
    <Route exact path="/" render={(props) => <Private appErrorState={appErrorState} />} />
  );

  return (
    <Switch>
      {!isAccessToken && (
        <Redirect
          from={`/stories/${storyV1Url}/:refType/:storyId`}
          to={`/stories/${storyViewUrl}/:refType/:storyId`}
        />
      )}
      {!isAccessToken && (
        <Redirect
          from={`/stories/${storyV1Url}/:refType/:storyId/group/:groupName`}
          to={`/stories/${storyViewUrl}/:refType/:storyId/group/:groupName`}
        />
      )}
      {!isAccessToken && (
        <Redirect
          from={`/stories/${storyV1Url}/:refType/:storyId/:treeId/:primaryPersonId`}
          to={`/stories/${storyViewUrl}/:refType/:storyId/:treeId/:primaryPersonId`}
        />
      )}
      {!isAccessToken && <Route exact path="/login" component={RedirectToLogin} />}
      {isAccessed && isAccessToken && <Route exact path="/accounts" component={Accounts} />}
      {isAccessed && isAccessToken && <Route exact path="/registration" component={Registration} />}
      {isAccessed && isAccessToken && (
        <Route exact path="/registration/:steps" component={Registration} />
      )}
      {isAccessed && isAccessToken && (
        <Route exact path="/account-linking" component={AccountLinking} />
      )}
      <ShareStoryRoutes exact path="/invitation/:messageID" component={ShareStory} />
      <ShareStoryRoutes exact path="/sharestorylink/:shareableID" component={ShareableStory} />
      {previewStoryRoute}
      {shareableStoryRoute}
      {shareStoryRoute}
      {shareGroupStoryRoute}
      {sharePersonStoryRoute}
      {accessRoute}
      {publicRoutes}
      {privateRoutes}
      <Route render={(props) => <Private appErrorState={appErrorState} />} />
    </Switch>
  );
};

export default Test;
