import { useAuth0 } from "@auth0/auth0-react";
import jwt_decode from "jwt-decode";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getPendingInvites } from "redux/actions/group";
import { getTreesList } from "redux/actions/homepage";
import { getAllUserTreesList, getUserGroups } from "redux/actions/leftSideNav";
import { getUserCouponStatus } from "redux/actions/printBook/order";
import { clearAccess, createStoriedUser, getBlobApiList, getUserAccount } from "redux/actions/user";
import { addAxiosErrorInterceptor } from "redux/requests";

//Services
import {
  getAccessCode,
  getAccessToken,
  getUserCheck,
  removeCookies,
  setAccessToken,
  setOwner,
  setRedirectToAccountLinking,
  setRedirectToRegistration
} from "services";

const useInit = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently, isLoading: auth0Loading, isAuthenticated } = useAuth0();

  const { redirectToLogin, accessCodeInvalid } = useSelector((state) => state.user);
  const { showBillingToast } = useSelector((state) => state.payment);
  const [isAccessed, setIsAccessed] = useState(getAccessCode());
  const [isAccessToken, setIsAccessToken] = useState(getAccessToken());
  const [isLoading, setLoading] = useState(auth0Loading);
  const [cookieAccessToken, setCookieAccessToken] = useState(getAccessToken());

  useEffect(() => {
    addAxiosErrorInterceptor(getAccessTokenSilently, dispatch);
  }, [getAccessTokenSilently, dispatch]);

  const callRequiredApis = useCallback(
    async ({ ownerId, tokenClaims, groupTokenClaims }) => {
      await dispatch(createStoriedUser(ownerId, tokenClaims)); // creating user in storied DB
      dispatch(getBlobApiList());
      dispatch(getUserAccount(ownerId, groupTokenClaims)); // getting user information
      dispatch(getAllUserTreesList(ownerId)); // getting 4 trees record for user
      dispatch(getPendingInvites()); // getting all pending group invites
      dispatch(getTreesList(0, "GROUP")); // getting trees for sidebar
      dispatch(getUserGroups()); // getting all groups related to user
      dispatch(getUserCouponStatus()); // getting user coupon status
    },
    [dispatch]
  );

  //getAuth0Token() -. get token from auth0
  const getAuth0Token = useCallback(async () => {
    setLoading(true);
    if (isAuthenticated) {
      const isDuplicate = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/isDuplicate";
      const locationName = window.location.pathname.split("/");
      let tokenClaims, groupTokenClaims;
      const response = getAccessToken();
      let userCheck = getUserCheck();
      if (response) {
        const decoded = jwt_decode(response);
        tokenClaims = decoded.login_count === 1 ? "new" : "existing";
        groupTokenClaims = decoded.extension_GroupInfo;
        const ownerId = decoded.app_metadata_UserId;
        setOwner(ownerId);
        const isDuplicateAccount = decoded[isDuplicate];
        if (isDuplicateAccount && locationName[1] !== "accounts") {
          setRedirectToAccountLinking(true);
          setIsAccessToken(true);
        }
        if (
          tokenClaims === "new" &&
          userCheck === null &&
          (locationName[1] === "" || locationName[1] === "home")
        ) {
          setRedirectToRegistration(true);
          setIsAccessToken(true);
          setLoading(false);
        }
        callRequiredApis({ ownerId, tokenClaims, groupTokenClaims });
        setIsAccessToken(true);
        setLoading(false);
      }
    }
  }, [isAuthenticated, callRequiredApis]);

  //Silent Authentication SSO
  const checkCurrentSession = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getAccessTokenSilently();
      if (response) {
        setAccessToken(response);
        setCookieAccessToken(response);
      } else {
        setLoading(false);
        setIsAccessToken(null);
        removeCookies();
      }
    } catch (err) {
      setLoading(false);
      setIsAccessToken(null);
      removeCookies();
    }
  }, []);

  useEffect(() => {
    checkCurrentSession();
  }, []);

  // after access code redirection
  useEffect(() => {
    if (redirectToLogin) {
      setIsAccessed(true);
      dispatch(clearAccess());
    }
  }, [dispatch, accessCodeInvalid, redirectToLogin]);

  //Receiving token from Auth0
  useEffect(() => {
    if (isAuthenticated && !auth0Loading && cookieAccessToken) {
      getAuth0Token();
    }
  }, [getAuth0Token, isAuthenticated, auth0Loading, cookieAccessToken]);

  return useMemo(
    () => ({
      showBillingToast,
      isAccessed,
      isAccessToken,
      isLoading,
      setLoading
    }),
    [isAccessToken, isAccessed, isLoading, showBillingToast]
  );
};

export default useInit;
