import {
    GET,
    USER_ERROR,
    GET_ALL_TREES,
    SET_ALL_USER_TREES,
    GET_ALL_GROUPS,
    LEAVE_GROUP,
    CLEAR_GEDCOM,
    SET_HOMEPERSON_MODAL
} from "../constants";
import { apiRequest } from "../requests";

export const getAllUserTreesList = (id) => async (dispatch) => {
    try {
        let requestData = { authorId: id };
        const response = await apiRequest(GET, `Users/trees?numberOfTrees=5`, requestData);
        const ownTrees = response?.data?.filter((tree) => tree.ownerId === id);
        response.ownUserTrees = ownTrees;


        dispatch({
            type: GET_ALL_TREES,
            payload: response
        })
    } catch (err) {
        dispatch({
            type: USER_ERROR,
            payload: { msg: err }
        })
    }
};

export const addToRecentTrees = (trees, treeId, startNewTree) => async (dispatch) => {
    let foundTree = trees.filter(ele => ele.treeId === treeId);
    if (foundTree && foundTree.length) {
      let remainingTrees = trees.filter(ele => ele.treeId !== treeId);
      let firstPositionedTree = [...foundTree, ...remainingTrees];
      dispatch({
        type: GET_ALL_TREES,
        payload: firstPositionedTree
      })
    }
    else {
      let newTree = [{
        treeId: startNewTree?.treeId,
        homePersonId: startNewTree?.primaryPersonId,
        treeName: startNewTree?.treeName
      }]
      let newAddedTrees = [...newTree, ...trees];
      dispatch({
        type: GET_ALL_TREES,
        payload: newAddedTrees
      })
      dispatch({
        type: CLEAR_GEDCOM
    })
    }
  }

  export const showSetHomePersonModal = (person) => async (dispatch) => {
    dispatch({
      type: SET_HOMEPERSON_MODAL,
      payload: person,
    });
  };

  export const getUserGroups = () => async (dispatch) => {
    try {
      const response = await apiRequest(GET, `Users/groups`);

      dispatch({
          type: GET_ALL_GROUPS,
          payload: response.data
      })
  } catch (err) {
      dispatch({
          type: USER_ERROR,
          payload: { msg: err }
      })
  }
  };

  export const leaveUserGroup = (payload) => async (dispatch) => {
    dispatch({
      type: LEAVE_GROUP,
      payload: payload,
    });
  };

  export const setAllUserTreesList = (payload) => async (dispatch) => {
    try {
      dispatch({
        type: SET_ALL_USER_TREES,
        payload: payload
      });
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: { msg: err }
      });
    }
  };