import { registrationsDataLayer } from "redux/helpers/dataLayerHelper";
import {
  getGroupInfoCookie,
  getOwner,
  getUserCheck,
  getUserCreated,
  getUserFlow,
  loadGroupPage,
  loadRegisterType,
  setAccessCode,
  setAccountsMerged,
  setForceFailureApis,
  setUserCheck,
  setUserCreated
} from "services";
import { checkValue } from "../../utils";
import {
  ACCESS_CODE_INVALID,
  ACCESS_CODE_VALID,
  ACCESS_CODE_VALIDATING,
  ADD_PROFILE_THUMBNAIL,
  CLEAR_ACCESS,
  CLEAR_APP_ERROR_STATE,
  CLEAR_EDIT_IMAGE,
  DELETE,
  GET,
  GET_USER,
  GET_USER_PROFILE_IMAGE,
  POST,
  PROFILE_CHANGED,
  PROFILE_IMAGE_DELETED,
  PROFILE_IMAGE_UPLOADED,
  PROFILE_LOADING,
  SAVE_PROFILE,
  SET_MILO_DETAILS,
  SET_PROFILE_IMAGE,
  SHOW_EDIT_IMAGE,
  UPDATE_EMAIL_ERROR,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_INFO_MODAL_TOOLTIP,
  UPDATE_ORIGINAL_EMAIL,
  UPDATE_PROMPT_RECIPE,
  UPDATE_TOOLTIP_RECIPE,
  UPDATE_USER,
  UPDATE_USER_BITHDATE,
  UPDATE_USER_PREFERENCES,
  USER_ERROR
} from "../constants";
import { getReducedGroupInfo } from "../helpers/index";
import { apiRequest } from "../requests";
import { apiSalesForceRequest, apiToGetPreferences } from "../requests/salesforce";
import {
  getAccountActivationPayload,
  getAccountCreationPayload,
  getAddUserDemographicsPayload,
  getAddUserPreferencesPayload,
  getUpdatedPreferenceOnly,
  userImageUploadPayload
} from "./../helpers";
import { addMessage } from "./toastr";
import { dataLayerregistration } from "./utils/datalayer";

export const getUserAccount = (userId, groupInfo) => async (dispatch) => {
  const cookieGroupInfo = getGroupInfoCookie();
  try {
    const adb2cInfo = await apiRequest(GET, `Users/${userId}/authproviderinfo`);
    const res = await apiRequest(GET, `Users/${userId}/info`);
    let finalGroupPayload =
      (groupInfo || cookieGroupInfo) && getReducedGroupInfo(groupInfo, cookieGroupInfo);
    let displayOrderElements = ["Story", "Group", "Search"];
    if (res.data.widgetSettings.widgetDisplayOrder.length > 0) {
      displayOrderElements = res.data.widgetSettings.widgetDisplayOrder;
    }
    let payload = {
      id: adb2cInfo.data.userId,
      firstName: checkValue(adb2cInfo.data.givenName),
      lastName: checkValue(adb2cInfo.data.surname),
      email: adb2cInfo.data.mail,
      birthPlace: checkValue(adb2cInfo.data.city),
      birthDate: "",
      gender: "",
      profileImageId: res.data.profileImageId,
      imgSrc: res.data.profileImageUrl,
      isExternalImage: res.data.isExternalImage,
      birthLocationId: "",
      mobileNumber: adb2cInfo.data?.mobileNumber,
      optInStatus: res.data?.optInStatus === "OptedIn" ? true : false,
      frequency: res.data?.frequency,
      signInType: adb2cInfo.data.signInType,
      groupInfo: finalGroupPayload || [],
      shouldDisplayFollowFeaturedStoriesPrompt:
        res.data.widgetSettings.shouldDisplayFollowFeaturedStoriesPrompt,
      shouldDisplayRecipePrompt: res.data.widgetSettings.shouldDisplayRecipePrompt,
      shouldDisplayRecipeToolTipPrompt: res.data.widgetSettings.shouldDisplayRecipeToolTipPrompt,
      shouldDisplayStoryPrompt: res.data.widgetSettings.shouldDisplayStoryPrompt,
      shouldDisplayGroupPrompt: res.data.widgetSettings.shouldDisplayGroupPrompt,
      shouldDisplaySearchPrompt: res.data.widgetSettings.shouldDisplaySearchPrompt,
      shouldDisplayFreeTrialPrompt: res.data.widgetSettings.shouldDisplayFreeTrialPrompt,
      widgetDisplayOrder: displayOrderElements,
      shouldDisplayQuickViewModalToolTip:
        res.data.widgetSettings.shouldDisplayQuickViewModalToolTip,
      firstHintSeen: res.data.userCompletedActions.firstHintSeen
    };

    dispatch({
      type: GET_USER,
      payload: payload
    });
    dispatch({ type: SAVE_PROFILE });
  } catch (err) {
    dispatch({
      type: USER_ERROR,
      payload: { msg: err }
    });
  }
};

export const updateUserBirthYear = (birthYear) => (dispatch) => {
  try {
    dispatch({ type: UPDATE_USER_BITHDATE, payload: birthYear });
  } catch (err) {
    dispatch({
      type: USER_ERROR,
      payload: { msg: err }
    });
  }
};

const addDataLayer = (userId) => {
  const regType = loadRegisterType();
  const userFlow = getUserFlow();
  const userCreated = getUserCreated();
  const isGroupPage = loadGroupPage();

  if (userFlow === "signInUp" && JSON.parse(userCreated)) {
    dataLayerregistration(userId, "newspaper archive");
  } else if (isGroupPage) {
    dataLayerregistration(userId, "group creation");
  } else {
    dataLayerregistration(userId, JSON.parse(regType));
  }
};

export const createStoriedUser = (userId, tokenClaims) => async (dispatch) => {
  try {
    let userCheck = getUserCheck();
    if (userCheck === null) {
      setUserCheck(true);
      const userResponse = await apiRequest(POST, `User/createstorieduser`);
      if (userResponse?.status === 202) {
        await apiRequest(POST, `User/migrateuser`);
      }
      const adb2cInfo = await apiRequest(GET, `Users/${userId}/authproviderinfo`);
      if (userResponse?.status === 200) {
        setUserCreated(true);
        addDataLayer(userId);
        //Trigger email notification of account creation for new user
        if (tokenClaims === "new") {
          const accountCreationPayload = getAccountCreationPayload(userId, adb2cInfo.data);
          await apiSalesForceRequest(
            POST,
            `definitionKey=st-reg-confirmation&websiteId=4670&emailId=${adb2cInfo.data.mail}`,
            accountCreationPayload
          );
          registrationsDataLayer(userId);
        } else {
          //Trigger email notification of account activation for existing/migrated NA user
          const accountActivationPayload = getAccountActivationPayload(userId, adb2cInfo.data);
          await apiSalesForceRequest(
            POST,
            `definitionKey=st-account-activation-confirmation&websiteId=4670&emailId=${adb2cInfo.data.mail}`,
            accountActivationPayload
          );
        }
        const addUserDemographicsPayload = getAddUserDemographicsPayload(userId, adb2cInfo.data);
        const addUserPreferencesPayload = getAddUserPreferencesPayload(userId);
        await apiSalesForceRequest(
          POST,
          `definitionKey=sde_all_demographics&websiteId=4670&emailId=${adb2cInfo.data.mail}`,
          addUserDemographicsPayload
        );
        await apiSalesForceRequest(
          POST,
          `definitionKey=sde_st_user_preferences&websiteId=4670&emailId=${adb2cInfo.data.mail}`,
          addUserPreferencesPayload
        );
      }
    }
  } catch (err) {
    dispatch({
      type: USER_ERROR,
      payload: { msg: err }
    });
  }
};

export const getPreferenceDetails = () => async (dispatch) => {
  let userId = getOwner();
  try {
    let res = await apiToGetPreferences(
      GET,
      `filter=azureid eq "${userId}"&definitionKey=sde_st_user_preferences`
    );

    dispatch({
      type: UPDATE_USER_PREFERENCES,
      payload: res.data.items[0].values
    });
  } catch (err) {
    dispatch({
      type: USER_ERROR,
      payload: { msg: err }
    });
  }
};
export const updateCommunicationDetails =
  (userId, userEmail, oldPreference, newPreference) => async (dispatch) => {
    try {
      const preference = getUpdatedPreferenceOnly(oldPreference, newPreference);
      let payload = [
        {
          keys: {
            AzureID: userId
          },
          values: preference
        }
      ];
      await apiSalesForceRequest(
        POST,
        `definitionKey=sde_st_user_preferences&websiteId=4670&emailId=${userEmail}`,
        payload
      );
      dispatch({
        type: UPDATE_USER_PREFERENCES,
        payload: newPreference
      });
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: { msg: err }
      });
    }
  };

export const profileChange = () => (dispatch) => {
  try {
    dispatch({ type: PROFILE_CHANGED });
  } catch (err) {
    dispatch({
      type: USER_ERROR,
      payload: { msg: err }
    });
  }
};

export const saveUserProfileImage = (imagePayload) => async (dispatch) => {
  try {
    dispatch({ type: PROFILE_LOADING });
    dispatch({
      type: ADD_PROFILE_THUMBNAIL,
      payload: imagePayload
    });
    const payload = userImageUploadPayload(imagePayload);
    await apiRequest(POST, `Media/uploaduserprofileimage`, payload);
    dispatch({ type: SAVE_PROFILE });
    dispatch({ type: PROFILE_IMAGE_UPLOADED });
  } catch (err) {
    dispatch({
      type: USER_ERROR,
      payload: { msg: err }
    });
  }
};

export const editUserProfileImage = (imagePayload) => async (dispatch) => {
  try {
    dispatch({ type: PROFILE_LOADING });
    dispatch({
      type: ADD_PROFILE_THUMBNAIL,
      payload: imagePayload
    });
    const payload = userImageUploadPayload(imagePayload);
    await apiRequest(POST, `Media/edituserprofileimage`, payload);
    dispatch({ type: PROFILE_IMAGE_UPLOADED });
  } catch (err) {
    dispatch({
      type: USER_ERROR,
      payload: { msg: err }
    });
  }
};

export const deleteUserProfileImage = (userId) => async (dispatch) => {
  try {
    dispatch({ type: PROFILE_LOADING });
    dispatch({ type: PROFILE_IMAGE_DELETED });
    await apiRequest(DELETE, `User/deleteuserprofileimage/${userId}`);
  } catch (err) {
    dispatch({
      type: USER_ERROR,
      payload: { msg: err }
    });
  }
};

export const getUserProfileImage =
  (profileImageId, queryParam = "") =>
  async (dispatch) => {
    try {
      const res = await apiRequest(GET, `Media/${profileImageId}/OriginalImage${queryParam}`);

      dispatch({
        type: GET_USER_PROFILE_IMAGE,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: { msg: err }
      });
    }
  };

export const updateUser = (payload) => async (dispatch) => {
  try {
    let updateUserPayload = {
      userFirstName: payload.givenName,
      userLastName: payload.surname
    };
    dispatch({ type: PROFILE_LOADING });
    dispatch({ type: UPDATE_USER, payload: updateUserPayload });
    await apiRequest(POST, `User/updateuser`, payload);
  } catch (err) {
    dispatch({
      type: USER_ERROR,
      payload: { msg: err }
    });
  }
};

export const updateEmail = (userId, email) => async (dispatch) => {
  try {
    let payload = { userId, email };
    dispatch({ type: UPDATE_EMAIL_SUCCESS, payload });
    await apiRequest(POST, `User/updateuseremail`, payload);
    dispatch({ type: UPDATE_ORIGINAL_EMAIL, payload });
  } catch (err) {
    dispatch(addMessage(err.response.data, "error"));
    dispatch({
      type: UPDATE_EMAIL_ERROR,
      payload: { msg: err }
    });
  }
};

export const setProfileImage = (userId) => async (dispatch) => {
  try {
    const res = await apiRequest(GET, `Users/${userId}/info`);

    dispatch({
      type: SET_PROFILE_IMAGE,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: USER_ERROR,
      payload: { msg: err }
    });
  }
};

export const clearEditImage = () => (dispatch) => {
  try {
    dispatch({ type: CLEAR_EDIT_IMAGE });
  } catch (err) {
    dispatch({
      type: USER_ERROR,
      payload: { msg: err }
    });
  }
};

export const showEditImage = () => (dispatch) => {
  try {
    dispatch({ type: SHOW_EDIT_IMAGE });
  } catch (err) {
    dispatch({
      type: USER_ERROR,
      payload: { msg: err }
    });
  }
};

export const accessCodeValidation = (accessCode) => async (dispatch) => {
  try {
    let payload = {
      accessCode
    };

    dispatch({
      type: ACCESS_CODE_VALIDATING
    });
    const response = await apiRequest(POST, `user/verifyaccess`, payload);
    if (response.data === "Failure") {
      dispatch({
        type: ACCESS_CODE_INVALID
      });
    } else {
      setAccessCode(accessCode);
      dispatch({
        type: ACCESS_CODE_VALID
      });
    }
  } catch (err) {
    dispatch({
      type: USER_ERROR,
      payload: { msg: err }
    });
  }
};

export const clearAccess = () => (dispatch) => {
  try {
    dispatch({
      type: CLEAR_ACCESS
    });
  } catch (err) {
    dispatch({
      type: USER_ERROR,
      payload: { msg: err }
    });
  }
};
export const addRecentViewPeople =
  ({ primaryPersonId, treeId }) =>
  async () => {
    await apiRequest(
      POST,
      `user/addrecentlyviewedpeople/${treeId}/${primaryPersonId}`,
      {},
      undefined,
      undefined,
      undefined,
      false
    );
  };

export const clearAppErrorState = () => (dispatch) => {
  try {
    dispatch({
      type: CLEAR_APP_ERROR_STATE
    });
  } catch (err) {
    dispatch({
      type: USER_ERROR,
      payload: { msg: err }
    });
  }
};

export const setMiloDetails = (payload) => (dispatch) => {
  dispatch({ type: SET_MILO_DETAILS, payload });
};

export const dismissquickviewmodaltooltip = () => (dispatch) => {
  try {
    apiRequest(POST, `User/dismissquickviewmodaltooltip`);
    dispatch({
      type: UPDATE_INFO_MODAL_TOOLTIP
    });
  } catch (err) {
    dispatch({
      type: USER_ERROR,
      payload: { msg: err }
    });
  }
};

export const updateRegistration = (formData, step) => (dispatch) => {
  try {
    const registrationPayload = {
      birthYear: formData.birthYear,
      familyResearchExperience: formData.familyResearchExperience,
      AgeGroup: ""
    };
    const response = apiRequest(
      POST,
      `User/userRegistrationQuestionnaireAnswers`,
      registrationPayload
    );
    if (step === "goals" && response) {
      dispatch(
        addMessage("Thanks! We’ll use these responses to customize your experience.", "success")
      );
    }
  } catch (err) {
    dispatch({
      type: USER_ERROR,
      payload: { msg: err }
    });
  }
};

export const showPreferencesSavedMessage = () => (dispatch) => {
  try {
    dispatch(
      addMessage("Thanks! We’ll use these responses to customize your experience.", "success")
    );
  } catch (err) {
    dispatch({
      type: USER_ERROR,
      payload: { msg: err }
    });
  }
};

export const updateRegistrationSeen = () => (dispatch) => {
  try {
    apiRequest(POST, `User/userRegistrationQuestionnaireSeen`);
  } catch (err) {
    dispatch({
      type: USER_ERROR,
      payload: { msg: err }
    });
  }
};

export const getPrimaryAccount = (email) => {
  let url = `Users/${email}/getprimaryidentityprovider`;
  return apiRequest("GET", url)
    .then((res) => {
      return res.data || [];
    })
    .catch((_err) => {
      return [];
    });
};

export const mergeLinkedAccounts = (email) => {
  let url = `User/${email}/linkaccountsbyemail`;
  return apiRequest("POST", url)
    .then((res) => {
      setAccountsMerged(true);
      return res.data || null;
    })
    .catch((_err) => {
      return "Error";
    });
};

export const resetUserAccountDetails = (userDetails) => (dispatch) => {
  try {
    dispatch({
      type: GET_USER,
      payload: userDetails
    });
  } catch (err) {
    dispatch({
      type: USER_ERROR,
      payload: { msg: err }
    });
  }
};

export const dismissRecipePrompt = () => async (dispatch) => {
  try {
    await apiRequest(POST, `User/dismissRecipePrompt`);
    dispatch({ type: UPDATE_PROMPT_RECIPE, payload: false });
  } catch (err) {
    dispatch({
      type: USER_ERROR,
      payload: { msg: err }
    });
  }
};

export const dismissRecipeToolTipPrompt = () => async (dispatch) => {
  try {
    await apiRequest(POST, `User/dismissRecipePrompt`);
    await apiRequest(POST, `User/dismissRecipeToolTipPrompt`);
    dispatch({ type: UPDATE_PROMPT_RECIPE, payload: false });
    dispatch({ type: UPDATE_TOOLTIP_RECIPE, payload: false });
  } catch (err) {
    dispatch({
      type: USER_ERROR,
      payload: { msg: err }
    });
  }
};

export const getBlobApiList = () => async (dispatch) => {
  try {
    await fetch(
      `https://storiedteststorage.blob.core.windows.net/wastoriedgedcom/forcedFailureApis.json`
    )
      .then((response) => response.json())
      .then((data) => {
        setForceFailureApis(data);
      });
  } catch (err) {
    dispatch({
      type: USER_ERROR,
      payload: { msg: err }
    });
  }
};
