import { EXTERNAL_IMAGE } from "pages/Media/helpers";
import { trimString } from "shared-logics";
import { v4 as uuidv4 } from "uuid";
import { titleCase } from "../../components/utils/titlecase";
import { getOwner } from "../../services";
import { checkUnknown } from "../../utils";

const BlankId = "00000000-0000-0000-0000-000000000000";

export const addFilialFunction = (child, parent, treeId) => {
  if (child.attributes.cFilialId === "" || child.attributes.cFilialId === "cFilialId") {
    child.attributes.cFilialId = uuidv4();
  }
  return {
    filialRelationshipId: child.attributes.cFilialId,
    childId: child.id,
    treeId: treeId,
    relationType: "Biological",
    parent: treePersonFunction(parent, treeId),
    siblingFilialRelationshipIds: []
  };
};

export const addPotentialPersonPayload = (child, parent, treeId) => {
  console.log({ child, parent, treeId });
  if (child?.attributes?.cFilialId === "" || child?.attributes?.cFilialId === "cFilialId") {
    child.attributes.cFilialId = uuidv4();
  }
  return {
    "filialRelationshipId": child?.attributes?.cFilialId || uuidv4(),
    "childId": child.id,
    "parent": {
      "treeId": treeId,
      "id": parent.id ?? uuidv4(),
      "givenName": parent?.firstName || parent?.givenName,
      "surname": parent?.lastName || parent?.surname,
      "gender": parent?.attributes?.gender || parent.gender,
      "race": "",
      "isLiving": parent?.isLiving || parent?.livingStatus,
      "birthDate": (parent?.attributes?.birth?.year || parent?.birthDate?.rawDate) ?? "",
      "birthLocation": (parent?.attributes?.birthLocation || parent.birthLocation) ?? "",
      "deathDate": (parent?.attributes?.death?.year || parent?.deathDate?.rawDate) ?? "",
      "deathLocation": (parent?.attributes?.deathLocation || parent?.deathLocation) ?? "",
      "birthLocationId": "",
      "deathLocationId": "",
      "residenceEvents": []
    },
    "treeId": treeId,
    "relationType": "Biological",
    "siblingFilialRelationshipIds": [],
    "potentialParentHintId": parent?.pendingParentHintId || parent?.pendingHintId
  };
};

const getTreeName = (personData) => {
  if (personData.lastName) {
    return `${personData.lastName} Family Tree`;
  }
  return `${personData.firstName} Family Tree`;
};

export const getAccountCreationPayload = (userId, adb2cInfo) => {
  return {
    definitionKey: "st-reg-confirmation",
    recipients: [
      {
        contactKey: userId,
        to: adb2cInfo.mail,
        messageKey: uuidv4(),
        attributes: {
          FirstName: adb2cInfo.givenName,
          LastName: adb2cInfo.surname
        }
      }
    ]
  };
};

export const getAccountActivationPayload = (userId, adb2cInfo) => {
  return {
    definitionKey: "st-account-activation-confirmation",
    recipients: [
      {
        contactKey: userId,
        to: adb2cInfo.mail,
        messageKey: uuidv4(),
        attributes: {
          FirstName: adb2cInfo.givenName,
          LastName: adb2cInfo.surname
        }
      }
    ]
  };
};

const getFormattedDate = () => {
  let year = new Date().getFullYear();

  let month = (1 + new Date().getMonth()).toString();
  month = month.length > 1 ? month : "0" + month;

  let day = new Date().getDate().toString();
  day = day.length > 1 ? day : "0" + day;

  return month + "/" + day + "/" + year;
};

export const getAddUserDemographicsPayload = (userId, adb2cInfo) => {
  let date = getFormattedDate();
  return [
    {
      keys: {
        AzureID: userId
      },
      values: {
        Title: "",
        FirstName: adb2cInfo.givenName,
        LastName: adb2cInfo.surname,
        DateAdded: date,
        LastUpdateDate: date
      }
    }
  ];
};

export const getAddUserPreferencesPayload = (userId) => {
  return [
    {
      keys: {
        AzureID: userId
      },
      values: {
        Promo_Email: "True",
        Tips_Email: "True",
        Newsletter_Email: "True"
      }
    }
  ];
};

export const startNewTreeWithFocusPerson = (personData, isSharedWithPartners, birthYear) => {
  const treeId = uuidv4();
  const treeName = getTreeName(personData);

  return {
    treeId: treeId,
    treeName: treeName,
    homePerson: {
      treeId: treeId,
      givenName: personData.firstName,
      surname: personData.lastName,
      id: uuidv4(),
      gender: personData.gender,
      birthDate: birthYear || personData.birthDate,
      birthLocation: personData.birthPlace,
      birthLocationId: personData.birthLocationId || "",
      deathLocationId: "",
      deathDate: personData.death,
      deathLocation: personData.deathPlace,
      isLiving: personData.isLiving
    },
    userId: personData.id,
    isSharedWithPartners: isSharedWithPartners
  };
};

const treePersonFunction = (person, treeId) => {
  return {
    treeId: treeId,
    givenName: person.firstName,
    surname: person.lastName,
    isLiving: person.isLiving,
    id: person.id,
    gender: person.attributes.gender,
    birthDate: person.attributes.birth.rawDate,
    birthLocation: person.attributes.birthLocation,
    deathDate: person.attributes.death.rawDate,
    deathLocation: person.attributes.deathLocation,
    birthLocationId: person.birthLocationId,
    deathLocationId: person.deathLocationId,
    residenceEvents: getAddedLocations(person.upDatedResidenceEvents, treeId, person.id)
  };
};

export const gedcomPayload = (FileName, FormFile, isSharedWithPartners) => {
  let formData = new FormData();
  formData.append("UserId", getOwner());
  formData.append("TreeId", uuidv4());
  formData.append("FileName", FileName);
  formData.append("FormFile", FormFile);
  formData.append("IsSharedWithPartners", isSharedWithPartners);
  return formData;
};

const getNaClipImgPayload = (formData, selectedFile) => {
  formData.append(`${EXTERNAL_IMAGE}.MediaId`, selectedFile.mediaId);
  formData.append(`${EXTERNAL_IMAGE}.publicationTitleId`, selectedFile.publicationTitleId);
  formData.append(`${EXTERNAL_IMAGE}.fullImagePath`, selectedFile.fullImagePath);
  formData.append(`${EXTERNAL_IMAGE}.thumbnailImagePath`, selectedFile.thumbnailImagePath);
  formData.append(`${EXTERNAL_IMAGE}.storyImagePath`, selectedFile.storyImagePath);
  formData.append(`${EXTERNAL_IMAGE}.redirectionPath`, selectedFile.redirectionPath);
  formData.append(`${EXTERNAL_IMAGE}.mediaSource`, selectedFile.mediaSource);
};

export const imageUploadPayload = (imagePayload, FF_HandleDerivedImagesAtBE) => {
  const { selectedFile, profileImage, croppingInfo } = imagePayload;
  const formData = new FormData();
  formData.append("OwnerId", getOwner());
  formData.append("TreeId", imagePayload.treeId);
  if (imagePayload?.petId) {
    formData.append("PetId", imagePayload.petId);
  } else {
    formData.append("PersonId", imagePayload.treePersonId);
  }
  formData.append("Size", imagePayload.size);
  formData.append("AuthorCredit", imagePayload.authorCredit);
  formData.append("OriginalMediaId", selectedFile?.mediaId || uuidv4());
  selectedFile?.file && formData.append("OriginalImage", selectedFile.file);
  selectedFile?.mediaId && formData.append("OriginalImageURL", selectedFile.mediaUrl);
  !FF_HandleDerivedImagesAtBE && formData.append("ProfileImage", profileImage);
  if (selectedFile?.mediaType === EXTERNAL_IMAGE) {
    formData.append("IsImageOriginatingFromExternalMedia", true);
  }
  if (selectedFile?.naClipping) {
    getNaClipImgPayload(formData, selectedFile);
  }
  formData.append("MediaId", uuidv4());
  Object.entries(croppingInfo).forEach(([key, value]) => {
    formData.append(`CroppingInfo.${key}`, value);
  });
  return formData;
};

export const groupImageUploadPayload = (imagePayload, editMode) => {
  const {
    selectedMedia,
    originalImageId,
    originalImageUrl,
    groupId,
    authorCredit,
    croppingInfo,
    isExternalImage
  } = imagePayload;
  const formData = new FormData();
  formData.append("OwnerId", getOwner());
  if (editMode) {
    formData.append("OriginalMediaId", originalImageId);
    formData.append("OriginalImageURL", originalImageUrl);
  } else {
    formData.append("OriginalMediaId", selectedMedia?.mediaId || uuidv4());
  }
  selectedMedia?.mediaId && formData.append("OriginalImageURL", selectedMedia.mediaUrl);
  selectedMedia?.file && formData.append("OriginalImage", selectedMedia.file);
  if (selectedMedia?.mediaType === EXTERNAL_IMAGE || isExternalImage) {
    formData.append("IsImageOriginatingFromExternalMedia", true);
  }
  if (selectedMedia?.naClipping) {
    getNaClipImgPayload(formData, selectedMedia);
  }
  formData.append("MediaId", uuidv4());
  formData.append("GroupId", groupId);
  formData.append("AuthorCredit", authorCredit);
  Object.entries(croppingInfo).forEach(([key, value]) => {
    formData.append(`CroppingInfo.${key}`, value);
  });
  return formData;
};

export const heroImageUploadPayload = (imagePayload, FF_HandleDerivedImagesAtBE) => {
  const { selectedHeroFile, profileImage, croppingInfo } = imagePayload;
  const formData = new FormData();
  formData.append("OwnerId", getOwner());
  formData.append("TreeId", imagePayload.treeId);
  if (imagePayload?.petId) {
    formData.append("PetId", imagePayload.petId);
  } else {
    formData.append("PersonId", imagePayload.treePersonId);
  }
  formData.append("AuthorCredit", imagePayload.authorCredit);
  formData.append("OriginalMediaId", selectedHeroFile?.mediaId || uuidv4());
  selectedHeroFile?.file && formData.append("OriginalImage", selectedHeroFile.file);
  selectedHeroFile?.mediaId && formData.append("OriginalImageURL", selectedHeroFile.mediaUrl);
  !FF_HandleDerivedImagesAtBE && formData.append("BackgroundImage", profileImage);
  if (selectedHeroFile?.mediaType === EXTERNAL_IMAGE) {
    formData.append("IsImageOriginatingFromExternalMedia", true);
  }
  if (selectedHeroFile?.naClipping) {
    getNaClipImgPayload(formData, selectedHeroFile);
  }
  formData.append("MediaId", uuidv4());
  Object.entries(croppingInfo).forEach(([key, value]) => {
    formData.append(`CroppingInfo.${key}`, value);
  });
  return formData;
};

export const heroImageEditPayload = (heroImagePayload) => {
  const { originalImageId, originalImageURL, profileImage, croppingInfo, isExternalImage } =
    heroImagePayload;
  const formData = new FormData();
  formData.append("OwnerId", getOwner());
  formData.append("OriginalMediaId", originalImageId);
  formData.append("OriginalImageURL", originalImageURL);
  formData.append("TreeId", heroImagePayload.treeId);
  if (heroImagePayload?.petId) {
    formData.append("PetId", heroImagePayload.petId);
  } else {
    formData.append("PersonId", heroImagePayload.treePersonId);
  }
  formData.append("AuthorCredit", heroImagePayload.authorCredit);
  profileImage && formData.append("BackgroundImage", profileImage);
  if (isExternalImage) {
    formData.append("IsImageOriginatingFromExternalMedia", true);
  }
  formData.append("MediaId", uuidv4());
  Object.entries(croppingInfo).forEach(([key, value]) => {
    formData.append(`CroppingInfo.${key}`, value);
  });
  return formData;
};

export const userImageUploadPayload = (imagePayload) => {
  const { originalImageId, OriginalImageURL, selectedFile, croppingInfo, isExternalImage } =
    imagePayload;
  const formData = new FormData();
  formData.append("OwnerId", getOwner());
  formData.append("AuthorCredit", imagePayload.authorCredit);
  if (originalImageId) {
    formData.append("OriginalMediaId", originalImageId);
    formData.append("OriginalImageURL", OriginalImageURL);
  } else {
    formData.append("OriginalMediaId", selectedFile?.mediaId || uuidv4());
  }
  selectedFile?.file && formData.append("OriginalImage", selectedFile.file);
  selectedFile?.mediaId && formData.append("OriginalImageURL", selectedFile.mediaUrl);
  if (selectedFile?.mediaType === EXTERNAL_IMAGE || isExternalImage) {
    formData.append("IsImageOriginatingFromExternalMedia", true);
  }
  if (selectedFile?.naClipping) {
    getNaClipImgPayload(formData, selectedFile);
  }
  formData.append("MediaId", uuidv4());
  Object.entries(croppingInfo).forEach(([key, value]) => {
    formData.append(`CroppingInfo.${key}`, value);
  });
  return formData;
};

export const editImageUploadPayload = (detailsPayload) => {
  const { originalImageId, originalImageURL, profileImage, croppingInfo, isExternalImage } =
    detailsPayload;
  const formData = new FormData();
  formData.append("OwnerId", getOwner());
  formData.append("OriginalMediaId", originalImageId);
  formData.append("OriginalImageURL", originalImageURL);
  formData.append("TreeId", detailsPayload.treeId);
  if (detailsPayload?.petId) {
    formData.append("PetId", detailsPayload.petId);
  } else {
    formData.append("PersonId", detailsPayload.treePersonId);
  }
  formData.append("AuthorCredit", detailsPayload.authorCredit);
  profileImage && formData.append("ProfileImage", profileImage);
  if (isExternalImage) {
    formData.append("IsImageOriginatingFromExternalMedia", true);
  }
  formData.append("MediaId", uuidv4());
  Object.entries(croppingInfo).forEach(([key, value]) => {
    formData.append(`CroppingInfo.${key}`, value);
  });
  return formData;
};

export const assignPayload = (treeId, homePersonId, thisIsMePerson) => {
  return {
    treeId,
    userId: getOwner(),
    homePersonId,
    thisIsMePerson
  };
};

export const createUID = (value) => {
  if (value === BlankId) return uuidv4();
  else return value;
};

export const editPersonPayload = (person, editPerson, treeId) => {
  const {
    firstName,
    lastName,
    isLiving,
    gender,
    birth,
    birthPlace,
    death,
    deathPlace,
    birthLocationId,
    deathLocationId,
    residenceEvents,
    upDatedResidenceEvents
  } = editPerson;
  const editPayload = {
    personId: person.id,
    treeId,
    newGivenName: trimString(firstName),
    newSurname: trimString(lastName),
    newGender: gender,
    newLivingStatus: isLiving.toString(),
    newBirthDate: trimString(birth),
    newBirthLocation: trimString(birthPlace),
    newDeathDate: trimString(death),
    newDeathLocation: trimString(deathPlace),
    newBirthLocationId: birthLocationId || "",
    newDeathLocationId: deathLocationId || "",
    residenceEvents: getUpdatedResidenceLocation(
      residenceEvents,
      upDatedResidenceEvents,
      treeId,
      person.id
    )
  };
  if (firstName === person.firstName) delete editPayload.newGivenName;
  if (lastName === person.lastName) delete editPayload.newSurname;
  if (gender === person.gender) delete editPayload.newGender;
  if (isLiving.toString().toLowerCase() === person.isLiving.toString().toLowerCase())
    delete editPayload.newLivingStatus;
  if (birth === person.birth) delete editPayload.newBirthDate;
  if (death === person.death) delete editPayload.newDeathDate;
  if (birthPlace === person.birthPlace) delete editPayload.newBirthLocation;
  if (deathPlace === person.deathPlace) delete editPayload.newDeathLocation;
  if (birthPlace === person.birthPlace) delete editPayload.newBirthLocationId;
  if (deathPlace === person.deathPlace) delete editPayload.newDeathLocationId;
  return editPayload;
};
//for updated residence locations
const residencePayload = (treeId, obj, actionType, personId) => {
  return {
    treeId: treeId,
    personId: personId,
    locationAssertionId: obj.assertionId,
    newLocation: obj.location,
    eventType: "residence",
    newLocationId: obj.locationId,
    apiAction: actionType
  };
};
const getUpdatedResidenceLocation = (oldLocation, updatedLocation, treeId, personId) => {
  let res = [];
  updatedLocation.map(function (obj) {
    let result = oldLocation.find(
      (element) => obj.assertionId === element.assertionId && obj.location === element.location
    );
    let editedResult = oldLocation.find(
      (element) => obj.assertionId === element.assertionId && obj.location !== element.location
    );
    if (result) {
      return;
    } else if (editedResult) {
      let payload = residencePayload(treeId, obj, "edit", personId);
      res.push(payload);
    } else {
      if (obj.location && obj.location.length > 0) {
        let payload = residencePayload(treeId, obj, "add", personId);
        res.push(payload);
      }
    }
  });
  //check for deleted location
  oldLocation.map(function (obj) {
    let deletedResult = updatedLocation.some(function (arrVal) {
      return obj.assertionId === arrVal.assertionId;
    });
    if (!deletedResult) {
      let payload = residencePayload(treeId, obj, "delete", personId);
      res.push(payload);
    }
  });
  return res;
};

export const getAddedLocations = (data, treeId, personId) => {
  let res = [];
  (data || []).map(function (obj) {
    if (obj.location && obj.location.length > 0) {
      let payload = residencePayload(treeId, obj, "add", personId);
      res.push(payload);
    }
  });
  return res;
};

export const getValuesForCheckBoxes = (siblings) => {
  return siblings.reduce((res, ele) => {
    res.push({
      id: ele.id,
      filialRelationshipId: ele.filialRelationshipId,
      firstName: ele.firstName,
      lastName: ele.lastName,
      check: true
    });
    return res;
  }, []);
};

export const getParentPayload = (selectedNode) => {
  return {
    id: selectedNode.id,
    treeId: selectedNode.treeId,
    nodeType: selectedNode.nodeType,
    relationAdded: selectedNode.relationAdded,
    homePersonId: selectedNode.homePersonId,
    generation: selectedNode.generation,
    filialRelationshipId: selectedNode.filialRelationshipId,
    childId: selectedNode.childId,
    selectedName: selectedNode.selectedName,
    firstName: "",
    lastName: "",
    isLiving: selectedNode.isLiving,
    gender: selectedNode.gender,
    requiredGender: selectedNode.requiredGender,
    birth: "",
    birthPlace: "",
    death: "",
    deathPlace: "",
    siblings: []
  };
};

export const getSiblingPayload = (selectedNode) => {
  return {
    id: selectedNode.id,
    relationAdded: selectedNode.relationAdded,
    nodeType: selectedNode.nodeType,
    treeId: selectedNode.treeId,
    homePersonId: selectedNode.homePersonId,
    generation: selectedNode.generation,
    filialRelationshipId: selectedNode.filialRelationshipId,
    primaryPersonId: selectedNode.primaryPersonId,
    selectedName: selectedNode.selectedName,
    firstName: "",
    lastName: "",
    isLiving: selectedNode.isLiving,
    gender: "",
    birth: "",
    birthPlace: "",
    death: "",
    deathPlace: "",
    pfirstName: "",
    plastName: "",
    parents: []
  };
};

export const getSiblingDropdown = (parents) => {
  return parents.reduce((res, ele) => {
    let firstElement = ele.Combination[0];
    let secondElement = ele.Combination[1];
    res.push({
      id: firstElement.id,
      gender: firstElement.gender,
      name: trimString(
        `${titleCase(firstElement.firstName)} ${titleCase(
          firstElement.lastName
        )} and ${checkUnknown(secondElement, "SIBLING")}`
      ),
      spouseId: secondElement.id
    });
    return res;
  }, []);
};

export const saveIndividualPayload = (modalPerson) => {
  const {
    firstName,
    lastName,
    gender,
    isLiving,
    birth,
    birthPlace,
    death,
    deathPlace,
    upDatedResidenceEvents
  } = modalPerson;
  let treeId = "00000000-0000-0000-0000-000000000000";
  return {
    treeId: treeId,
    id: modalPerson.id,
    givenName: trimString(firstName),
    surname: trimString(lastName),
    gender,
    isLiving,
    birthDate: birth,
    birthLocation: birthPlace,
    deathDate: death,
    deathLocation: deathPlace,
    birthLocationId: "",
    deathLocationId: "",
    residenceEvents: getAddedLocations(upDatedResidenceEvents, treeId, modalPerson.id)
  };
};

export const saveParentPayload = (modalPerson) => {
  const {
    firstName,
    lastName,
    gender,
    isLiving,
    birth,
    birthPlace,
    death,
    deathPlace,
    siblings,
    birthLocationId,
    deathLocationId,
    upDatedResidenceEvents
  } = modalPerson;
  const siblingFilialRelationshipIds = siblings
    ? siblings.reduce((res, ele) => {
      if (ele.check) res.push(ele.filialRelationshipId);
      return res;
    }, [])
    : [];
  return {
    filialRelationshipId: modalPerson.filialRelationshipId,
    childId: modalPerson.childId,
    parent: {
      treeId: modalPerson.treeId,
      id: modalPerson.id,
      givenName: trimString(firstName),
      surname: trimString(lastName),
      gender,
      isLiving,
      birthDate: trimString(birth),
      birthLocation: trimString(birthPlace),
      deathDate: trimString(death),
      deathLocation: trimString(deathPlace),
      birthLocationId,
      deathLocationId,
      residenceEvents: getAddedLocations(upDatedResidenceEvents, modalPerson.treeId, modalPerson.id)
    },
    treeId: modalPerson.treeId,
    relationType: "Biological",
    siblingFilialRelationshipIds
  };
};

const payloadForNewChild = (newPerson, modalPerson) => {
  const {
    firstName,
    lastName,
    gender,
    isLiving,
    birth,
    birthPlace,
    existingParentIds,
    id,
    death,
    deathPlace,
    birthLocationId,
    deathLocationId,
    upDatedResidenceEvents
  } = modalPerson;
  const { filialRelationshipId, treeId, primaryPersonId } = newPerson;
  const parentArray = existingParentIds.filter((i) => i !== BlankId);
  return {
    filialRelationshipId,
    primaryPersonId,
    existingParentIds: parentArray,
    child: {
      treeId: treeId,
      id,
      givenName: trimString(firstName),
      surname: trimString(lastName),
      gender,
      isLiving,
      birthDate: trimString(birth),
      birthLocation: trimString(birthPlace),
      deathDate: trimString(death),
      deathLocation: trimString(deathPlace),
      birthLocationId,
      deathLocationId,
      residenceEvents: getAddedLocations(upDatedResidenceEvents, treeId, id)
    },
    NewParent: {
      treeId,
      id: createUID(BlankId),
      givenName: trimString(modalPerson.pfirstName),
      surname: trimString(modalPerson.plastName),
      gender: modalPerson.unknownGender,
      isLiving: true,
      birthDate: "",
      birthLocation: ""
    },
    treeId: treeId,
    relationType: "Biological"
  };
};

const payloadForBothParents = (newPerson, modalPerson) => {
  const {
    firstName,
    lastName,
    gender,
    isLiving,
    birth,
    birthPlace,
    existingParentIds,
    id,
    death,
    deathPlace,
    birthLocationId,
    deathLocationId,
    upDatedResidenceEvents
  } = modalPerson;
  const { filialRelationshipId, treeId, primaryPersonId } = newPerson;
  const parentArray = existingParentIds.filter((i) => i !== BlankId);
  return {
    filialRelationshipId,
    primaryPersonId,
    existingParentIds: parentArray,
    child: {
      treeId: treeId,
      id,
      givenName: trimString(firstName),
      surname: trimString(lastName),
      gender,
      isLiving,
      birthDate: trimString(birth),
      birthLocation: trimString(birthPlace),
      deathDate: trimString(death),
      deathLocation: trimString(deathPlace),
      birthLocationId,
      deathLocationId,
      residenceEvents: getAddedLocations(upDatedResidenceEvents, treeId, id)
    },
    treeId: treeId,
    relationType: "Biological"
  };
};

export const saveSiblingPayload = (newPerson, modalPerson) => {
  const { existingParentIds, pfirstName, plastName } = modalPerson;
  if (existingParentIds.includes(BlankId) && (pfirstName || plastName)) {
    return payloadForNewChild(newPerson, modalPerson);
  } else {
    return payloadForBothParents(newPerson, modalPerson);
  }
};

const getOptions = (selectedNode, spousesList) => {
  let result = [];
  result = spousesList.reduce((res, ele) => {
    res.push({
      id: selectedNode.treePersonId,
      name: trimString(
        `${titleCase(selectedNode.firstName)} ${titleCase(selectedNode.lastName)} and ${titleCase(
          ele.firstName
        )} ${titleCase(ele.lastName)}`
      ),
      spouseId: ele.id
    });
    return res;
  }, []);
  result.push({
    id: selectedNode.treePersonId,
    name: trimString(
      `${titleCase(selectedNode.firstName)} ${titleCase(selectedNode.lastName)} and ${checkUnknown(
        selectedNode,
        "CHILD"
      )}`
    ),
    spouseId: BlankId
  });
  return result;
};
//To add related events via modal for allLifeEvents table
export const getSpouseOptions = (spousesList) => {
  let result = [];
  result = spousesList.reduce((res, ele) => {
    res.push({
      id: ele.id,
      name: trimString(`${titleCase(ele.firstName)} ${titleCase(ele.lastName)}`),
      firstName: trimString(`${titleCase(ele.firstName)}`),
      lastName: trimString(`${titleCase(ele.lastName)}`),
      spouseId: ele.id,
      spousalRelationshipId: ele?.RelationshipId
    });
    return res;
  }, []);
  result.push({
    id: uuidv4(),
    name: trimString(`${titleCase("Add New Spouse")}`),
    firstName: "",
    lastName: "",
    spouseId: BlankId,
    spousalRelationshipId: uuidv4()
  });
  return result;
};

//addChild set-modal-data
export const getChildPayload = (selectedNode) => {
  return {
    id: selectedNode.id,
    treeId: selectedNode.treeId,
    nodeType: selectedNode.nodeType,
    relationAdded: selectedNode.relationAdded,
    homePersonId: selectedNode.homePersonId,
    generation: selectedNode.generation,
    filialRelationshipId: selectedNode.filialRelationshipId,
    childId: selectedNode.childId,
    selectedName: selectedNode.selectedName,
    nodeGender: selectedNode.nodeGender,
    firstName: "",
    lastName: "",
    isLiving: selectedNode.isLiving,
    gender: "",
    requiredGender: selectedNode.requiredGender,
    birth: "",
    birthPlace: "",
    death: "",
    deathPlace: "",
    pfirstName: "",
    plastName: "",
    primaryPersonId: selectedNode.primaryPersonId,
    spouses: []
  };
};
export const getParentAndSpousesPayload = (selectedNode, spousesList) => {
  const options = getOptions(selectedNode, spousesList);
  return {
    id: selectedNode.id,
    treeId: selectedNode.treeId,
    filialRelationshipId: selectedNode.filialRelationshipId,
    childId: selectedNode.childId,
    selectedName: selectedNode.selectedName,
    nodeGender: selectedNode.nodeGender,
    firstName: "",
    lastName: "",
    isLiving: selectedNode.isLiving,
    gender: "",
    requiredGender: selectedNode.requiredGender,
    birth: "",
    birthPlace: "",
    death: "",
    deathPlace: "",
    pfirstName: "",
    plastName: "",
    primaryPersonId: selectedNode.primaryPersonId,
    spouses: options
  };
};

//addSpouse set-modal-data
export const getSpousePayload = (selectedNode) => {
  return {
    id: selectedNode.id,
    treeId: selectedNode.treeId,
    nodeType: selectedNode.nodeType,
    spousesLength: selectedNode.spousesLength,
    relationAdded: selectedNode.relationAdded,
    treePersonId: selectedNode.treePersonId,
    homePersonId: selectedNode.homePersonId,
    generation: selectedNode.generation,
    directChildren: selectedNode.directChildren,
    filialRelationshipId: selectedNode.filialRelationshipId,
    spousalRelationshipId: selectedNode.spousalRelationshipId,
    selectedName: selectedNode.selectedName,
    firstName: "",
    lastName: "",
    isLiving: selectedNode.isLiving,
    gender: selectedNode.gender,
    requiredGender: selectedNode.requiredGender,
    birth: "",
    birthPlace: "",
    death: "",
    deathPlace: "",
    children: []
  };
};

export const getSpouseAndChildrenPayload = (selectedNode, children) => {
  const myChildren = getValuesForCheckBoxes(children);
  return {
    id: selectedNode.id,
    treeId: selectedNode.treeId,
    treePersonId: selectedNode.treePersonId,
    filialRelationshipId: selectedNode.filialRelationshipId,
    spousalRelationshipId: selectedNode.spousalRelationshipId,
    selectedName: selectedNode.selectedName,
    firstName: "",
    lastName: "",
    isLiving: selectedNode.isLiving.toString(),
    gender: selectedNode.gender,
    requiredGender: selectedNode.requiredGender,
    birth: "",
    birthPlace: "",
    death: "",
    deathPlace: "",
    children: myChildren
  };
};

export const saveSpousePayload = (newPerson, modalPerson) => {
  const {
    firstName,
    lastName,
    gender,
    isLiving,
    birth,
    birthPlace,
    death,
    deathPlace,
    birthLocationId,
    deathLocationId,
    upDatedResidenceEvents
  } = modalPerson;
  const childFilialRelationshipIds = modalPerson.children.reduce((res, ele) => {
    if (ele.check) res.push(ele.filialRelationshipId);
    return res;
  }, []);
  return {
    spousalRelationshipId: newPerson.spousalRelationshipId,
    primaryPersonId: newPerson.treePersonId,
    spouse: {
      treeId: newPerson.treeId,
      id: newPerson.id,
      givenName: trimString(firstName),
      surname: trimString(lastName),
      gender,
      isLiving,
      birthDate: trimString(birth),
      birthLocation: trimString(birthPlace),
      deathDate: trimString(death),
      deathLocation: trimString(deathPlace),
      birthLocationId,
      deathLocationId,
      residenceEvents: getAddedLocations(upDatedResidenceEvents, newPerson.treeId, newPerson.id)
    },
    treeId: newPerson.treeId,
    childFilialRelationshipIds
  };
};
//add relatedevent set-modal-data
export const getRelatedEventPayload = (selectedNode) => {
  return {
    id: selectedNode.treePersonId,
    treeId: selectedNode.treeId,
    relationAdded: selectedNode.relationAdded,
    selectedName: selectedNode.selectedName,
    isLiving: selectedNode.isLiving,
    gender: "",
    requiredGender: selectedNode.requiredGender,
    date: selectedNode.date,
    description: selectedNode.description,
    location: selectedNode.location,
    locationId: "",
    birth: "",
    birthLocation: "",
    birthLocationId: "",
    death: "",
    deathLocation: "",
    deathLocationId: "",
    sfirstName: "",
    slastName: "",
    spousalRelationshipId: "",
    primaryPersonId: selectedNode.primaryPersonId,
    spouses: [],
    residenceEvents: []
  };
};

export const getSnackBarStatus = (windowLocation, treeId) => {
  if (
    (windowLocation.includes("pedigree-view") && windowLocation.includes(`${treeId}`)) ||
    (windowLocation.includes("stories") &&
      (windowLocation.includes("add") || windowLocation.includes("edit")))
  ) {
    return false;
  }
  return true;
};

export const getFsTreeSnackBarStatus = (windowLocation) => {
  if (
    windowLocation.includes("pedigree-view") ||
    (windowLocation.includes("stories") &&
      (windowLocation.includes("add") || windowLocation.includes("edit"))) ||
    windowLocation.includes("payment")
  ) {
    return false;
  }
  return true;
};

export const getReducedGroupInfo = (groupObj, cookieGroupInfo) => {
  try {
    let updatedInfo,
      groupInfo = groupObj;
    if (groupObj && typeof groupObj === "string") {
      groupInfo = JSON.parse(groupObj);
    }
    if (cookieGroupInfo) updatedInfo = { ...groupInfo, ...cookieGroupInfo };
    else updatedInfo = groupInfo;
    let keyArr = Object.keys(updatedInfo);
    let valueArr = Object.values(updatedInfo);
    return valueArr.map((ele, index) => {
      return { groupId: keyArr[index], role: ele };
    });
  } catch (err) {
    console.log("Err..", err);
  }
};

export const getUpdateGroupInfo = (groupObj, cookieGroupInfo) => {
  try {
    let keyArr = Object.keys(cookieGroupInfo || {});
    let valueArr = Object.values(cookieGroupInfo);
    let newArr = valueArr.map((ele, index) => {
      return { groupId: keyArr[index], role: ele };
    });
    return [...groupObj, ...newArr];
  } catch (err) {
    console.log("Err..", err);
  }
};

export const getUpdatedPreferenceOnly = (oldData, newData) => {
  const {
    group_digest_email,
    group_digest_frequency,
    newsletter_email,
    promo_email,
    social_digest_email,
    social_digest_frequency,
    tips_email,
    hints_email,
    hints_email_frequency,
    newspaper_match_email,
    newspaper_match_frequency,
    story_prompt_email
  } = newData;
  const updatedPayload = {
    group_digest_email: group_digest_email,
    group_digest_frequency: group_digest_frequency,
    newsletter_email: newsletter_email,
    promo_email: promo_email,
    social_digest_email: social_digest_email,
    social_digest_frequency: social_digest_frequency,
    tips_email: tips_email,
    hints_email: hints_email,
    hints_email_frequency: hints_email_frequency,
    newspaper_match_email: newspaper_match_email,
    newspaper_match_frequency: newspaper_match_frequency,
    story_prompt_email: story_prompt_email
  };
  if (group_digest_email === oldData.group_digest_email) delete updatedPayload.group_digest_email;
  if (group_digest_frequency === oldData.group_digest_frequency)
    delete updatedPayload.group_digest_frequency;
  if (newsletter_email === oldData.newsletter_email) delete updatedPayload.newsletter_email;
  if (promo_email === oldData.promo_email) delete updatedPayload.promo_email;
  if (social_digest_email === oldData.social_digest_email)
    delete updatedPayload.social_digest_email;
  if (social_digest_frequency === oldData.social_digest_frequency)
    delete updatedPayload.social_digest_frequency;
  if (tips_email === oldData.tips_email) delete updatedPayload.tips_email;
  if (hints_email === oldData.hints_email) delete updatedPayload.hints_email;
  if (hints_email_frequency === oldData.hints_email_frequency)
    delete updatedPayload.hints_email_frequency;
  if (newspaper_match_email === oldData.newspaper_match_email)
    delete updatedPayload.newspaper_match_email;
  if (newspaper_match_frequency === oldData.newspaper_match_frequency)
    delete updatedPayload.newspaper_match_frequency;
  if (story_prompt_email === oldData.story_prompt_email) delete updatedPayload.story_prompt_email;

  return updatedPayload;
};

export const getUpdatedAgeInEvents = (person, newData, newAge) => {
  const updatedAgeEvents = person.lifeEvents.map((ele) => {
    if (ele.id === newData.id) {
      return {
        ...ele,
        age: newAge,
        date: {
          ...ele.date,
          Date: {
            ...ele.date.Date,
            RawDate: newData.date
          }
        }
      };
    }
    return ele;
  });
  return updatedAgeEvents;
};

const removeMediaFilter = (state, payload, key) => {
  if (!state[key] || state[key] === "" || state[key]?.length === 0) return [];
  return state[key].map((authorStory) => {
    if (typeof authorStory !== "string" && authorStory.authorId === getOwner()) {
      authorStory.storyImages = (authorStory.storyImages || []).filter(
        (image) => image.originatingMediaId !== payload && image.mediaId !== payload
      );
      authorStory.storyExternalImages = (authorStory.storyExternalImages || []).filter(
        (image) => image.originatingMediaId !== payload && image.mediaId !== payload
      );
    }
    return authorStory;
  });
};

export const removeMediaFromStories = (state, payload, arrKey) => {
  for (let i = 0; i < arrKey?.length; i++) {
    state[arrKey[i]] = removeMediaFilter(state, payload, arrKey[i]);
  }

  return { ...state };
};

export const getGlobalPersonPageError = () => {
  const isStoriesInURL = window.location.href.includes("stories");
  if (isStoriesInURL) {
    return {
      errorMessage: "Stories Failed to Load",
      errorDescription:
        "We’re having trouble loading your stories. Don’t worry, we promise they’re still there. Please refresh the page to try again.",
      errorPage: "stories"
    };
  } else {
    return {
      errorMessage: "Person failed to load",
      errorDescription:
        "Something went wrong trying to load your person. Please refresh the page to try again."
    };
  }
};

export const getPersonPageError = () => {
  const isStoriesInURL = window.location.href.includes("stories");
  if (isStoriesInURL) {
    return {
      errorMessage: "Stories Failed to Load",
      errorDescription:
        "We’re having trouble loading your stories. Don’t worry, we promise they’re still there. Please refresh the page to try again.",
      errorPage: "stories"
    };
  }
  return false;
};