import { MediaTypes } from "pages/StoriesLayoutV1/AddStoriesV1/helpers";
import { defaultStoryState } from "pages/StoriesLayoutV1/AddStoriesV1/helpers/defaultStoryState";
import { ownStoryUpdateRedux } from "redux/helpers/ownStories";
import { BLANKID, LAYOUT_ID } from "utils";
import { REACT_APP_CLIENT_ID } from "utils/env";
import { v4 as uuidv4 } from "uuid";
import { getGoogleSessionId, getOwner, removeLoggedInViaShareableID } from "../../services";
import {
  CLEARGROUPPAGESTORIES,
  CLEARHOMEPAGESTORIES,
  DELETEINDIVDUALOWNSTORY,
  FEATURED_STORY_ID_REDIRECT,
  GET,
  POST,
  PUT,
  SAVEDRAFTPREVIEWREMOVE,
  SAVEDRAFTPREVIEWSTORE
} from "../constants";
import { apiRequest } from "../requests";
import * as CONSTANTS from "./../constants/actionTypes";
import * as API_URLS from "./../constants/apiUrl";
import { actionCreator, callApi } from "./../utils";
import { dismissTellAStoryPrompt } from "./homepagePrompts";
import { addMessage } from "./toastr";
import { DeletedFilterStory, draftAction } from "./utils/storyHelper";

const createHeader = () => {
  return {
    "wa-clientId": REACT_APP_CLIENT_ID,
    "wa-requestId": uuidv4(),
    "wa-sessionId": getGoogleSessionId()
  };
};
const createMultipartHeader = () => {
  return {
    ...createHeader(),
    "Content-Type": "multipart/form-data"
  };
};

const getParamKey = (key, authorId) => {
  if (authorId) {
    return `&${key}`;
  } else {
    return `?${key}`;
  }
};

export const getCitation = (storyId) => async (dispatch) => {
  try {
    dispatch({ type: CONSTANTS.GET_CITATION.REQUEST });
    const res = await apiRequest(GET, `Story/${storyId}/citation`);
    dispatch({ type: CONSTANTS.GET_CITATION.SUCCESS, payload: res.data });
  } catch (err) {
    dispatch({ type: CONSTANTS.GET_CITATION.FAILURE, payload: err });
  }
};

export const updateCitation = (data) => async (dispatch) => {
  try {
    dispatch({ type: CONSTANTS.UPDATE_CITATION.REQUEST });
    const res = await apiRequest(POST, `Story/editcitation`, data);
    dispatch({ type: CONSTANTS.UPDATE_CITATION.SUCCESS, payload: res.data });
    dispatch(addMessage("Story citation updated successfully.", "success"));
  } catch (err) {
    dispatch({ type: CONSTANTS.UPDATE_CITATION.FAILURE, payload: err });
    dispatch(addMessage(err.response.data, "error"));
  }
};

export const removeCitation = (storyId) => async (dispatch) => {
  try {
    dispatch({ type: CONSTANTS.REMOVE_CITATION.REQUEST });
    const res = await apiRequest(POST, `Story/${storyId}/removeCitation`);
    dispatch({ type: CONSTANTS.REMOVE_CITATION.SUCCESS, payload: res.data });
    dispatch(addMessage("Story citation removed successfully.", "success"));
  } catch (err) {
    dispatch({ type: CONSTANTS.REMOVE_CITATION.FAILURE, payload: err });
    dispatch(addMessage(err.response.data, "error"));
  }
};

const getGroupsByGroupId = async (groupIds, getState, staticHeader) => {
  let groupIdsurl = Object.keys(groupIds)
      .map(function (k) {
        return encodeURIComponent("groupIds") + "=" + encodeURIComponent(groupIds[k]);
      })
      .join("&"),
    groupUrl = `${API_URLS.getGroupByIds}?${groupIdsurl}`,
    groupData = await callApi("GET", groupUrl, {}, false, staticHeader);
  return groupData.data;
};
const filterDeletedStory = (dataRespo, getState) => {
  let deleteStoryList = getState().storyDelete.list;
  return deleteStoryList.length
    ? dataRespo.filter((elem) => DeletedFilterStory(elem, deleteStoryList))
    : dataRespo;
};
const getStoryURL = (isMemberStories, requestData, data) => {
  let url;
  if (isMemberStories) {
    url = API_URLS.getMemberStories(requestData);
  } else if (data.petId) {
    url = API_URLS.getPetStories(requestData);
  } else {
    url = API_URLS.getStories(requestData);
  }
  return url;
};
export const getStory = (
  data,
  newRequest = true,
  isPagintionLoading = {},
  isMemberStories = false
) => {
  let requestData = { ...data, authorId: data?.authorId };
  return (dispatch, getState) => {
    let url = getStoryURL(isMemberStories, requestData, data),
      staticHeader = createHeader();
    if (data.categoryName) {
      if (data.categoryName === "Drafts") {
        url = url + `${getParamKey("storyStatus", data.authorId)}=Draft`;
      } else {
        url = url + `${getParamKey("categoryName", data.authorId)}=${data.categoryName}`;
      }
    }
    const handleGlobalErr = {
      errorMessage: "Stories Failed to Load",
      errorDescription: "We’re having trouble loading your stories. Don’t worry, we promise they’re still there. Please refresh the page to try again.",
      errorPage: "stories",
    };

    if (newRequest) {
      dispatch(actionCreator(CONSTANTS.GETSTORY.REQUEST));
    } else {
      dispatch(actionCreator(CONSTANTS.GETSTORYPAGINATION.REQUEST));
    }
    setTimeout(() => {
      isPagintionLoading.current = true;
      callApi("GET", url, requestData, false, staticHeader, undefined, undefined, handleGlobalErr)
        .then((res) => {
          let dataRespo = res.data;
          dataRespo = filterDeletedStory(dataRespo, getState);
          if (newRequest) {
            dispatch(actionCreator(CONSTANTS.GETSTORY.SUCCESS, dataRespo));
          } else {
            dispatch(actionCreator(CONSTANTS.GETSTORYPAGINATION.SUCCESS, dataRespo));
            isPagintionLoading.current = false;
          }
        })
        .catch((err) => {
          if (newRequest) {
            dispatch(actionCreator(CONSTANTS.GETSTORY.FAILURE, err.response.data));
          } else {
            dispatch(actionCreator(CONSTANTS.GETSTORYPAGINATION.FAILURE, err));
            isPagintionLoading.current = false;
          }
        });
    }, 1000);
  };
};

export const getStoryAndUpdateList = (data) => {
  return (dispatch, getState) => {
    let url;
    if (data.feed) {
      url = API_URLS.viewStoryforFeed(data);
    } else if (data.collection) {
      url = API_URLS.viewStoryCollection(data);
    } else {
      url = API_URLS.viewStory(data);
    }
    const staticHeader = createHeader();
    return callApi("GET", url, data, false, staticHeader, false, null, false)
      .then((res) => {
        if (res.status !== 500) {
          if (res.data.layoutId && res.data.layoutId !== BLANKID && res.data.storyImages?.length) {
            res.data.layoutId = BLANKID;
            res.data.storyImages = res.data.storyImages.map((image) => ({
              ...image,
              url: image.originatingMediaURL
            }));
          }
          dispatch(actionCreator(CONSTANTS.GETSTORYANDUPDATELIST.SUCCESS, res.data));
        } else {
          dispatch(
            actionCreator(CONSTANTS.GETSTORYANDUPDATELIST.SUCCESS, {
              apiError: true,
              storyId: data.storyId,
              retryLoading: false
            })
          );
          data.setRetryLoading && data.setRetryLoading(false);
        }
      })
      .catch((err) => {
        dispatch(
          actionCreator(CONSTANTS.GETSTORYANDUPDATELIST.SUCCESS, {
            apiError: true,
            storyId: data.storyId,
            retryLoading: false
          })
        );
        data.setRetryLoading && data.setRetryLoading(false);
      });
  };
};
const editSuccessStory = ({
  dispatch,
  setSubmitting,
  res,
  treeId,
  personId,
  ref,
  callback,
  history,
  viewTree,
  petId
}) => {
  setSubmitting(false);
  dispatch({ type: SAVEDRAFTPREVIEWREMOVE });
  dispatch(actionCreator(CONSTANTS.POSTSTORY.SUCCESS, res.data));
  dispatch({ type: CLEARHOMEPAGESTORIES });
  dispatch({ type: CLEARGROUPPAGESTORIES });
  dispatch(addMessage("Story has been updated successfully", "success"));
  if (viewTree) {
    history.replace(viewTree);
  } else if (ref === "5") {
    callback();
  } else if (ref === "7" && petId) {
    history.push(`/family/pet-page/stories/${BLANKID}/${petId}`);
  } else if (ref !== "1" && treeId && personId) {
    history.push(`/family/person-page/stories/${treeId}/${personId}`);
  } else {
    if (ref === "1") {
      history.push("/stories");
    } else {
      history.push("/");
    }
  }
};
function editStoryApiCall({
  dispatch,
  getState,
  formData,
  staticHeader,
  setSubmitting,
  treeId,
  personId,
  ref,
  callback,
  history,
  storyExternalImages,
  viewTree,
  files,
  additionalImages,
  FF_HandleDerivedImagesAtBE,
  petId,
  values
}) {
  const url = FF_HandleDerivedImagesAtBE ? API_URLS.editStoryApiV2 : API_URLS.editStoryApi;
  dispatch(actionCreator(CONSTANTS.POSTSTORY.REQUEST));
  callApi(PUT, url, formData, false, staticHeader)
    .then((res) => {
      ownStoryUpdateRedux({
        dispatch,
        storyId: formData.get("storyId"),
        values,
        files,
        additionalImages,
        storyExternalImages,
        editMode: true
      });
      editSuccessStory({
        dispatch,
        setSubmitting,
        res,
        treeId,
        personId,
        ref,
        callback,
        history,
        viewTree,
        petId
      });
    })
    .catch((err) => {
      setSubmitting(false);
      dispatch(actionCreator(CONSTANTS.SAVETOTREE.FAILURE, err?.response?.data));
      dispatch(addMessage("Sorry, your story couldn't be updated. Please try again.", "error"));
    });
}
function autoSaveApiCall({
  getState,
  dispatch,
  formData,
  ActionProps,
  staticHeader,
  FF_HandleDerivedImagesAtBE,
  storyExternalImages,
  setSubmitting,
  history,
  ref,
  viewTree,
  values,
  files,
  additionalImages,
  existImages,
  existAdditionalImages,
  fromV1,
  setValidSelectedFile,
  setAdditionalImages
}) {
  const formDataLen = Array.from(formData.keys()).length;
  if (formDataLen < 3) {
    if (values.storyAssistSave) {
      ActionProps.resetForm({ values: defaultStoryState });
      setValidSelectedFile([]);
      setAdditionalImages([]);
      const url = "/stories/add";
      history.replace(`${url}/${ref}`);
    }
    return;
  }
  const url = FF_HandleDerivedImagesAtBE ? API_URLS.autoSavetoryV2 : API_URLS.autoSavetory;
  dispatch(actionCreator(CONSTANTS.POSTSTORY.REQUEST));
  callApi(PUT, url, formData, false, staticHeader)
    .then((res) => {
      const payload = {
        existImages: existImages ?? [],
        existAdditionalImages: existAdditionalImages ?? []
      };
      setSubmitting(false);
      ownStoryUpdateRedux({
        dispatch,
        storyId: formData.get("storyId"),
        storyExternalImages,
        values,
        files,
        additionalImages,
        editMode: true
      });
      dispatch(actionCreator(CONSTANTS.POSTSTORY.SUCCESS, res.data));
      dispatch(actionCreator(CONSTANTS.UPDATESTORY.SUCCESS, payload));
      if (values.terminateEvent) {
        draftAction({
          data: formData,
          dispatch,
          viewTree,
          history,
          ref,
          addMessage,
          terminateEvent: true
        });
      } else if (values.storyAssistSave) {
        ActionProps.resetForm({ values: defaultStoryState });
        setValidSelectedFile([]);
        setAdditionalImages([]);
        history.replace(`/stories/add/${ref}`);
      } else {
        dispatch({ type: SAVEDRAFTPREVIEWSTORE, payload: { ...values, storyImages: files } });
      }
      if (viewTree) {
        history.replace(viewTree);
      }
    })
    .catch((err) => {
      setSubmitting(false);
      dispatch(actionCreator(CONSTANTS.SAVETOTREE.FAILURE, err?.response?.data));
    });
}
export const editStory = (
  formData,
  history,
  treeId,
  personId,
  setSubmitting,
  {
    ref,
    callback,
    ActionProps,
    values,
    groupName,
    viewTree,
    storyExternalImages,
    petId,
    files,
    additionalImages,
    FF_HandleDerivedImagesAtBE,
    fromV1,
    setValidSelectedFile,
    setAdditionalImages
  },
  existImages,
  existAdditionalImages
) => {
  return (dispatch, getState) => {
    const staticHeader = createMultipartHeader();
    if (!values.storyAssistSave) ActionProps.resetForm({ values });
    setSubmitting(true);
    if (values.status === "Draft") {
      autoSaveApiCall({
        getState,
        dispatch,
        formData,
        staticHeader,
        ref,
        files,
        additionalImages,
        storyExternalImages,
        FF_HandleDerivedImagesAtBE,
        setSubmitting,
        history,
        viewTree,
        values,
        existImages,
        existAdditionalImages,
        ActionProps,
        fromV1,
        setValidSelectedFile,
        setAdditionalImages
      });
    } else {
      editStoryApiCall({
        dispatch,
        getState,
        formData,
        staticHeader,
        setSubmitting,
        treeId,
        personId,
        ref,
        callback,
        values,
        history,
        files,
        additionalImages,
        storyExternalImages,
        viewTree,
        groupName,
        FF_HandleDerivedImagesAtBE,
        petId
      });
    }
  };
};
const getCta = (data, mediaId, history) => {
  if (mediaId) {
    return {
      cta: {
        action: () => history.push(`/stories/view/0/${data.get("storyId")}?mediaId=${mediaId}`),
        text: "View"
      }
    };
  }
  return null;
};
const getValue = (data, mediaId, history) => {
  if (mediaId) {
    return {
      cta: { action: () => history.push(`/stories/view/1/${data.get("storyId")}`), text: "View" }
    };
  }
  return null;
};
const addStorySuccess = ({
  dispatch,
  res,
  history,
  textVal,
  personId,
  treeId,
  ref,
  mediaId,
  callback,
  data,
  petId
}) => {
  setTimeout(() => {
    dispatch(actionCreator(CONSTANTS.POSTSTORY.SUCCESS, res.data));
    dispatch({ type: CLEARHOMEPAGESTORIES });
    dispatch({ type: CLEARGROUPPAGESTORIES });
    dispatch(addMessage("Story has been created successfully", "success", textVal));
    if (ref !== "1" && treeId && personId) {
      let urlCal = `/family/person-page/stories/${treeId}/${personId}`;
      history.push(urlCal);
    } else {
      if (petId) {
        history.replace(`/family/pet-page/stories/${BLANKID}/${petId}`);
      } else if (ref === "1") {
        history.push("/stories");
      } else if (ref === "4" && mediaId) {
        history.push(`/media/view-image/${mediaId}`);
        // For Redirection to Story Page when going back from Media Viewer
        dispatch({
          type: FEATURED_STORY_ID_REDIRECT,
          payload: data.get("storyId")
        });
      } else if (ref === "6" && mediaId) {
        history.replace(`/media/view-image/${mediaId}/newspaper`);
        // For Redirection to Story Page when going back from Media Viewer
        dispatch({
          type: FEATURED_STORY_ID_REDIRECT,
          payload: data.get("storyId")
        });
      } else if (ref === 5) {
        callback();
      } else {
        history.push("/");
      }
    }
  }, 1500);
};

export const addStory = ({
  data,
  history,
  props,
  treeId,
  groupName,
  personId,
  submit,
  ref,
  mediaId,
  callback,
  viewTree,
  storiesCount,
  petId,
  values,
  existImages,
  existAdditionalImages,
  files,
  additionalImages,
  draftEditRedirection,
  recordId,
  FF_HandleDerivedImagesAtBE,
  fromV1,
  setValidSelectedFile,
  setAdditionalImages
}) => {
  return (dispatch, getState) => {
    let textVal =
      ref === 5
        ? getValue(data, data.get("storyId"), history)
        : getCta(data, data.get("storyId"), history);
    const url = FF_HandleDerivedImagesAtBE ? API_URLS.saveStoryApiV2 : API_URLS.saveStoryApi,
      staticHeader = createMultipartHeader();
    submit(true);
    dispatch(actionCreator(CONSTANTS.POSTSTORY.REQUEST));
    callApi(POST, url, data, false, staticHeader)
      .then((res) => {
        submit(false);
        const allValues = { ...values, status: data.get("status") };
        ownStoryUpdateRedux({
          dispatch,
          files,
          additionalImages,
          storyId: data.get("storyId"),
          values: allValues
        });
        if (data.get("status") === "Draft") {
          draftEditRedirection.current = true;
          if (values.storyAssistSave) {
            props.resetForm();
            setValidSelectedFile([]);
            setAdditionalImages([]);
          }
          draftAction({
            data,
            dispatch,
            viewTree,
            history,
            ref,
            storyAssistSave: values.storyAssistSave,
            addMessage,
            terminateEvent: values.terminateEvent,
            treeId,
            groupName,
            personId,
            petId,
            recordId,
            fromV1
          });
          const payload = {
            existImages: existImages ?? [],
            existAdditionalImages: existAdditionalImages ?? []
          };
          dispatch(actionCreator(CONSTANTS.UPDATESTORY.SUCCESS, payload));
          if (!values.terminateEvent) {
            dispatch({ type: SAVEDRAFTPREVIEWSTORE, payload: { ...values, storyImages: files } });
          }
        } else {
          addStorySuccess({
            dispatch,
            res,
            history,
            textVal,
            personId,
            treeId,
            ref,
            mediaId,
            callback,
            data,
            petId
          });
        }
        if (storiesCount === 0) {
          dispatch(dismissTellAStoryPrompt());
        }
      })
      .catch((err) => {
        props.setStatus(undefined);
        submit(false);
        dispatch(actionCreator(CONSTANTS.POSTSTORY.FAILURE));
        dispatch(addMessage("Sorry, your story couldn't be saved. Please try again.", "error"));
      });
  };
};
export const deleteStoryPerson = (item, storyId, personDetail, removeIndex) => {
  return (dispatch, getState) => {
    let NewPrimaryPersonId = removeIndex === 0 ? personDetail[1].id : personDetail[0].id;
    const data = { storyId: storyId, personId: item.id, treeId: item.treeId, NewPrimaryPersonId };
    let url = API_URLS.deleteStoryPerson,
      staticHeader = createHeader();
    dispatch(actionCreator(CONSTANTS.DELETESTORYPERSON.REQUEST));
    callApi("PUT", url, data, false, staticHeader)
      .then((_res) => {
        dispatch(actionCreator(CONSTANTS.DELETESTORYPERSON.SUCCESS, item));
        dispatch(addMessage("Person has been removed successfully in this story"));
      })
      .catch((err) => {
        dispatch(actionCreator(CONSTANTS.DELETESTORYPERSON.FAILURE, err.response.data));
        dispatch(addMessage("something went wrong", "error"));
      });
  };
};

export const resetViewData = () => {
  return (dispatch) => {
    dispatch(actionCreator(CONSTANTS.VIEWSTORY.RESET));
  };
};

export const viewStory = (data, isView = true) => {
  return (dispatch, getState) => {
    let url = API_URLS.viewStory(data),
      staticHeader = createHeader();
    dispatch(actionCreator(CONSTANTS.VIEWSTORY.REQUEST));
    return callApi("GET", url, data, false, staticHeader)
      .then(async (res) => {
        if (isView) {
          let userUrl = API_URLS.getMemberInfo(res?.data?.authorId),
            folloInfoUrl = `${API_URLS.getFollowUnfollowDetail}/${res?.data?.authorId}`,
            followbool = await callApi("GET", folloInfoUrl, {}, false, staticHeader),
            authorData = await callApi("GET", userUrl, {}, false, staticHeader);
          res.data.author = authorData?.data;
          res.data.author.isFollow = followbool?.data;
          res.data.groupData = await getGroupsByGroupId(res.data.groupIds, getState, staticHeader);
        }
        const additionalStoryImages = res.data?.storyImages?.map((image) => ({
          ...image,
          mediaType: MediaTypes.IMAGE
        }));
        const additionalStoryAudios = res.data?.storyAudio?.map((image) => ({
          ...image,
          mediaType: MediaTypes.AUDIO
        }));
        const additionalStoryPdfs = res.data?.storyPdfs?.map((image) => ({
          ...image,
          mediaType: MediaTypes.PDF
        }));
        dispatch(
          actionCreator(CONSTANTS.VIEWSTORY.SUCCESS, {
            ...res.data,
            existImages: res.data?.storyImages,
            existAdditionalImages: [
              ...additionalStoryImages,
              ...additionalStoryAudios,
              ...additionalStoryPdfs
            ]
          })
        );
        return res.data;
      })
      .catch((err) => {
        dispatch(actionCreator(CONSTANTS.VIEWSTORY.FAILURE, err?.response));
      });
  };
};

export const emptyViewState = () => {
  return (dispatch) => {
    dispatch(actionCreator(CONSTANTS.VIEWSTORYEMPTY));
  };
};

export const deleteStory = (data, params) => {
  return (dispatch, getState) => {
    let url = API_URLS.deleteStory(data.storyId),
      staticHeader = createHeader();
    dispatch(actionCreator(CONSTANTS.DELETESTORY.REQUEST));
    callApi("DELETE", url, {}, false, staticHeader)
      .then((_res) => {
        setTimeout(() => {
          dispatch({ type: DELETEINDIVDUALOWNSTORY, payload: data });
          dispatch(actionCreator(CONSTANTS.DELETESTORY.SUCCESS, data));
          dispatch(actionCreator(CONSTANTS.DELETESTORYID.SUCCESS, data.storyId));
          dispatch(addMessage(data.tostMsg || "Story deleted successfully"));
          if (params.handleAssist) {
            params.handleAssist();
            const url = params.enabledNewStoryLayout ? "/stories/v1/add" : "/stories/add";
            params.history.replace(`${url}/${params.refType}`);
            return;
          }
          if (params.refType === "feed") {
            params.history.push(`/groups/${params.groupName}/feed`);
          } else if (params.refType !== "1" && params.treeId && params.primaryPersonId) {
            params.history.push(
              `/family/person-page/stories/${params.treeId}/${params.primaryPersonId}`
            );
          } else {
            params.history.push(
              params.refType === "1"
                ? `/my-stories${params.previousSearch ? `${params.previousSearch}` : ""}`
                : `/`
            );
          }
        }, 100);
      })
      .catch((err) => {
        dispatch(actionCreator(CONSTANTS.DELETESTORY.FAILURE, err.response));
        dispatch(
          addMessage(
            "We are unable to delete this story at this time, please try again later.",
            "error"
          )
        );
      });
  };
};

export const getLeftPanelDetails = (data) => {
  let requestData = { ...data, authorId: data?.authorId };
  return (dispatch) => {
    let url = API_URLS.getLeftPanelDetailsOwner(),
      staticHeader = createHeader();
    if (requestData.treeId && requestData.personId) {
      url = API_URLS.getLeftPanelDetails(requestData);
    } else if (requestData.memberId) {
      url = API_URLS.getLeftPanelDetailsMember(requestData);
    } else if (requestData.groupId) {
      url = requestData.personId
        ? API_URLS.getLeftPanelDetailsGroupPerson(requestData)
        : API_URLS.getLeftPanelDetailsGroup(requestData);
    } else if (data?.petId) {
      url = API_URLS.getPetStoriesOnPetsPage(data.petId);
    }

    dispatch(actionCreator(CONSTANTS.GETLEFTPANELDETAILS.REQUEST));

    setTimeout(
      () =>
        callApi("GET", url, requestData, false, staticHeader, false, null, false)
          .then((res) => {
            if (res.status === 500) {
              const data = {
                AllStories: 0
              };
              dispatch(actionCreator(CONSTANTS.GETLEFTPANELDETAILS.SUCCESS, data || {}));
            } else {
              dispatch(
                actionCreator(CONSTANTS.GETLEFTPANELDETAILS.SUCCESS, res.data?.resultData || {})
              );
            }
          })
          .catch((_err) => {
            dispatch(actionCreator(CONSTANTS.GETLEFTPANELDETAILS.FAILURE, {}));
          }),
      1000
    );
  };
};

export const getImmediateFamily = (data) => {
  let requestData = { ...data, authorId: getOwner() };
  return (dispatch) => {
    let url = API_URLS.getImmediateFamily(requestData),
      staticHeader = createHeader();
    dispatch(actionCreator(CONSTANTS.GETRIGHTPANELDETAILS.REQUEST));
    dispatch(actionCreator(CONSTANTS.SPOUSESWITHCHILDREN.REQUEST));
    setTimeout(
      () =>
        callApi("GET", url, requestData, false, staticHeader, undefined, undefined, false)
          .then((res) => {
            if(res.status === 500){
              dispatch(actionCreator(CONSTANTS.GETRIGHTPANELDETAILS.SUCCESS, {}));
              dispatch(actionCreator(CONSTANTS.SPOUSESWITHCHILDREN.SUCCESS, {}));
              return;
            }
            dispatch(actionCreator(CONSTANTS.GETRIGHTPANELDETAILS.SUCCESS, res.data || {}));
            dispatch(actionCreator(CONSTANTS.SPOUSESWITHCHILDREN.SUCCESS, res.data || {}));
          })
          .catch((_err) => {
            dispatch(actionCreator(CONSTANTS.GETRIGHTPANELDETAILS.FAILURE));
            dispatch(actionCreator(CONSTANTS.SPOUSESWITHCHILDREN.FAILURE));
          }),
      1000
    );
  };
};

export const publishedTitleByGUID = async (publicationTitleId) => {
  if (!publicationTitleId) {
    return Promise.resolve("");
  }
  let url = API_URLS.GetPublishedTitleByGUID(publicationTitleId);
  return fetch(url)
    .then((response) => {
      return response.json().then(function (text) {
        return text ? text.PublicationTitle : "";
      });
    })
    .catch((err) => {
      console.log("err", err.message);
    });
};

export const updateViewStoryIsLiked = (storyId, isLiked) => {
  const requestData = { storyId: storyId, isLiked: isLiked };
  return (dispatch, getState) => {
    let url = API_URLS.updateStoryIsLiked(requestData),
      staticHeader = createHeader();
    dispatch(actionCreator(CONSTANTS.UPDATEVIEWSTORYISLIKED.REQUEST));
    callApi("POST", url, {}, false, staticHeader)
      .then((_res) => {
        dispatch(actionCreator(CONSTANTS.UPDATEVIEWSTORYISLIKED.SUCCESS, requestData));
      })
      .catch((err) => {
        dispatch(addMessage("Sorry, your story couldn't be updated. Please try again.", "error"));
        return err?.response?.data;
      });
  };
};

export const shareStoryViaEmail = (storyId, emails, formik, setShowModal) => {
  let formData = new FormData();
  formData.append("StoryId", storyId);
  emails.forEach((email) => {
    formData.append("EmailIds", email);
  });
  return (dispatch, getState) => {
    let url = API_URLS.shareStoriesViaEmail,
      staticHeader = createMultipartHeader();
    callApi("POST", url, formData, false, staticHeader)
      .then((res) => {
        if (res.status === 200) {
          dispatch(addMessage("Email has been sent"));
          setShowModal(false);
        }
        formik.setSubmitting(false);
      })
      .catch((err) => {
        dispatch(addMessage("Sorry, your story couldn't be shared. Please try again.", "error"));
        formik.setSubmitting(false);
        return err?.response?.data;
      });
  };
};

export const getSharedStoryIDUsingInvitationID = (invitationId, visitorId) => {
  return (dispatch, getState) => {
    let url = API_URLS.shareStoryViewStatus(invitationId, visitorId),
      staticHeader = createHeader();
    callApi("GET", url, {}, false, staticHeader)
      .then((res) => {
        if (res.status === 200) {
          dispatch(actionCreator(CONSTANTS.SHARESTORYSTATUS.SUCCESS, res.data));
        } else {
          dispatch(actionCreator(CONSTANTS.SHARESTORYSTATUS.FAILURE));
        }
      })
      .catch((_err) => {
        dispatch(addMessage("Something went wrong!", "error"));
      });
  };
};

export const addPreviewerAPI = (formData) => {
  return (dispatch, getState) => {
    let url = API_URLS.addToStoryPreviewers,
      staticHeader = createHeader();
    return callApi("PUT", url, formData, false, staticHeader)
      .then((res) => {
        return res.status === 200;
      })
      .catch((_err) => {
        dispatch(addMessage("Sorry, your story couldn't be updated. Please try again.", "error"));
        return false;
      });
  };
};

export const previewStory = (data, view = true) => {
  return (dispatch, getState) => {
    let url = API_URLS.previewStoryDetails(data),
      staticHeader = createHeader();
    dispatch(actionCreator(CONSTANTS.VIEWSTORY.REQUEST));
    return callApi("GET", url, data, false, staticHeader)
      .then(async (res) => {
        if (view) {
          let userUrl = API_URLS.nonLoginUserDetails(res?.data?.authorId),
            authorData = await callApi("GET", userUrl, {}, false, staticHeader);
          res.data.author = authorData?.data;
        }
        if (res.data?.layoutId !== BLANKID && res.data?.storyImages?.length) {
          res.data.layoutId = BLANKID;
          res.data.storyImages = res.data.storyImages.map((image) => ({
            ...image,
            url: image.originatingMediaURL
          }));
        }
        dispatch(actionCreator(CONSTANTS.VIEWSTORY.SUCCESS, res.data));
        return res.data;
      })
      .catch((err) => {
        dispatch(actionCreator(CONSTANTS.VIEWSTORY.FAILURE, err?.response));
      });
  };
};

export const userAssociationToStory = (storyID) => {
  return (dispatch, getState) => {
    let url = API_URLS.assignUserToStory(storyID),
      staticHeader = createHeader();
    return callApi("POST", url, storyID, false, staticHeader)
      .then((res) => {
        return res.status === 200;
      })
      .catch((_err) => {
        dispatch(
          addMessage("Sorry, your story couldn't be associated. Please try again.", "error")
        );
        return false;
      });
  };
};

export const addEmailsToWhiteList = (emails) => {
  let formData = new FormData();
  formData.append("emails", emails);
  return (dispatch, getState) => {
    let url = API_URLS.addEmailsToWhiteList,
      staticHeader = createMultipartHeader();
    return callApi("PUT", url, formData, false, staticHeader)
      .then((res) => {
        return res.status === 200;
      })
      .catch(() => {
        dispatch(addMessage("Something went wrong", "error"));
      });
  };
};

export const checkVisitorStoryPermissionAPI = (formData) => {
  return (dispatch, getState) => {
    let url = API_URLS.checkVisitorStoryPermission(formData.visitorID, formData.storyID),
      staticHeader = createMultipartHeader();
    return callApi("GET", url, formData, false, staticHeader)
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          return false;
        }
      })
      .catch(() => {
        dispatch(addMessage("Something went wrong", "error"));
      });
  };
};

export const checkUserAssociationAPI = (storyId) => {
  return (dispatch, getState) => {
    let url = API_URLS.checkUserAssociation(storyId),
      staticHeader = createMultipartHeader();
    return callApi("GET", url, {}, false, staticHeader)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        } else {
          return false;
        }
      })
      .catch(() => {
        dispatch(addMessage("Something went wrong", "error"));
      });
  };
};

export const viewStoryViaInvitationAPI = (storyId, isView = true, previewShareable = false) => {
  return (dispatch, getState) => {
    let url = API_URLS.viewStoryViaInvitation(storyId),
      staticHeader = createMultipartHeader();
    dispatch(actionCreator(CONSTANTS.VIEWSTORY.REQUEST));
    return callApi("GET", url, {}, false, staticHeader)
      .then(async (response) => {
        if (isView) {
          let authorUrl = API_URLS.getMemberInfo(response?.data?.authorId),
            followInfoUrl = `${API_URLS.getFollowUnfollowDetail}/${response?.data?.authorId}`,
            followboolData = await callApi("GET", followInfoUrl, {}, false, staticHeader),
            authorDetails = await callApi("GET", authorUrl, {}, false, staticHeader);
          response.data.author = authorDetails?.data;
          response.data.author.isFollow = followboolData?.data;
          response.data.groupData = await getGroupsByGroupId(
            response.data.groupIds,
            getState,
            staticHeader
          );
        }
        if (previewShareable) {
          const followStoryAuthor = API_URLS.followUser(response?.data?.authorId);
          const followStoryAuthorRes = await callApi(
            "POST",
            followStoryAuthor,
            {},
            false,
            staticHeader
          );
          if (followStoryAuthorRes.status === 200) {
            removeLoggedInViaShareableID();
          }
        }
        if (response.data.layoutId !== BLANKID && response.data.storyImages?.length) {
          response.data.storyImages = response.data.storyImages.map((image) => ({
            ...image,
            url: image.originatingMediaURL
          }));
        }
        if (response.data.layoutId === BLANKID && response.data.storyImages?.length) {
          response.data.layoutId =
            response.data.storyImages?.length > 1 ? LAYOUT_ID.TWO_IMAGE : LAYOUT_ID.FIT;
        }
        dispatch(actionCreator(CONSTANTS.VIEWSTORY.SUCCESS, response.data));
        return response;
      })
      .catch((error) => {
        dispatch(actionCreator(CONSTANTS.VIEWSTORY.FAILURE, error?.response));
      });
  };
};

export const addStoryCitation = (payload) => {
  return (dispatch, getState) => {
    dispatch({ type: CONSTANTS.ADD_CITATION.REQUEST });
    let url = API_URLS.AddStoryCitationApi(),
      staticHeader = createHeader();
    callApi("POST", url, payload, false, staticHeader)
      .then(() => {
        dispatch({ type: CONSTANTS.ADD_CITATION.SUCCESS });
        dispatch(addMessage("Story citation added successfully.", "success"));
      })
      .catch(() => {
        dispatch({ type: CONSTANTS.ADD_CITATION.FAILURE });
        dispatch(addMessage("Sorry, Something went wrong.", "error"));
      });
  };
};

export const getStoryIDViaSharableLinkID = (shareableID) => {
  return (dispatch, getState) => {
    const url = API_URLS.getStoryViaShareableLink(shareableID),
      staticHeader = createHeader();
    callApi("GET", url, {}, false, staticHeader)
      .then((res) => {
        if (res && res.status === 200) {
          dispatch(actionCreator(CONSTANTS.SHAREABLE_STORY.SUCCESS, res.data));
        } else {
          dispatch(actionCreator(CONSTANTS.SHAREABLE_STORY.FAILURE));
        }
      })
      .catch((_err) => {
        dispatch(addMessage("Something went wrong!", "error"));
      });
  };
};

export const uploadStoryMediaAction = (payload) => {
  const url = API_URLS.UPLOADSTORYMEDIAURL;
  return apiRequest(POST, url, payload);
};
