import { lazy, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
  useHistory,
  useLocation
} from "react-router-dom";

// services
import {
  getRedirectToAccountLinking,
  getRedirectToAccounts,
  getRedirectToRegister
} from "services";

// components
import Layout from "components/Layout";

// pages
import Marketing from "pages/Marketing";
import BillingUpdate from "pages/NewGroup/BillingUpdate";
import PaymentRoute from "pages/PaymentRoute";

//Stories Route V1
import { StoriesRoutesv1 } from "./routes/storiesRoutesV1";

// routes
import PrintBookRoute from "pages/Routes/PrintBookRoute";
import ShareStoryRoutes from "pages/Routes/ShareStoryRoutes";
import ShareableAfterAuth from "pages/ShareStory/ShareableStory/ShareableAfterAuth";
import StorySharePreview from "pages/ShareStory/ShareableStory/StorySharePreview";

// Search pages

// Utils
import { useQueryClient } from "@tanstack/react-query";
import { useFeatureFlags } from "services/featureFlag";
import { fetchAllUsersTypeahead } from "utils";
import NextjsLocationListener from "./NextjsPathListener";
import { getSearchRoutes } from "./routes/searchRoutes";

const HintsPage = lazy(() => import("pages/Hints/PersonHintsPage"));
const OfferPage = lazy(() => import("pages/OfferPage"));
const AnnualOnly = lazy(() => import("pages/Payment/AnnualOnly"));
const Ultimate = lazy(() => import("pages/Payment/Ultimate"));

const ErrorBoundaryComponent = lazy(() => import("components/ErrorBoundaryComponent"));
const Records = lazy(() => import("components/ImageViewer/Records"));
const SearchStoriedForm = lazy(() => import("components/Header/components/SearchStoriedForm"));
const ImportFamilySearchTree = lazy(() => import("components/ImportFamilySearchTree"));
const ImportingFamilySearchTree = lazy(() => import("pages/ImportingFamilyTreePage/index"));

// pages
const HomePage = lazy(() => import("pages/HomePage"));
const NewspaperSearch = lazy(() => import("pages/SearchPage/NewspaperSearch"));
const FamilyPage = lazy(() => import(/* webpackChunkName: "familypage" */ "pages/PedigreeView"));
const PersonViewPage = lazy(
  () => import(/* webpackChunkName: "personviewpage" */ "pages/PersonViewPage")
);
const LocationPage = lazy(() => import("pages/LocationPage"));
const HintInspector = lazy(() => import("pages/HintInspector"));
const SettingsPage = lazy(() => import("pages/SettingsPage"));
const PlanPage = lazy(() => import("pages/SettingsPage/PlanPage"));
const NewspapperRecords = lazy(() => import("pages/SearchPage/NewspapperRecords"));
const NewspapperSearchBrowse = lazy(() => import("pages/SearchPage/NewspapperSearchBrowse"));
const ViewNews = lazy(() => import("pages/SearchPage/ViewNews"));
// const AddStories = lazy(() => import("pages/PersonViewPage/stories/addStories"));
// const ViewStories = lazy(() => import("pages/PersonViewPage/stories/ViewStories"));
const AddStories = lazy(() => import("pages/StoriesLayoutV1/AddStoriesV1"));
const ViewStories = lazy(() => import("pages/StoriesLayoutV1/ViewStoryV1"));
const MediaItemViewer = lazy(() => import("pages/MediaItemViewer"));
const AboutPage = lazy(() => import("pages/AboutPage/AboutPage"));
const NotFound = lazy(() => import("pages/NotFound"));

const GroupInvitationPage = lazy(() => import("pages/NewGroup/InvitationPage"));
const GroupProcess = lazy(() => import("pages/GroupPage/GroupProcess"));
const ViewInvite = lazy(() => import("pages/GroupPage/Invite/ViewInvite"));
const ViewGroups = lazy(() => import("pages/GroupPage/ViewGroups"));

// Clues
const Clues = lazy(() => import("pages/Clues"));
const AboutGroup = lazy(() => import("pages/AboutGroup"));
const NewGroup = lazy(() => import("pages/NewGroup"));

const PersonProfile = lazy(() => import("pages/PersonProfile"));

// Footer Pages
const Privacy = lazy(() => import("pages/Privacy"));
const Terms = lazy(() => import("pages/Terms"));
const Dpa = lazy(() => import("pages/Dpa"));
const Ccpa = lazy(() => import("pages/Ccpa"));

const Stories = lazy(() => import("pages/Stories"));
const Gallery = lazy(() => import("pages/Media"));
const Notifications = lazy(() => import("pages/Notifications"));
const PaymentPage = lazy(() => import("pages/Payment"));
const FreeTrialsPage = lazy(() => import("pages/FreeTrials"));
const freeTrialLO = lazy(() => import("pages/LoggedOutFreeTrials"));

const AdminTopicPage = lazy(() => import("pages/Admin/TopicPage"));
const AdminHomePage = lazy(() => import("pages/Admin/HomePage"));
const AdminReportPage = lazy(() => import("pages/Admin/ReportPage"));
const ResourcesPage = lazy(() => import("pages/Resources"));
const RegistrationPage = lazy(() => import("pages/Registration"));
const AccountLinkingPage = lazy(() => import("pages/AccountLinking"));
const AccountPage = lazy(() => import("pages/Accounts"));
const ErrorPage = lazy(() => import("pages/ErrorPage"));

// Share Story
const StoryPreview = lazy(() => import("pages/ShareStory/StoryPreview"));
const VerifyStoryPermission = lazy(() => import("pages/ShareStory/VerifyStoryPermission"));

//compare records

const compareRecords = lazy(() => import("pages/compareRecord"));

//books route

const Books = lazy(() => import("pages/Books"));
const AddEditStoryBook = lazy(() => import("pages/Books/AddEditStoryBook"));
const ViewStoryBook = lazy(() => import("pages/Books/ViewStoriedBook"));
const OrderStoryBook = lazy(() => import("pages/Books/ReorderStoriedbook/OrderStoriedBook"));

///AB middleware
const abMiddlewarePlan = lazy(() => import("pages/AbMiddleware/planRedirect"));

// StoriedBook Upgrade Flows
const NAConversionPage = lazy(() => import("pages/StoriedBookUpgradeFlows/NAConversionPage"));
const RegularUsers = lazy(() => import("pages/StoriedBookUpgradeFlows/RegularUsers"));
const PlusNonAnnualUsers = lazy(() => import("pages/StoriedBookUpgradeFlows/PlusNonAnnualUsers"));
const UltimateNonAnnualUsers = lazy(
  () => import("pages/StoriedBookUpgradeFlows/UltimateNonAnnualUsers")
);

// QR Code generator
const QRCodePage = lazy(() => import("pages/QRCode"));

// Public Redirector
const PublicRedirector = lazy(() => import("pages/PublicRedirector"));
// Recipes

const ScrollToTop = () => {
  const { pathname } = useLocation();
  const scrollCord = useSelector((state) => state.homepage.scrollCord);
  useEffect(() => {
    if (!(pathname === "/" && scrollCord.y > 0)) {
      window.scrollTo(0, 0);
    }
  }, [pathname, scrollCord]);

  return null;
};
const ProtectedRoutes = ({ appErrorState, appError, clearServerErrorState }) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { enabled: FF_Book } = useFeatureFlags("FF_Book");
  const [errorState, setErrorState] = useState(appErrorState);
  const redirectToRegister = getRedirectToRegister();
  const redirectToAccountLinking = getRedirectToAccountLinking();
  const redirectToAccounts = getRedirectToAccounts();
  const { allUserTrees } = useSelector((state) => state.user);
  const noTree = allUserTrees?.length === 0;
  const { pathname } = useLocation();

  const clearAppErrorState = () => {
    setErrorState(false);
    clearServerErrorState();
  };

  useEffect(() => {
    queryClient.prefetchQuery({
      queryKey: ["allUsersTypeahead"],
      queryFn: fetchAllUsersTypeahead
    });
  }, [queryClient]);

  useEffect(() => {
    if (redirectToAccountLinking) {
      history.push("/account-linking");
    }
  }, [history, redirectToAccountLinking]);

  useEffect(() => {
    if (redirectToAccounts) {
      history.push("/accounts");
    }
  }, [history, redirectToAccounts]);

  useEffect(() => {
    if (redirectToRegister && !redirectToAccountLinking && !redirectToAccounts) {
      history.push("/registration");
    }
  }, [history, redirectToAccountLinking, redirectToAccounts, redirectToRegister]);

  useEffect(() => {
    if (noTree && pathname === "/family/pedigree-view") {
      history.push("/family");
    }
  }, [history, noTree, pathname]);

  return (
    <Router history={history}>
      <BillingUpdate />
      <NextjsLocationListener />
      <Layout clearAppErrorState={clearAppErrorState}>
        <ScrollToTop />

        <Switch>
          {(errorState || appError) && (
            <Route
              render={() => (
                <ErrorBoundaryComponent appError={appError} appErrorState={appErrorState} />
              )}
            />
          )}
          <Route exact path="/" component={HomePage} />
          <Route exact path="/home" component={HomePage} />

          <Route exact path="/should-not-access" component={ErrorPage} />

          <Redirect from="/recipes" to="/" />
          <Redirect from="/getstarted" to="/" />

          <Route exact path="/registration/step1" component={RegistrationPage} />
          <Route exact path="/registration/:steps" component={RegistrationPage} />
          <Route exact path="/account-linking" component={AccountLinkingPage} />
          <Route exact path="/accounts" component={AccountPage} />
          <Route exact path="/family" component={FamilyPage} />
          <Route exact path="/family/:addPerson" component={FamilyPage} />
          <Route exact path="/settings" component={SettingsPage} />
          <Route exact path="/settings/:getTab" component={SettingsPage} />
          <Route exact path="/import-family-search-tree" component={ImportFamilySearchTree} />
          <Route exact path="/import-family-search-tree/:code" component={ImportFamilySearchTree} />
          <Route exact path="/importing-family-search-tree" component={ImportingFamilySearchTree} />
          <Redirect from="/plans" to="/settings/billing/manage" />
          <Route exact path="/settings/billing/manage" component={PlanPage} />
          <Route path="/hints/:treeId" component={HintsPage} />

          {/********** Scrapbook Media Item Viewer Routes **********/}
          {/* View Single Media Item */}
          <Route exact path="/media/view-image/:mediaId" component={MediaItemViewer} />
          {/* User Media Item Viewer */}
          <Route exact path="/my-scrapbook/media/:mediaId" component={MediaItemViewer} />
          {/* Group Media Item Viewer */}
          <Route
            exact
            path="/groups/storied/scrapbook/media/:groupId/:groupTreeId/:mediaId"
            component={MediaItemViewer}
          />
          {/* Person Media Item Viewer */}
          <Route
            exact
            path="/family/person-page/:queryTab/media/:personId/:mediaId"
            component={MediaItemViewer}
          />
          {/* Pet Media Item Viewer */}
          <Route
            exact
            path="/family/pet-page/:queryTab/media/:petId/:mediaId"
            component={MediaItemViewer}
          />

          <Route exact path="/family/pedigree-view/:action" component={FamilyPage} />
          <Route exact path="/family/info/:action" component={FamilyPage} />
          <Route
            exact
            path="/family/pedigree-view/:treeId/:primaryPersonId/:level"
            component={FamilyPage}
          />
          <Route
            exact
            path="/family/pedigree-view/:treeId/:primaryPersonId/:level/:addPerson"
            component={FamilyPage}
          />
          <Route
            exact
            path="/family/person-page/:queryTab/:treeId/:primaryPersonId"
            component={PersonViewPage}
          />
          <Route
            exact
            path="/family/pet-page/:queryTab/:treeId/:petId"
            component={PersonViewPage}
          />
          <Route
            exact
            path="/family/person-page/:queryTab/:subQueryTab/:treeId/:primaryPersonId"
            component={PersonViewPage}
          />
          {getSearchRoutes().map((route) => (
            <Route key={route.path} exact path={route.path} component={route.component} />
          ))}
          <Route exact path="/test" component={LocationPage} />
          <Route exact path="/inspect/hints" component={HintInspector} />
          <Route exact path="/records/:recordId/:partitionKey" component={Records} />
          <Route exact path="/storiedbook/view/:bookId" component={ViewStoryBook} />
          <Route exact path="/storiedbook/order/:bookId" component={OrderStoryBook} />
          <Route exact path="/stories/add" component={AddStories} />
          <Route exact path="/stories/add/:refType/pet/:petId" component={AddStories} />
          <Route exact path="/stories/add/:refType/group/:groupName" component={AddStories} />
          <Route
            exact
            path="/stories/add/:refType/:treeId/:primaryPersonId"
            component={AddStories}
          />
          <Route
            exact
            path="/stories/view/:refType/:storyId/group/:groupName"
            component={ViewStories}
          />
          <Route
            exact
            path="/stories/view/:refType/:storyId/:treeId/:primaryPersonId"
            component={ViewStories}
          />
          <Route
            exact
            path="/stories/edit/:refType/:storyId/group/:groupName"
            component={AddStories}
          />
          <Route
            exact
            path="/stories/edit/:refType/:storyId/:treeId/:primaryPersonId"
            component={AddStories}
          />
          <Route exact path="/stories/add/newspaper/:recordId" component={AddStories} />
          <Route exact path="/stories/add/:refType" component={AddStories} />
          <Route exact path="/stories/view/:refType/:storyId" component={ViewStories} />
          <Route exact path="/stories/view/:refType/:storyId?newspaper" component={ViewStories} />
          <Route exact path="/stories/edit/:refType/:storyId" component={AddStories} />
          <Route exact path="/stories/add-from-media/:refType/:mediaId" component={AddStories} />
          <Route
            exact
            path="/stories/add-from-external-media/:refType/:mediaId/:recordId"
            render={(props) => <AddStories newspaper={true} {...props} />}
          />
          {StoriesRoutesv1.map((Info) => (
            <Redirect from={Info.path} to={Info.redirectPath} />
          ))}
          <Redirect from="/stories" to="/my-stories" />
          <Redirect from="/media" to="/my-media" />
          <Route exact path="/my-stories" component={Stories} />
          <Route exact path="/my-scrapbook" component={Gallery} />
          <Route exact path="/stories/:treeId/:primaryPersonId" component={Stories} />
          <Route exact path="/notifications" component={Notifications} />

          <Route exact path="/groups/:groupName" component={NewGroup} />
          <Route exact path="/groups/:groupName/:tab" component={NewGroup} />
          <Route exact path="/groups/:groupName/:tab/:primaryPersonId" component={NewGroup} />
          <Route exact path="/group-invitation/:inviteId" component={GroupInvitationPage} />
          <Route exact path="/group-process" component={GroupProcess} />
          <Route exact path="/view-invite" component={ViewInvite} />
          <Route exact path="/my-groups" component={ViewGroups} />
          <Route exact path="/marketing-process" component={Marketing} />
          <Route exact path="/about" component={AboutPage} />

          {/*  home storied search */}
          <Route exact path="/search-storied" component={SearchStoriedForm} />

          {/*compre record route*/}
          <Route exact path="/merge-record/:recordId/:personId" component={compareRecords} />

          {/**Newspapper link */}
          <Route exact path="/newspaper-search" component={NewspaperSearch} />
          <Redirect from="/lp/newspaper" to="/newspaper-search" />
          <Route exact path="/newspaper-search/result" component={NewspapperRecords} />
          <Route exact path="/newspaper-search/browse" component={NewspapperSearchBrowse} />
          <Route exact path="/newspaper-search/newspaper/:recordId" component={ViewNews} />

          {/** Newspaper location links */}
          <Route exact path="/newspaper-search/location" component={NewspapperSearchBrowse} />
          <Route
            exact
            path="/newspaper-search/location/:countryName"
            component={NewspapperSearchBrowse}
          />
          <Route
            exact
            path="/newspaper-search/location/:countryName/:stateName"
            component={NewspapperSearchBrowse}
          />
          <Route
            exact
            path="/newspaper-search/location/:countryName/:stateName/:cityName"
            component={NewspapperSearchBrowse}
          />
          <Route
            exact
            path="/newspaper-search/location/:countryName/:stateName/:cityName/:publicationName"
            component={NewspapperSearchBrowse}
          />
          {/* Clues */}
          <Route exact path="/clues" component={Clues} />
          <Route exact path="/groups" component={AboutGroup} />
          <Route exact path="/new-group" component={NewGroup} />

          <Route exact path="/person/profile/:primaryPersonId" component={PersonProfile} />

          {/* Footer Pages */}
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/terms" component={Terms} />
          <Route exact path="/dpa" component={Dpa} />
          <Route exact path="/ccpa" component={Ccpa} />
          <ShareStoryRoutes exact path="/story-preview/:storyId" component={StoryPreview} />
          <ShareStoryRoutes
            exact
            path="/verify-story-permission"
            component={VerifyStoryPermission}
          />
          <ShareStoryRoutes exact path="/story-share-auth" component={ShareableAfterAuth} />
          <ShareStoryRoutes exact path="/story-shared/:storyId" component={StorySharePreview} />

          <PaymentRoute exact path="/payment" component={PaymentPage} />
          <PaymentRoute exact path="/payment/:planName" component={PaymentPage} />

          {/* StoriedBook Upgraded Flows */}
          <Route path="/na-conversion" component={NAConversionPage} />
          <Route path="/upgrade/regular" component={RegularUsers} />
          <Route path="/upgrade/plus-non-annual" component={PlusNonAnnualUsers} />
          <Route path="/upgrade/ultimate-non-annual" component={UltimateNonAnnualUsers} />

          {/* Free Trials */}
          <Route path="/freetrials" component={freeTrialLO} />
          <Route path="/plus/v1" component={freeTrialLO} />
          <Route path="/plus/v2" component={freeTrialLO} />
          <PaymentRoute
            exact
            path="/payment/bundle/trial"
            component={OfferPage}
            props={{ isToggle: true }}
          />
          <PaymentRoute
            exact
            path="/payment/bundle/trial/grid"
            component={OfferPage}
            props={{ isToggle: false }}
          />
          <PaymentRoute exact path="/payment/bundle/annual-only" component={AnnualOnly} />
          <PaymentRoute exact path="/payment/bundle/trial/ultimate" component={Ultimate} />

          <PaymentRoute exact path="/payment/bundle/:planName" component={FreeTrialsPage} />
          <PaymentRoute exact path="/payment/bundle/trial/:planName" component={FreeTrialsPage} />

          <PaymentRoute exact path="/paywal" paywal={true} component={PaymentPage} />
          <PaymentRoute exact path="/paywal/:planName" paywal={true} component={PaymentPage} />

          <Route exact path="/admin/topics" component={AdminTopicPage} />
          <Route exact path="/admin/report/:reportType" component={AdminReportPage} />
          {/* Admin Pages */}
          <Route path="/admin" component={AdminHomePage} />
          {/* QR Code */}
          <Route path="/qrcode" component={QRCodePage} />
          {/* Public Redirector */}
          <Route path="/r" component={PublicRedirector} />
          <Route path="/resources" component={ResourcesPage} />
          <Route exact path="/redirector" component={abMiddlewarePlan} />
          {FF_Book && (
            <>
              <PrintBookRoute exact path="/print" component={Books} />
              <PrintBookRoute exact path="/storiedbook/add" component={AddEditStoryBook} />
              <PrintBookRoute
                exact
                path="/storiedbook/edit/:bookId/:step"
                component={AddEditStoryBook}
              />
            </>
          )}
          <Redirect to="/" />
          <Route component={NotFound} />
        </Switch>
      </Layout>
    </Router>
  );
};

export default ProtectedRoutes;
