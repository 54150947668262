/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  TopicData,
  TopicIdData,
  TopicNameData,
  TopicReloadData,
  TopicStoriesCountData,
  TopicStoriesData
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Topic<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Topic
   * @name Topic
   * @request GET:/api/Topic
   * @secure
   */
  topic = (params: RequestParams = {}) =>
    this.request<TopicData, any>({
      path: `/api/Topic`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Topic
   * @name TopicId
   * @request GET:/api/Topic/id/{topicId}
   * @secure
   */
  topicId = (topicId: string, params: RequestParams = {}) =>
    this.request<TopicIdData, any>({
      path: `/api/Topic/id/${topicId}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Topic
   * @name TopicName
   * @request GET:/api/Topic/name/{seoName}
   * @secure
   */
  topicName = (seoName: string, params: RequestParams = {}) =>
    this.request<TopicNameData, any>({
      path: `/api/Topic/name/${seoName}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Topic
   * @name TopicReload
   * @request GET:/api/Topic/reload
   * @secure
   */
  topicReload = (params: RequestParams = {}) =>
    this.request<TopicReloadData, any>({
      path: `/api/Topic/reload`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Topic
   * @name TopicStories
   * @request GET:/api/Topic/{topicId}/stories/{requestId}/{pageNumber}/{pageSize}
   * @secure
   */
  topicStories = (
    topicId: string,
    requestId: string,
    pageNumber: number,
    pageSize: number,
    params: RequestParams = {}
  ) =>
    this.request<TopicStoriesData, any>({
      path: `/api/Topic/${topicId}/stories/${requestId}/${pageNumber}/${pageSize}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Topic
   * @name TopicStoriesCount
   * @request GET:/api/Topic/storiesCount/{requestId}
   * @secure
   */
  topicStoriesCount = (requestId: string, params: RequestParams = {}) =>
    this.request<TopicStoriesCountData, any>({
      path: `/api/Topic/storiesCount/${requestId}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    });
}
