/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  HintInspectorHintableCollectionsData,
  HintInspectorInspectData,
  HintInspectorInspectParams
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class HintInspector<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags HintInspector
   * @name HintInspectorInspect
   * @request GET:/api/HintInspector/inspect
   * @secure
   */
  hintInspectorInspect = (query: HintInspectorInspectParams, params: RequestParams = {}) =>
    this.request<HintInspectorInspectData, any>({
      path: `/api/HintInspector/inspect`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags HintInspector
   * @name HintInspectorHintableCollections
   * @request GET:/api/HintInspector/hintable-collections
   * @secure
   */
  hintInspectorHintableCollections = (params: RequestParams = {}) =>
    this.request<HintInspectorHintableCollectionsData, any>({
      path: `/api/HintInspector/hintable-collections`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    });
}
