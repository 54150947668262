export const DeletedFilterStory = (elem, deleteStoryList) => {
  const storyId = typeof elem === "string" ? elem : elem.storyId;
  return deleteStoryList.includes(storyId) ? false : true;
};
function draftRedirect({ ref, data, groupName, personId, petId, treeId, history, recordId }) {
  const startUrl= '/stories/edit/';
  if (treeId && personId) {
    return history.replace(`${startUrl}${ref}/${data.get("storyId")}/${treeId}/${personId}`);
  }
  if (petId) {
    return history.replace(`${startUrl}${ref}/${data.get("storyId")}?petid=${petId}`);
  }
  if (groupName) {
    return history.replace(`${startUrl}${ref}/${data.get("storyId")}/group/${groupName}`);
  }
  return history.replace(`${startUrl}${ref}/${data.get("storyId")}`, { recordId });
}
export const draftAction = ({
  data,
  dispatch,
  viewTree,
  history,
  ref,
  addMessage,
  terminateEvent,
  storyAssistSave,
  treeId,
  groupName,
  personId,
  petId,
  recordId,
  fromV1
}) => {
  if (terminateEvent||storyAssistSave) {
    dispatch(addMessage("Your story draft has been saved", "success"));
  } else {
    viewTree
      ? history.replace(viewTree)
      : draftRedirect({ ref, data, treeId, groupName, history, personId, petId, recordId,fromV1 });
  }
};
