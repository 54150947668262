import { apiFamilySearchRequest } from "redux/requests/familysearch";
import { setFamilySearchToken } from "services";
import {
  ADD_TREE,
  ASSIGN_ERROR,
  ASSIGN_HOMEPERSON,
  CLEAR_GEDCOM,
  CLOSE_HOMEPERSON_MODAL,
  GEDCOM_ERROR,
  GET,
  GET_ALL_TREES,
  GET_IMPORT_STATUS,
  IMPORT_GEDCOM,
  IMPORT_IN_PROGRESS,
  IMPORT_STATUS_ERROR,
  INVALID_GEDCOM_FILE,
  IS_GEDCOM_UPLOADED,
  PENDING_NOTIFICATION_SNACKBAR,
  POST,
  PROGRESS_BAR,
  RENDER_NEW_TREE,
  REVERT_START_TREE_REQUEST_STATUS,
  SELECT_HOMEPERSON,
  SELECT_HOMEPERSON_ERROR,
  SET_HOMEPERSON,
  SET_START_TREE_REQUEST_STATUS,
  SURNAME_DISTRIBUTION,
  SURNAME_MEANING,
  TREE_ERROR,
  USER_ERROR
} from "../constants";
import { assignPayload, gedcomPayload, startNewTreeWithFocusPerson } from "../helpers";
import { apiRequest } from "../requests";
import { startTreeApiRequest } from "../requests/startTree";
import { addMessage } from "./toastr";

export const getImportStatus = (treeId, selectedHomePerson, onImportFinish) => async (dispatch) => {
  try {
    const res = await apiRequest(GET, `Trees/${treeId}/importstatus`);
    let payload = {
      pendingSnackbar: true,
      personId: selectedHomePerson.personId,
      treeId
    };
    if (res.data.toUpperCase() === "IMPORTCOMPLETED") {
      onImportFinish();
      dispatch({
        type: ASSIGN_HOMEPERSON,
        payload: res.data
      });
      dispatch({
        type: PENDING_NOTIFICATION_SNACKBAR,
        payload: payload
      });
    }
    dispatch({
      type: GET_IMPORT_STATUS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: IMPORT_STATUS_ERROR,
      payload: { msg: err }
    });
  }
};

export const importGedCom = (FileName, FormFile, isSharedWithPartners) => async (dispatch) => {
  const payload = gedcomPayload(FileName, FormFile, isSharedWithPartners);

  try {
    const onUploadProgress = (event) => {
      let percentage = (event.loaded / event.total) * 100;
      dispatch({ type: PROGRESS_BAR, payload: percentage });
    };

    const res = await apiRequest(POST, `Trees/importgedcom`, payload, onUploadProgress);

    dispatch({
      type: IMPORT_GEDCOM,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: INVALID_GEDCOM_FILE,
      payload: true
    });
    dispatch({
      type: GEDCOM_ERROR,
      payload: { msg: err }
    });
  }
};

export const clearGedcomProgress = () => (dispatch) => {
  try {
    dispatch({ type: PROGRESS_BAR, payload: 0 });
  } catch (err) {
    dispatch({
      type: GEDCOM_ERROR,
      payload: { msg: err }
    });
  }
};

export const startNewTree = (personData, isSharedWithPartners, addNewPersonToCache) => async (dispatch) => {
  const payload = startNewTreeWithFocusPerson(personData, isSharedWithPartners);

  try {
    await startTreeApiRequest(POST, `trees`, payload);
    const res = {
      data: {
        treeId: payload.treeId,
        primaryPersonId: payload.homePerson.id,
        treeName: payload.treeName,
        level: 4
      }
    };
    addNewPersonToCache?.({ ...personData, primaryPersonId: payload.homePerson.id });
    dispatch({
      type: ADD_TREE,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: TREE_ERROR,
      payload: { msg: error }
    });
  }
};
export const cancelRenderNewTree = () => (dispatch) => {
  dispatch({
    type: RENDER_NEW_TREE,
    payload: null
  });
};
export const startATree = (personData, isSharedWithPartners, birthYear, addNewPersonToCache) => async (dispatch) => {
  const payload = startNewTreeWithFocusPerson(personData, isSharedWithPartners, birthYear);

  try {
    await startTreeApiRequest(POST, `trees`, payload);

    const res = {
      data: {
        treeId: payload.treeId,
        primaryPersonId: payload.homePerson.id,
        treeName: payload.treeName,
        level: 4
      }
    };
    addNewPersonToCache?.({ ...personData, primaryPersonId: payload.homePerson.id });
    dispatch({
      type: RENDER_NEW_TREE,
      payload: res.data
    });

    let newTree = [
      {
        treeId: payload?.treeId,
        homePersonId: payload?.homePerson.id,
        treeName: payload?.treeName
      }
    ];
    dispatch({
      type: GET_ALL_TREES,
      payload: newTree
    });
  } catch (error) {
    dispatch({
      type: TREE_ERROR,
      payload: { msg: error }
    });
  }
};

//To get request resoponse for start tree flow
export const getStartTreeRequestStatus = (requestId) => async () => {
  if (requestId) {
    const response = await apiRequest(GET, `Users/requestStatus/${requestId}`);
    return response?.data[0];
  }
};

export const getFamilySearchAccessToken = async (code) => {
  return await apiFamilySearchRequest(
    POST,
    `/token?client_id=6FPD-2YHT-GBN8-1GXV-47N9-W86Y-B7MB-255H&code=${code}&grant_type=authorization_code`
  )
    .then((res) => {
      setFamilySearchToken(res?.data?.access_token);
      return res.data;
    })
    .catch((_err) => {
      return [];
    });
};

export const setStartTreeRequestStatus = () => (dispatch) => {
  dispatch({
    type: SET_START_TREE_REQUEST_STATUS
  });
};

export const revertStartTreeRequestStatus = () => (dispatch) => {
  dispatch({
    type: REVERT_START_TREE_REQUEST_STATUS
  });
};

export const triggerPendingSnackbar = (pendingData) => async (dispatch) => {
  try {
    let url = `/family/pedigree-view/${pendingData.treeId}/${pendingData.personId}/4`;
    await dispatch(addMessage("Your tree has finished processing!", "notification", { url }));
    dispatch({
      type: PENDING_NOTIFICATION_SNACKBAR,
      payload: null
    });
  } catch (err) {
    dispatch({
      type: USER_ERROR,
      payload: { msg: err }
    });
  }
};

export const assignHomePerson = (treeId, homePersonId, thisIsMePerson) => async (dispatch) => {
  const payload = assignPayload(treeId, homePersonId, thisIsMePerson);

  try {
    const res = await apiRequest(POST, `Trees/assignhomeandrepresentingperson`, payload);

    if (res.status === 200) {
      dispatch({
        type: ASSIGN_HOMEPERSON,
        payload: res.data
      });
    }
    if (res.status === 202) {
      dispatch({
        type: IMPORT_IN_PROGRESS,
        payload: res.data
      });
    }
  } catch (err) {
    dispatch({
      type: ASSIGN_ERROR,
      payload: { msg: err }
    });
  }
};

export const handleSelectHomePerson = (selectedHomePerson) => (dispatch) => {
  try {
    dispatch({
      type: SELECT_HOMEPERSON,
      payload: selectedHomePerson
    });
  } catch (err) {
    dispatch({
      type: SELECT_HOMEPERSON_ERROR,
      payload: { msg: err }
    });
  }
};
export const setHomePerson = (treeId, homePersonId, thisIsMePerson) => async (dispatch) => {
  const payload = assignPayload(treeId, homePersonId, thisIsMePerson);
  try {
    const res = await apiRequest(POST, `Trees/assignhomeandrepresentingperson`, payload);

    if (res.status === 200) {
      const response = await apiRequest(GET, `persons/pedigree/${treeId}/${homePersonId}/4`);

      dispatch({
        type: SET_HOMEPERSON,
        payload: response.data
      });
    }
  } catch (err) {
    dispatch({
      type: ASSIGN_ERROR,
      payload: { msg: err }
    });
  }
};

export const closeHomePersonModal = () => (dispatch) => {
  dispatch({
    type: CLOSE_HOMEPERSON_MODAL
  });
};

export const updateGedcom = (treeId, selectedHomePerson, file, level) => (dispatch) => {
  let treeName = file.name.split(".")[0];
  try {
    const payload = {
      treeId,
      primaryPersonId: selectedHomePerson.personId,
      treeName,
      level
    };

    dispatch({
      type: SET_START_TREE_REQUEST_STATUS
    });

    dispatch({
      type: ADD_TREE,
      payload
    });

    dispatch({
      type: IS_GEDCOM_UPLOADED
    });
  } catch (err) {
    dispatch({
      type: TREE_ERROR,
      payload: { msg: err }
    });
  }
};

export const clearGedcom = () => (dispatch) => {
  dispatch({
    type: CLEAR_GEDCOM
  });
};

export const getNameDistributionData = (userInfo) => async (dispatch) => {
  return apiRequest("GET", `Users/USA/${userInfo.lastName}/namedistributiondata`)
    .then((res) => {
      dispatch({
        type: SURNAME_DISTRIBUTION,
        payload: res?.data?.stateWiseSurnameDistributionData
      });
      return res?.data?.stateWiseSurnameDistributionData || [];
    })
    .catch((_err) => {
      return [];
    });
};

export const getNameMeaningData = (userInfo) => async (dispatch) => {
  return apiRequest("GET", `Users/USA/${userInfo.lastName}/namemeaningdata`)
    .then((res) => {
      dispatch({
        type: SURNAME_MEANING,
        payload: res?.data
      });
      return res?.data || [];
    })
    .catch((_err) => {
      return [];
    });
};

export const closeInvalidGedcomModal = () => (dispatch) => {
  dispatch({
    type: INVALID_GEDCOM_FILE,
    payload: false
  });
};
