import axios from "axios";
import { pathToRegexp } from "path-to-regexp";
import { v4 } from "uuid";

function featureFlagWithCacheFactory(
  flag: string,
  cacheExpirationMs: number,
  reactAppClientId: string,
  reactAppApi: string
): () => Promise<boolean> {
  let previouslyCheckedValue: boolean | null = null;
  return async () => {
    if (previouslyCheckedValue !== null) {
      return previouslyCheckedValue;
    }

    // We can't use autogenerated API here because this file is imported by the Next.js repository which would cause issues.
    const result = await axios.get<boolean>(`${reactAppApi}/api/FeatureFlag/feature/${flag}`, {
      headers: {
        "wa-clientId": reactAppClientId,
        "wa-requestId": v4()
      }
    });
    previouslyCheckedValue = result.data;
    setTimeout(() => {
      previouslyCheckedValue = null;
    }, cacheExpirationMs);

    return result.data;
  };
}

function getNextJsRoutes(
  reactAppClientId: string,
  reactAppApi: string
): { path: string; canBeLoggedIn: boolean | (() => Promise<boolean>) }[] {
  return [
    { path: "/stories", canBeLoggedIn: false },
    { path: "/stories/:version?/view/:idunno/:storyid", canBeLoggedIn: false },
    { path: "/storiedbooks", canBeLoggedIn: true },
    { path: "/groups", canBeLoggedIn: true },
    { path: "/family-trees", canBeLoggedIn: true },
    { path: "/people", canBeLoggedIn: true },
    { path: "/newspaper-search", canBeLoggedIn: false },
    { path: "/newspaper-search/:route*", canBeLoggedIn: false },
    { path: "/plans", canBeLoggedIn: false },
    { path: "/about", canBeLoggedIn: true },
    { path: "/resources", canBeLoggedIn: true },
    { path: "/privacy", canBeLoggedIn: true },
    { path: "/terms", canBeLoggedIn: true },
    { path: "/ccpa", canBeLoggedIn: true },
    { path: "/dpa", canBeLoggedIn: true },
    { path: "/getstarted", canBeLoggedIn: true },
    { path: "/name-search", canBeLoggedIn: true },
    { path: "/person/profile/:id", canBeLoggedIn: false },
    { path: "/payment/bundle/:rest+", canBeLoggedIn: false },
    { path: "/gifts/:rest+", canBeLoggedIn: true },
    { path: "/print", canBeLoggedIn: true },
    { path: "/storiedbook/re-order/:id", canBeLoggedIn: true }
  ];
}

export function getCompiledNextRoutes(reactAppClientId: string, reactAppApi: string) {
  return getNextJsRoutes(reactAppClientId, reactAppApi).map((route) => ({
    path: pathToRegexp(route.path),
    canBeLoggedIn: route.canBeLoggedIn
  }));
}
